import { I18nUtil } from "@app/utils/I18nUtil";
import { ValidationError } from "class-validator";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import * as React from "react";
import { useEffect, useState } from "react";

export enum BaseInputType {
    AutoComplete,
    TextInput,
    TextArea,
    Checkbox,
    DropDown,
    DisplayTextOnly,
    MultiSelect,
    Password,
    Calendar
}

export interface BaseInputProps {
    type: BaseInputType;
    classes: string;
    labelClasses?: string;
    inputClasses?: string;

    label: string;
    value: any;
    valueName?: string;

    placeholder?: string;
    options?: any[];
    optionLabel?: string;

    dropDownFilter?: boolean;
    dropDownShowClear?: boolean;

    textAreaCols?: number;
    textAreaRows?: number;
    textAreaAutoResize?: boolean;

    calendarShowIcon?: boolean;
    calendarMinDate?: Date;
    calendarMaxDate?: Date;
    calendarDateFormat?: string;

    disabled?: boolean;
    readOnly?: boolean;

    formErrors?: ValidationError[];

    handlerChange?: (data: any) => void;
    nativeHandlerChange?: (e: any) => void;
    autoCompleteSearchRequest?: (queryString: string) => void;
}

const BaseInput: React.FunctionComponent<BaseInputProps> = (props: BaseInputProps) => {
    const [uuid] = useState('id-' + Math.random());
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const invalidMessage = React.useMemo(
        () => {
            if (props.formErrors) {
                const errorMessages: string[] = [];
                props.formErrors.map(error => {
                    if (error.property === props.valueName) {
                        Object.keys(error.constraints).map(
                            key => errorMessages.push(I18nUtil.translateValidatorError(error.constraints[key]))
                        )
                    }
                });
                return errorMessages.join(", ");
            } else {
                return null;
            }
        },
        [props.formErrors]
    )

    const handlerChange = (evt) => {
        switch (props.type) {
            case BaseInputType.AutoComplete:
                setValue(evt.target.value);
                props.handlerChange(evt.target.value);
                break;
            case BaseInputType.Checkbox:
                setValue(evt.checked);
                props.handlerChange(evt.checked);
                break;
            case BaseInputType.DropDown:
                setValue(evt.value);
                props.handlerChange(evt.value);
                break;
            case BaseInputType.MultiSelect:
                setValue(evt.value);
                props.handlerChange(evt.value);
                break;
            case BaseInputType.DisplayTextOnly:
                setValue(evt.value);
                props.handlerChange(evt.value);
                break;
            default:
                setValue(evt.target.value);
                props.handlerChange(evt.target.value);
        }

        if (props.nativeHandlerChange) {
            props.nativeHandlerChange(evt);
        }
    }

    /**
     * Handler for auto complete signals search should be started
     * @param evt
     */
    // todo type event
    const onAutoCompleteCompleted = (evt: any) => {
        if (props.autoCompleteSearchRequest) {
            props.autoCompleteSearchRequest(evt.query)
        }
    }

    const renderInput = () => {
        const baseProps = {
            id: uuid,
            disabled: props.disabled || props.readOnly,
            readOnly: props.readOnly,
            className: `${(invalidMessage) ? 'p-invalid' : ''} ${props.inputClasses ?? ''}`
        }

        switch (props.type) {
            case BaseInputType.AutoComplete:
                return <AutoComplete
                    {...baseProps}
                    value={value ?? ''}
                    suggestions={props.options}
                    onChange={handlerChange}
                    placeholder={props.placeholder}
                    completeMethod={onAutoCompleteCompleted}
                />
            case BaseInputType.Checkbox:
                return <Checkbox
                    {...baseProps}
                    checked={value}
                    onChange={handlerChange}
                />
            case BaseInputType.TextArea:
                return <InputTextarea
                    {...baseProps}
                    placeholder={props.placeholder}
                    rows={props.textAreaRows}
                    cols={props.textAreaCols}
                    autoResize={props.textAreaAutoResize}
                    value={value ?? ''}
                    onChange={handlerChange}
                />
            case BaseInputType.DropDown:
                return <Dropdown
                    {...baseProps}
                    optionLabel={props.optionLabel}
                    value={value}
                    options={props.options}
                    onChange={handlerChange}
                    placeholder={props.placeholder}
                    showClear={props.dropDownShowClear}
                    filter={props.dropDownFilter}
                    filterBy={props.optionLabel}
                />
            case BaseInputType.MultiSelect:
                // has workaround for dropdown issue, that selected values will not be displayed with complex options data
                return <>
                    <MultiSelect
                        {...baseProps}
                        value={value}
                        options={props.options}
                        onChange={handlerChange}
                        optionLabel={props.optionLabel}
                        placeholder={props.placeholder}
                    />
                </>
            case BaseInputType.DisplayTextOnly:
                baseProps.className = `${baseProps.className} text-left`
                return <div
                    {...baseProps}
                >
                    {value && `${value}`.trim() !== '' ? value : props.placeholder}
                </div>
            case BaseInputType.Password:
                return <Password
                    {...baseProps}
                    placeholder={props.placeholder}
                    type="text"
                    value={value ?? ''}
                    onChange={handlerChange}
                    feedback={false}
                />
            case BaseInputType.Calendar:
                return <Calendar
                    {...baseProps}
                    placeholder={props.placeholder}
                    value={value ?? ''}
                    showIcon={props.calendarShowIcon}
                    minDate={props.calendarMinDate}
                    maxDate={props.calendarMaxDate}
                    dateFormat={props.calendarDateFormat}
                    onChange={handlerChange}
                />
            default:
                return <InputText
                    {...baseProps}
                    placeholder={props.placeholder}
                    type="text"
                    value={value ?? ''}
                    onChange={handlerChange}
                />
        }
    }

    const renderedContent = React.useMemo(() => {
        switch (props.type) {
            case BaseInputType.Checkbox:
                return <>
                    {props.label ? <label htmlFor={uuid} className={(props.labelClasses ?? 'col-12')}>{props.label}</label> : null}
                    {renderInput()}
                </>
            case BaseInputType.AutoComplete:
            case BaseInputType.DropDown:
            case BaseInputType.DisplayTextOnly:
            default:
                return <>
                    {props.label ? <label htmlFor={uuid} className={(props.labelClasses ?? 'col-12')}>{props.label}</label> : null}
                    <div className={(props.inputClasses ?? 'col-12')}>
                        {renderInput()}
                        {invalidMessage ?
                            <small id={`${uuid}-help`} className="p-error p-d-block">
                                {invalidMessage}
                            </small>
                            : null
                        }
                    </div>
                </>
        }
    }, [props, invalidMessage, uuid, renderInput])

    return <div className={"field grid p-fluid " + props.classes}>
        {renderedContent}
    </div>
}

export default BaseInput;