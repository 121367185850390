import { PaginatedServerResponse } from "@app/interfaces/PaginatedServerResponse";
import { BaseService } from "@app/services/BaseService";
import { ChatHistoryEntryInterface } from "@dashart/dashart-gpt-shared-library";

export class ChatHistoryService extends BaseService {

    private static _instance: ChatHistoryService;

    /**
     * Get current instance
     */
    public static getInstance(): ChatHistoryService {
        if (!this._instance) {
            this._instance = new ChatHistoryService();
        }

        return this._instance;
    }

    /**
     * Get chat history paginated with start and limit
     * @param start 
     * @param limit 
     * @returns 
     */
    public async getConversationHistoryListPaginated(start: number, limit: number): Promise<PaginatedServerResponse<ChatHistoryEntryInterface>> {
        const response = await this.axiosInstance.get(
            `/v1/conversation-history/?start=${start}&limit=${limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return response.data;
    }

    // search chat history
    public async searchConversationHistoryList(search: string, start: number, limit: number): Promise<PaginatedServerResponse<ChatHistoryEntryInterface>> {
        const response = await this.axiosInstance.get(
            `/v1/conversation-history/search/?start=${start}&limit=${limit}&search=${search}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return response.data;
    }

    /**
     * Get conversation history by conversation id
     * @param conversationId 
     * @returns 
     */
    public async getConversationHistory(conversationId: string): Promise<ChatHistoryEntryInterface> {
        const response = await this.axiosInstance.get(
            `/v1/conversation-history/${conversationId}/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return (response.data) as ChatHistoryEntryInterface ?? null;
    }
}