import { ChatMessageInterface } from "@app/interfaces/ChatMessageInterface";
import CodeCopyBtn from "@view/components/codeCopyBtn/CodeCopyBtn";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import remarkGfm from "remark-gfm";
import styles from "./ChatMessage.module.scss";
import ChatMessageOptionsMenu from "./chatMessageOptionsMenu/ChatMessageOptionsMenu";

export interface ChatMessageProps {
    message: ChatMessageInterface;
    index: number;

    onForkRequestHandler?: (messageIndex: number) => void;
}

const ChatMessage: React.FunctionComponent<ChatMessageProps> = (props: ChatMessageProps) => {
    const { message, index } = props;

    /**
     * Request to fork of the conversation starting from this message
     */
    const onForkContentHandler = () => {
        if (props.onForkRequestHandler) {
            props.onForkRequestHandler(props.index);
        }
    }

    const renderIcon = () => {
        switch (message.role) {
            case 'bot':
                return '🤖';
            case 'user':
                return '👤';
            default:
                return '🖥️';

        }
    }

    const Pre = ({ children }) => <pre>
        <CodeCopyBtn>{children}</CodeCopyBtn>
        {children}
    </pre>

    return <div
        key={index}
        className={`${styles.ChatMessage} markdown-body col-12 flex flex-row pr-5 ${index % 2 ? 'bg-white-alpha-90' : 'bg-white-alpha-50'}`}
    >

        <div className={'absolute right-0 top-0 p-2 pr-3'}>
            <ChatMessageOptionsMenu
                messageContents={message.content}
                messageIndex={index}
                onForkRequestHandler={onForkContentHandler}
            />
        </div>

        <div className={`${styles.iconColumn}`}>
            {renderIcon()}
        </div>
        <div className={`${styles.messageColumn}`}>
            <ReactMarkdown
                children={message.content}
                remarkPlugins={[remarkGfm]}
                components={{
                    // add the copy button to code
                    pre: Pre,
                    // render formatted code
                    code(
                        { node, inline, className = "code", children, ...props }
                    ) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <SyntaxHighlighter
                                style={a11yDark as any}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            >
                                {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}
            />
        </div>
    </div>
}

export default ChatMessage;