import { InstructionInterface } from '@dashart/dashart-gpt-shared-library';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PaginatedServerResponse } from '../../../../../app/interfaces/PaginatedServerResponse';
import { InstructionsService } from '../../../../../app/services/InstructionsService';
import './InstructionsSelectorDialogHistoryTab.scss';

export interface InstructionsSelectorDialogHistoryTabProps {
    onSelect: (instruction: string) => void;
}

const ROWS_PER_PAGE = 20;

export const InstructionsInstructionsSelectorDialogHistoryTab: React.FunctionComponent<InstructionsSelectorDialogHistoryTabProps> = (props: InstructionsSelectorDialogHistoryTabProps) => {
    const { t } = useTranslation();
    const [paginatedResult, setPaginatedResult] = useState<PaginatedServerResponse<InstructionInterface>>(null)

    const loadInstructionsFromConversationHistory = async (page: number) => {
        const result = await InstructionsService.getInstance().getInstructionsOfUserConversationHistoryListPaginated(page, ROWS_PER_PAGE);
        setPaginatedResult(result);
    }

    useEffect(() => {
        loadInstructionsFromConversationHistory(1);
    }, []);

    /**
     * Cell parser with mouse handler
     * @param rowData 
     * @returns 
     */
    const cellParser = (rowData: InstructionInterface) => {
        return (
            <div
                className="InstructionsInstructionsSelectorDialogHistoryTabCell"
                onClick={() => props.onSelect(rowData.instruction)}
            >
                {rowData.instruction}
            </div>
        );
    }

    return (
        <div className="InstructionsSelectorDialogHistoryTab h-full">

            <div className='contentContainer'>
                <div className='tableContainer'>
                    <DataTable
                        value={paginatedResult?.data ?? []}
                        rowHover={true}
                        showHeaders={false}
                        scrollable
                        scrollHeight='flex'
                        sortField="name"
                        sortOrder={1}
                    >
                        <Column
                            body={cellParser}
                        />
                    </DataTable>
                </div>

                <Paginator
                    first={paginatedResult?.meta?.current_page}
                    rows={ROWS_PER_PAGE}
                    totalRecords={paginatedResult?.meta?.total}
                    onPageChange={(e) => {
                        loadInstructionsFromConversationHistory(e.page + 1);
                    }}
                />
            </div>
        </div>
    );
}

export default InstructionsInstructionsSelectorDialogHistoryTab;
