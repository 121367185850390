import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import RecoilStates from "../../../../app/models/RecoilStates";
import ChatMessageOptionsButton from "../../chatMessageOptionsButton/ChatMessageOptionsButton";
import styles from './ChatMessageOptionsMenu.module.scss';

export interface ChatMessageOptionsMenuProps {
    messageContents: any;
    messageIndex: number;

    onForkRequestHandler?: (messageIndex: number) => void;
}

const ChatMessageOptionsMenu: React.FunctionComponent<ChatMessageOptionsMenuProps> = (props: ChatMessageOptionsMenuProps) => {
    const { messageContents } = props;
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);

    const setToast = useSetRecoilState(RecoilStates.lastToastMessageState);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(`.${styles.menuContainer}`) === null) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    /**
     * Toggle the menu open/close
     */
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    /**
     * Copy the message content to the clipboard
     */
    const onCopyContentHandler = () => {
        try {
            // try to copy the code rendered by the markdown editor
            navigator.clipboard.writeText(
                messageContents
            );

            setToast({
                severity: 'success',
                summary: t('globals.clipboard.copied'),
                lifetime: 1000
            });
        } catch (e) {
            setToast({
                severity: 'warn',
                summary: t('globals.clipboard.failed'),
                lifetime: 1000
            });
        }

        setIsOpen(false);
    }

    /**
     * Request to fork of the conversation starting from this message
     */
    const onForkContentHandler = () => {
        if (props.onForkRequestHandler) {
            props.onForkRequestHandler(props.messageIndex);
        }

        setIsOpen(false);
    }

    return (
        <div className={styles.ChatMessageOptionsMenu}>
            <div className={styles.buttonContainer}>
                <ChatMessageOptionsButton
                    onClickHandler={toggleMenu}
                />
            </div>
            {isOpen ?
                <div className={styles.menuContainer}>
                    <ul>
                        <li onClick={onCopyContentHandler}>
                            {t('components.chat_message.options_menu.copy')}
                        </li>
                        <li onClick={onForkContentHandler}>
                            {t('components.chat_message.options_menu.fork')}
                        </li>
                    </ul>
                </div>
                : null}
        </div>
    )
}

export default ChatMessageOptionsMenu;