import { Dialog } from 'primereact/dialog';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import React from "react";

export interface SelectorDialogProps {
    header: string;
    description?: string;

    width: string;

    labelKey: string;
    availableValues: any[];
    selectedValues: any;

    onSelect: (model: any) => void;
    onclose: () => void;
}

export const SelectorDialogDialog: React.FunctionComponent<SelectorDialogProps> = (props: SelectorDialogProps) => {
    const { header, description, labelKey, availableValues: availableModels, selectedValues: selectedModel, onSelect, onclose, width } = props;

    const onSelectionChanged = (event: ListBoxChangeEvent) => {
        onSelect(event.value);
    }

    return (
        <Dialog
            className="SelectorDialogDialog"
            header={header}
            visible={true}
            style={{ width: width }}
            onHide={onclose}
            closeOnEscape={true}
        >
            {description ? <p className='mt-0'>{description}</p> : null}
            <ListBox
                value={selectedModel}
                onChange={onSelectionChanged}
                options={availableModels}
                optionLabel={labelKey}
                className="w-full"
            />
        </Dialog>
    );
}

export default SelectorDialogDialog;
