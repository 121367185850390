import { SentryHelper } from "@app/helpers/SentryHelper";
import { AxiosUtil } from "@app/utils/AxiosUtil";
import { AddUserToCompanyDto, CompanyInterface, CreateCompanyDto, UpdateCompanyDto } from "@dashart/dashart-gpt-shared-library";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import { AxiosInstance, AxiosResponse } from "axios";
import { PaginatedServerResponse } from "../interfaces/PaginatedServerResponse";

export class CompanyService {

    private static axiosInstance: AxiosInstance;

    private static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = AxiosUtil.createPreconfiguredAxiosInstance();
        }

        return this.axiosInstance;
    }

    /**
     * Get all companies
     * @param start 
     * @param limit 
     * @returns 
     */
    public static async getAll(start: number = 0, limit: number = 50): Promise<PaginatedServerResponse<CompanyInterface>> {
        return this.getAxiosInstance().get(
            `/v1/companies?start=${start}&limit=${limit}`,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(response.data)
            },
            (error) => {
                console.log('Get companies failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    /**
     * Get company by id
     * @param id 
     * @returns 
     */
    public static async getById(id: string): Promise<CompanyInterface> {
        return this.getAxiosInstance().get(
            `/v1/companies/${id}`,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<CompanyInterface>response.data ?? null)
            },
            (error) => {
                console.log('Get company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    /**
     * Create company
     * @param dto 
     * @returns 
     */
    public static async create(dto: CreateCompanyDto): Promise<CompanyInterface> {
        return this.getAxiosInstance().post(
            `/v1/companies`,
            dto
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<CompanyInterface>response.data ?? null)
            },
            (error) => {
                console.log('Create company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    /**
     * Update company
     * @param id 
     * @param dto 
     * @returns 
     */
    public static async update(id: string, dto: UpdateCompanyDto): Promise<CompanyInterface> {

        // remove createdAt and updatedAt
        delete (dto as any).createdAt;
        delete (dto as any).updatedAt;

        return this.getAxiosInstance().put(
            `/v1/companies/${id}`,
            dto
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<CompanyInterface>response.data ?? null)
            },
            (error) => {
                console.log('Update company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    /**
     * Delete company
     * @param id 
     * @returns 
     */
    public static async delete(id: string): Promise<void> {
        return this.getAxiosInstance().delete(
            `/v1/companies/${id}`,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve()
            },
            (error) => {
                console.log('Delete company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    //
    // Users

    /**
     * Get users of company
     * @param id 
     * @param start 
     * @param limit 
     * @returns 
     */
    public static async getUsers(id: string, start: number = 0, limit: number = 50): Promise<PaginatedServerResponse<UserInterface>> {
        return this.getAxiosInstance().get(
            `/v1/companies/${id}/users?start=${start}&limit=${limit}`,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(response.data)
            },
            (error) => {
                console.log('Get users of company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    /**
     * Add user to company
     * @param id 
     * @param dto 
     * @returns 
     */
    public static async addUser(id: number, dto: AddUserToCompanyDto): Promise<CompanyInterface> {
        return this.getAxiosInstance().post(
            `/v1/companies/${id}/users/`,
            dto
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<CompanyInterface>response.data ?? null)
            },
            (error) => {
                console.log('Add user to company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }

    /**
     * Remove user from company
     * @param id 
     * @param userId 
     * @returns 
     */
    public static async removeUser(id: number, userId: string): Promise<CompanyInterface> {
        return this.getAxiosInstance().delete(
            `/v1/companies/${id}/users/${userId}`,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<CompanyInterface>response.data ?? null)
            },
            (error) => {
                console.log('Remove user from company failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }
}
