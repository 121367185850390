import * as React from "react";
import {useState} from "react";
import "./LoginScreen.scss"
import {Panel} from "primereact/panel";
import {RoutesEnum} from "@app/models/RoutesEnum";
import {Dialog} from 'primereact/dialog';
import {useTranslation} from "react-i18next";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import BaseInput, {BaseInputType} from "@view/components/inputs/baseInput/BaseInput";
import {Button} from "primereact/button";
import {Menubar} from "primereact/menubar";
import {Navigate} from "react-router-dom";
import {Message} from "primereact/message";

export interface LoginScreenProps {
    loading: boolean;
    loginFailed: boolean;
    overlay: boolean;
    login: (userName:string, password:string) => void;
}

interface FormData {
    userName:string,
    password:string
}

const LoginScreen: React.FunctionComponent<LoginScreenProps> = (props: LoginScreenProps) => {
    const {t} = useTranslation();
    const [redirectTo, setRedirectTo] = useState<string>(null);

    const [formData, setFormData] = useState<FormData>({
        userName: "",
        password: "",
    });

    const onClearHandler = (): void => {
        setFormData({
            userName: "",
            password: "",
        })
    };

    const onLoginHandler = (): void => {
        props.login(formData.userName, formData.password);
    };

    const onResetHandler = (): void => {
        setRedirectTo(RoutesEnum.forgottenPassword);
    };

    const onOuterKeyHandler = (evt: any): void => {
        if (evt.code.toLowerCase() === "enter") {
            onLoginHandler();
        }
    }

    const renderAsPanel = (panelContent) => {
        return (
            <div className='LoginBox flex p-grid col align-items-center justify-content-center'>
                <div className='col-8'>
                    <Panel header={t('components.login_screen.header')}>
                        {panelContent}
                    </Panel>
                </div>
            </div>
        )
    }

    const renderAsOverlay = (overlayContent) => {
        return (
            <Dialog header={t('components.login_screen.header')} visible={true} style={{maxWidth: '50vw'}} modal
                    closable={false} onHide={() => {
            }}>
                {overlayContent}
            </Dialog>
        )
    }

    const render = (component) => {
        return props.overlay ? renderAsOverlay(component) : renderAsPanel(component)
    }

    return render(
        <div className="LoginScreen formgrid grid">

            {redirectTo ? <Navigate to={redirectTo}/> : null}

            <ProgressSpinnerOverlay visible={props.loading}/>

            <div className="col-12 p-fluid col-nogutter" onKeyPressCapture={onOuterKeyHandler}>
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t('components.login_screen.field_email')}
                    value={formData.userName}
                    handlerChange={(data: string) => setFormData({
                        ...formData,
                        userName: data,
                    })}
                />

                <BaseInput
                    type={BaseInputType.Password}
                    classes={'col-12'}
                    label={t('components.login_screen.field_password')}
                    value={formData.password}
                    handlerChange={(data: string) => setFormData({
                        ...formData,
                        password: data,
                    })}
                />

                {
                    props.loginFailed ?
                        <div className="col-12 mb-2">
                            <Message severity="error" text={t('components.login_screen.message_login_failed')}/>
                        </div>
                        : null
                }

            </div>

            <Menubar
                className={'col-12 loginMenubar'}
                model={[]}
                end={<>
                    {/*only if not displayed as overlay*/}
                    {/*{!props.overlay ? <Button
                        className={'p-button-secondary ml-2'}
                        label={t('components.login_screen.button_reset_password')}
                        onClick={onResetHandler}
                        icon="pi pi-help"/> : null}*/}

                    <Button
                        className={'p-button-warning ml-2'}
                        label={t('globals.actions.cancel')}
                        onClick={onClearHandler}
                        icon="pi pi-cancel"/>

                    <Button
                        className={'ml-2'}
                        label={t('globals.actions.login')}
                        onClick={onLoginHandler}
                        icon="pi pi-check"/>
                </>}
            />
        </div>
    )
}

export default LoginScreen;