import { Dialog } from 'primereact/dialog';
import { ListBoxChangeEvent } from 'primereact/listbox';
import { TabPanel, TabView } from 'primereact/tabview';
import React from "react";
import './InstructionsSelectorDialog.scss';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import RecoilStates from '../../../../app/models/RecoilStates';
import InstructionsInstructionsSelectorDialogHistoryTab from './instructionsSelectorDialogHistoryTab/InstructionsSelectorDialogHistoryTab';
import InstructionsInstructionsSelectorDialogSavedEntries from './instructionsSelectorDialogSavedEntries/InstructionsSelectorDialogSavedEntries';
import { InstructionInterface } from '@dashart/dashart-gpt-shared-library';

export interface SelectorDialogProps {
    width: string;
    height: string;

    onSelect: (instruction: string) => void;
    onDetailViewRequested?: (instruction: InstructionInterface, mode: 'edit' | 'view') => void;
    onclose: () => void;
}

export const InstructionsSelectorDialog: React.FunctionComponent<SelectorDialogProps> = (props: SelectorDialogProps) => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useRecoilState(RecoilStates.instructionsSelectorDialogTabIndexState)

    return (
        <Dialog
            className="InstructionsSelectorDialog"
            header={t('components.instruction_panel.instructionsSelectorDialog.header')}
            visible={true}
            style={{ width: props.width, height: props.height }}
            onHide={props.onclose}
            closeOnEscape={true}
        >

            <TabView
                className='h-full'
                activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}
            >
                <TabPanel header={t('components.instruction_panel.instructionsSelectorDialog.selectFromHistory')} className='h-full'>
                    {activeIndex === 0 && <InstructionsInstructionsSelectorDialogHistoryTab
                        onSelect={props.onSelect}
                    />}
                </TabPanel>
                <TabPanel header={t('components.instruction_panel.instructionsSelectorDialog.selectFromPersonalSaved')} className='h-full'>
                    {activeIndex === 1 && <InstructionsInstructionsSelectorDialogSavedEntries
                        mode={'by-user'}
                        onSelect={props.onSelect}
                        onDetailViewRequested={props.onDetailViewRequested}
                    />}
                </TabPanel>
                <TabPanel header={t('components.instruction_panel.instructionsSelectorDialog.selectFromCompanySaved')} className='h-full'>
                    {activeIndex === 2 && <InstructionsInstructionsSelectorDialogSavedEntries
                        mode={'by-company'}
                        onSelect={props.onSelect}
                        onDetailViewRequested={props.onDetailViewRequested}
                    />}
                </TabPanel>
            </TabView>

        </Dialog>
    );
}

export default InstructionsSelectorDialog;
