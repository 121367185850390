// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessageOptionsButton{position:relative;z-index:1}.ChatMessageOptionsButton .inner{position:absolute;top:-4px;right:-4px;cursor:pointer;background:#fff;border-radius:10%;border:1px solid #333;padding:2px 4px}`, "",{"version":3,"sources":["webpack://./src/view/components/chatMessageOptionsButton/ChatMessageOptionsButton.module.scss"],"names":[],"mappings":"AAAA,0BACE,iBAAA,CACA,SAAA,CAEA,iCACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".ChatMessageOptionsButton {\n  position: relative;\n  z-index: 1;\n\n  .inner {\n    position: absolute;\n    top: -4px;\n    right: -4px;\n    cursor: pointer;\n    background: #FFF;\n    border-radius: 10%;\n    border: 1px solid #333333;\n    padding: 2px 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatMessageOptionsButton": `ChatMessageOptionsButton`,
	"inner": `inner`
};
export default ___CSS_LOADER_EXPORT___;
