// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessageOptionsMenu{position:relative}.ChatMessageOptionsMenu .menuContainer{position:absolute;right:24px;top:0px;z-index:2;border:1px solid #333;background:#fff}.ChatMessageOptionsMenu .menuContainer ul{list-style:none;padding:0;margin:0}.ChatMessageOptionsMenu .menuContainer ul li{padding:2px 20px;cursor:pointer;white-space:nowrap;max-width:200px}.ChatMessageOptionsMenu .menuContainer ul li:hover{background:#eaeaea}`, "",{"version":3,"sources":["webpack://./src/view/components/chatMessage/chatMessageOptionsMenu/ChatMessageOptionsMenu.module.scss"],"names":[],"mappings":"AAAA,wBACA,iBAAA,CAME,uCACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,SAAA,CAEA,qBAAA,CACA,eAAA,CAEA,0CACE,eAAA,CACA,SAAA,CACA,QAAA,CAEA,6CACE,gBAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CAEA,mDACE,kBAAA","sourcesContent":[".ChatMessageOptionsMenu {\nposition: relative;\n\n  .buttonContainer {\n\n  }\n\n  .menuContainer {\n    position: absolute;\n    right: 24px;\n    top: 0px;\n    z-index: 2;\n\n    border: 1px solid #333333;\n    background: #FFFFFF;\n\n    ul {\n      list-style: none;\n      padding: 0;\n      margin: 0;\n\n      li {\n        padding: 2px 20px;\n        cursor: pointer;\n        white-space: nowrap;\n        max-width: 200px;\n\n        &:hover {\n          background: #EAEAEA;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatMessageOptionsMenu": `ChatMessageOptionsMenu`,
	"menuContainer": `menuContainer`
};
export default ___CSS_LOADER_EXPORT___;
