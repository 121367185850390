import {AxiosInstance, AxiosResponse} from "axios";
import {AxiosUtil} from "@app/utils/AxiosUtil";
import {SentryHelper} from "@app/helpers/SentryHelper";
import {CreateDeviceApiTokenDto, DeviceApiTokenInterface} from "@dashart/dashart-gpt-shared-library";

export class DeviceApiTokensService {

    private static axiosInstance: AxiosInstance;

    private static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = AxiosUtil.createPreconfiguredAxiosInstance();
        }

        return this.axiosInstance;
    }

    /**
     * Get device api tokens by user
     */
    static async getAllByUser(userId: number): Promise<DeviceApiTokenInterface[]> {
        return this.getAxiosInstance().get(
            `/v1/device-api-tokens/user/${userId}`,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<DeviceApiTokenInterface[]>response.data ?? [])
            },
            (error) => {
                console.log('Get device api tokens by user failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Create one device api token
     */
    static async createOne(createDeviceApiTokenDto: CreateDeviceApiTokenDto): Promise<DeviceApiTokenInterface> {
        return this.getAxiosInstance().post(
            '/v1/device-api-tokens/',
            createDeviceApiTokenDto
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<DeviceApiTokenInterface>response.data ?? null)
            },
            (error) => {
                console.log('Create device api token failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Delete one device api token
     */
    static async deleteOne(deviceApiTokenId: number): Promise<DeviceApiTokenInterface> {
        return this.getAxiosInstance().delete(
            '/v1/device-api-tokens/' + deviceApiTokenId,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<DeviceApiTokenInterface>response.data ?? null)
            },
            (error) => {
                console.log('Delete device api token failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };
}
