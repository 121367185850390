export enum RoutesEnum {
    catchAll = '*',

    login = '/login',
    forgottenPassword = '/forgottenPassword',
    resetPassword = '/resetPassword/:token',

    dashboard = '/dashboard',

    chatWithChatGpt = '/chat/chat-gpt',
    chatWithClaude = '/chat/claude',
    chatWithMistral = '/chat/mistral',

    userList = '/user/list',
    userCreate = '/user/create',
    userDetail = '/user/:userId/detail',
    userEdit = '/user/:userId/edit',
    userDelete = '/user/:userId/delete',
    userTokenUsage = '/user/:userId/token-usage',

    companyList = '/company/list',
    companyCreate = '/company/create',
    companyDetail = '/company/:companyId/detail',
    companyEdit = '/company/:companyId/edit',
    companyDelete = '/company/:companyId/delete',
    companyListUsers = '/company/:companyId/users',
}

export enum RoutesParametersEnum {
    userId = ':userId',
    companyId = ':companyId',
}