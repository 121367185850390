import * as React from "react";
import "./DashBoardScreen.scss"

export interface DashBoardScreenProps {

}

const DashBoardScreen: React.FunctionComponent<DashBoardScreenProps> = (props: DashBoardScreenProps) => {
    return <div className='DashBoardScreen'>
        DASHBOARD
    </div>
}

export default DashBoardScreen;