import { RoutesEnum } from "@app/models/RoutesEnum";
import { RouterUtil } from "@app/utils/RouterUtil";
import { UserACLUtil } from "@dashart/dashart-gpt-shared-library/dist";
import { CompanyACLUtil, CompanyInterface, UserRolesEnum } from "@dashart/dashart-gpt-shared-library/dist/";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import * as React from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";

export interface RoleValidatorComponentProps {
    currentUser: UserInterface;

    validationType: 'minimumUserRole' | 'userScope' | 'companyScope';

    userOfInterest?: UserInterface;
    companyOfInterest?: CompanyInterface;

    redirectRouteIfNotAllowed: string;
    /**
     * If set only role will be validated
     */
    neededMinimumRole?: UserRolesEnum | null;
}

/**
 * Validate user minimum role
 * @param props 
 * @returns 
 */
const validateMinimumUserRole = (props: RoleValidatorComponentProps) => {
    const { currentUser, neededMinimumRole } = props;

    if (!currentUser) {
        return null;
    }

    if (neededMinimumRole !== null && +currentUser.role <= neededMinimumRole) {
        return null;
    }

    return <Navigate to={props.redirectRouteIfNotAllowed} />
}

/**
 * Validate user scope
 * @param props 
 * @returns 
 */
const validateUserScope = (props: RoleValidatorComponentProps) => {
    const { currentUser, userOfInterest } = props;
    const location = useLocation();

    if (!currentUser) {
        return null;
    }

    // can user not list all users or users of his company
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userList], location)) {
        if (!UserACLUtil.canUserListAllUsers(currentUser)) {
            return <Navigate to={props.redirectRouteIfNotAllowed} />
        }
    }

    // is users of interest not set or in creation mode (no id set yet)
    if (!currentUser || !userOfInterest || !userOfInterest.id) {
        return null;
    }

    // all higher than werbe referent should be able to see other users
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userDetail], location)) {
        if (+currentUser.role < UserRolesEnum.UNKNOWN) {
            return null
        }
    }

    // user create route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userCreate], location)) {
        if (UserACLUtil.canUserCreateUser(currentUser, userOfInterest)) {
            return null
        }
    }

    // user detail route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userDetail], location)) {
        if (!userOfInterest) {
            return null; // user still loading
        }

        if (UserACLUtil.canUserUpdateUser(currentUser, userOfInterest)) {
            return null
        }
    }

    // user edit route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userEdit], location)) {
        if (!userOfInterest) {
            return null; // user still loading
        }

        if (UserACLUtil.canUserUpdateUser(currentUser, userOfInterest)) {
            return null
        }
    }

    // user delete route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userDelete], location)) {
        if (!userOfInterest) {
            return null; // user still loading
        }

        if (UserACLUtil.canUserDeleteUser(currentUser, userOfInterest)) {
            return null
        }
    }

    // user token usage route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.userTokenUsage], location)) {
        if (!userOfInterest) {
            return null; // user still loading
        }

        if (UserACLUtil.canUserSeeUserTokenUsage(currentUser, userOfInterest)) {
            return null
        }
    }

    return <Navigate to={props.redirectRouteIfNotAllowed} />
}

/**
 * Validate company scope
 * @param props 
 * @returns 
 */
const validateCompanyScope = (props: RoleValidatorComponentProps) => {
    const { currentUser, companyOfInterest } = props;
    const location = useLocation();

    if (!currentUser || !companyOfInterest) {
        return null;
    }

    // can user list all companies
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.companyList], location)) {
        if (CompanyACLUtil.canUserListAllCompanies(currentUser)) {
            return null
        }
    }

    // company create route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.companyCreate], location)) {
        if (CompanyACLUtil.canUserCreateCompany(currentUser)) {
            return null
        }
    }

    // company detail route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.companyDetail], location)) {
        if (CompanyACLUtil.canUserListCompany(currentUser, companyOfInterest)) {
            return null
        }
    }

    // company edit route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.companyEdit], location)) {
        if (CompanyACLUtil.canUserUpdateCompany(currentUser, companyOfInterest)) {
            return null
        }
    }

    // company delete route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.companyDelete], location)) {
        if (CompanyACLUtil.canUserDeleteCompany(currentUser, companyOfInterest)) {
            return null
        }
    }

    // company users list route
    if (RouterUtil.isOneOfTheRoutesActive([RoutesEnum.companyListUsers], location)) {
        // add or remove user from company
        if (CompanyACLUtil.canUserAddOrRemoveUserFromCompany(currentUser, companyOfInterest)) {
            return null
        }

        // list users of his company
        if (CompanyACLUtil.canUserListUsersOfHisCompany(currentUser, companyOfInterest)) {
            return null
        }
    }

    return <Navigate to={props.redirectRouteIfNotAllowed} />
}

const RoleValidatorComponent: React.FunctionComponent<RoleValidatorComponentProps> = (props: RoleValidatorComponentProps) => {
    switch (props.validationType) {
        case 'minimumUserRole':
            return validateMinimumUserRole(props);
        case 'userScope':
            return validateUserScope(props);
        case 'companyScope':
            return validateCompanyScope(props);
        default:
            return null;
    }
}

export default RoleValidatorComponent;