import { PaginatedServerResponse } from "@app/interfaces/PaginatedServerResponse";
import { BaseService } from "@app/services/BaseService";
import { CreateInstructionDto, InstructionInterface, UpdateInstructionDto } from "@dashart/dashart-gpt-shared-library";

export class InstructionsService extends BaseService {

    private static _instance: InstructionsService;

    /**
     * Get current instance
     */
    public static getInstance(): InstructionsService {
        if (!this._instance) {
            this._instance = new InstructionsService();
        }

        return this._instance;
    }

    /**
     * Get chat instructions of user conversation history paginated with start and limit
     * @param start 
     * @param limit 
     * @returns 
     */
    public async getInstructionsOfUserConversationHistoryListPaginated(start: number, limit: number): Promise<PaginatedServerResponse<InstructionInterface>> {
        const response = await this.axiosInstance.get(
            `/v1/instruction/by-user-history/?start=${start}&limit=${limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return response.data;
    }

    /**
     * Get chat instructions of user saved list paginated with start and limit
     * @param start 
     * @param limit 
     * @returns 
     */
    public async getInstructionsOfUserSavedListPaginated(start: number, limit: number): Promise<PaginatedServerResponse<InstructionInterface>> {
        const response = await this.axiosInstance.get(
            `/v1/instruction/by-user-saved/?start=${start}&limit=${limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return response.data;
    }

    /**
     * Get chat instructions of company saved list paginated with start and limit
     * @param start 
     * @param limit 
     * @returns 
     */
    public async getInstructionsOfCompanySavedListPaginated(start: number, limit: number): Promise<PaginatedServerResponse<InstructionInterface>> {
        const response = await this.axiosInstance.get(
            `/v1/instruction/by-company-saved/?start=${start}&limit=${limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return response.data;
    }

    /**
     * Get instruction by id
     * @param id 
     * @returns 
     */
    public async getInstruction(id: number): Promise<InstructionInterface> {
        const response = await this.axiosInstance.get(
            `/v1/instruction/${id}/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return (response.data) as InstructionInterface ?? null;
    }

    /**
     * Create instruction
     * @param dto 
     * @returns 
     */
    public async createInstruction(dto: CreateInstructionDto): Promise<InstructionInterface> {
        const response = await this.axiosInstance.post(
            `/v1/instruction/`,
            dto,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return (response.data) as InstructionInterface ?? null;
    }

    /**
     * Update instruction
     * @param id 
     * @param dto 
     * @returns 
     */
    public async updateInstruction(id: number, dto: UpdateInstructionDto): Promise<InstructionInterface> {
        // remove createdAt and updatedAt from dto
        const {
            createdAt,
            updatedAt,
            ...cleanedDto
        } = dto as any;

        const response = await this.axiosInstance.patch(
            `/v1/instruction/${id}/`,
            cleanedDto,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return (response.data) as InstructionInterface ?? null;
    }

    /**
     * Delete instruction
     * @param id 
     * @returns 
     */
    public async deleteInstruction(id: number): Promise<InstructionInterface> {
        const response = await this.axiosInstance.delete(
            `/v1/instruction/${id}/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'accept': "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`)
        }

        return (response.data) as InstructionInterface ?? null;
    }

}