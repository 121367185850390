import * as React from "react";
import {useEffect, useRef} from "react";

export interface AutoScrollContainerProps {
    /**
     * Classnames of the container
     */
    className?: string;

    /**
     * Height of the magic tolerance pull zone at the bottom of the container,
     * that will keep auto scrolling activated
     */
    magicPullZoneHeight?: number;

    /**
     * Children of the container
     */
    children: React.ReactNode;
}

/**
 * Container that scrolls to bottom if children changed
 * Scroll up will stop auto scrolling,
 * scroll to end of view will activate auto scrolling again
 * @param props
 * @constructor
 */
const AutoScrollContainer: React.FunctionComponent<AutoScrollContainerProps> = (props: AutoScrollContainerProps) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollToBottomTargetRef = useRef<HTMLDivElement>(null);

    const [autoScrollToBottomActivated, setAutoScrollToBottomActivated] = React.useState(true);

    /**
     * Scroll to bottom if autoScrollToBottomActivated is true and children changed
     */
    useEffect(() => {
        if (autoScrollToBottomActivated && scrollToBottomTargetRef.current?.scrollIntoView) {
            scrollToBottomTargetRef.current?.scrollIntoView({
                behavior: "instant",
                block: "end",
            });
        }
    }, [props.children, autoScrollToBottomActivated]);

    /**
     * Add scroll listener to container / remove on unmount
     */
    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /**
     * Handle scroll event, if user scrolled to end of div,
     * set autoScrollToBottomActivated to true
     * @param event
     */
    const handleScroll = (event) => {
        const container = containerRef.current;
        const {scrollTop, scrollHeight, clientHeight} = container;
        // round with ceil, because of browser inconsistencies / float values issues with mac trackpads
        // -> clientHeight is sometimes 1px smaller than scrollHeight by 0.1-0.9 px
        let scrollPosition = Math.ceil(scrollTop + clientHeight);
        const isAtBottom = scrollPosition + (props.magicPullZoneHeight ?? 0) >= scrollHeight;

        //setAutoScrollToBottomActivated((autoScrollToBottomActivated) => isAtBottom);
        setAutoScrollToBottomActivated(isAtBottom);
    }

    return <div
        ref={containerRef}
        className={`AutoScrollContainer ${props.className ?? ''}`}
    >
        {props.children}

        {/*auto scroll to bottom target*/}
        <div ref={scrollToBottomTargetRef}/>
    </div>
}

export default AutoScrollContainer;