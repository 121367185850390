// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/github-markdown-css/github-markdown-light.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatScreen{display:flex;flex-direction:column;height:100vh}.upperDiv{flex:1;overflow:auto}.actionMenu{position:relative;z-index:10}.inputActionButtons{position:absolute;bottom:0;right:0;z-index:10}.historyOpen{max-width:20%;min-width:20%}.historyClose{max-width:0;min-width:0}@media(max-width: 767px){.historyOpen{max-width:30%;min-width:30%}}`, "",{"version":3,"sources":["webpack://./src/view/screens/chatScreen/ChatScreen.scss"],"names":[],"mappings":"AAEA,YACE,YAAA,CACA,qBAAA,CACA,YAAA,CAIF,UACE,MAAA,CAEA,aAAA,CAIF,YACE,iBAAA,CACA,UAAA,CAGF,oBACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CAGF,aACE,aAAA,CACA,aAAA,CAGF,cACE,WAAA,CACA,WAAA,CAIF,yBACE,aACE,aAAA,CACA,aAAA,CAAA","sourcesContent":["@import \"~github-markdown-css/github-markdown-light.css\";\n\n.ChatScreen {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  /* Adjust as needed */\n}\n\n.upperDiv {\n  flex: 1;\n  /* Take remaining vertical space */\n  overflow: auto;\n  /* Enable scrolling if content exceeds space */\n}\n\n.actionMenu {\n  position: relative;\n  z-index: 10;\n}\n\n.inputActionButtons {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  z-index: 10;\n}\n\n.historyOpen {\n  max-width: 20%;\n  min-width: 20%;\n}\n\n.historyClose {\n  max-width: 0;\n  min-width: 0;\n}\n\n// viewports smaller than 768px\n@media (max-width: 767px) {\n  .historyOpen {\n    max-width: 30%;\n    min-width: 30%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatScreen": `ChatScreen`,
	"upperDiv": `upperDiv`,
	"actionMenu": `actionMenu`,
	"inputActionButtons": `inputActionButtons`,
	"historyOpen": `historyOpen`,
	"historyClose": `historyClose`
};
export default ___CSS_LOADER_EXPORT___;
