import { AxiosUtil } from "@app/utils/AxiosUtil";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { AxiosInstance } from "axios";
import { ChatCompletionResultInterface } from "../interfaces/ChatCompletionResultInterface";
import { ChatModelInterface } from "../interfaces/ChatModelInterface";
import { ChatMessageInterface } from "../interfaces/ChatMessageInterface";
import { AuthUtil } from "../utils/AuthUtil";
import { AuthService } from "./AuthService";

export class BaseService {

    protected axiosInstance: AxiosInstance;
    protected rawAxiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = AxiosUtil.createPreconfiguredAxiosInstance();
        this.rawAxiosInstance = AxiosUtil.createNonConfiguredAxiosInstance();
    }

    /**
     * Configuration for the service.
     * Has to be overwritten in the inheriting class.
     * @returns Object containing default model and API resource name.
     */
    protected getConfiguration(): { defaultModel: string, apiResourceName: string } {
        throw new Error("Method has to be overwritten!.");
        return null as any;
    }

    /** AbortController for managing request cancellations */
    protected currentAbortController: AbortController | null = null;

    /**
     * Returns the default headers for API requests.
     * @returns Object containing default headers.
     */
    protected getDefaultHeaders(): { [key: string]: string } {
        return {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'accept': "application/json",
        };
    }

    /**
     * Validates the response status code.
     * @param response The response object to check.
     * @throws Error if response status is not 200.
     */
    protected async checkResponseStatus(response: any): Promise<void> {
        if (response.status !== 200) {
            throw new Error(`Unexpected status code: ${response.status}`);
        }
    }

    /**
     * Cancels the current chat completion request if it is in progress.
     */
    public cancelCurrentCompletion(): void {
        this.currentAbortController?.abort();
    }


    /**
     * Checks if the user's session is valid.
     * @throws Error if the session is invalid or has expired.
     */
    protected async checkSessionValidity(): Promise<void> {
        const accessTokenExpired = AuthUtil.getAccessTokenExpired();
        const refreshTokenExpired = AuthUtil.getRefreshTokenExpired();

        if (refreshTokenExpired) {
            AuthService.logout();
            throw new Error('Session expired');
        } else if (accessTokenExpired) {
            try {
                await AuthService.requestNewAccessToken(AuthUtil.getRefreshToken());
            } catch {
                AuthService.logout();
                throw new Error('Session expired');
            }
        }
    }

    /**
     * Checks if the ChatGPT service is available for the user.
     * @returns Promise resolving to a boolean indicating service availability.
     */
    public async getServiceAvailableForUser(): Promise<boolean> {
        const response = await this.axiosInstance.get(
            `/v1/${this.getConfiguration().apiResourceName}/service-available-for-user/`,
            { headers: this.getDefaultHeaders() }
        );
        await this.checkResponseStatus(response);
        return (response.data) as boolean ?? false;
    }

    /**
     * Retrieves the available ChatGPT models.
     * @returns Promise resolving to an array of ChatGptModelInterface.
     */
    public async getAvailableModels(): Promise<ChatModelInterface[]> {
        const response = await this.axiosInstance.get(
            `/v1/${this.getConfiguration().apiResourceName}/models/`,
            { headers: this.getDefaultHeaders() }
        );
        await this.checkResponseStatus(response);
        return (response.data) as ChatModelInterface[] ?? [];
    }

    /**
     * Requests a new conversation ID from the server.
     * @returns Promise resolving to the new conversation ID as a string.
     */
    public async requestNewConversationId(): Promise<string> {
        const response = await this.axiosInstance.post(
            `/v1/${this.getConfiguration().apiResourceName}/new-conversation-id/`,
            {},
            { headers: this.getDefaultHeaders() }
        );
        await this.checkResponseStatus(response);
        return response.data;
    }

    /**
     * Completes a chat conversation by sending a prompt and receiving a response stream.
     * @param prompt The text prompt to send.
     * @param useServerSideHistory Optional flag to use server-side history.
     * @param previousMessages Optional array of previous messages.
     * @param onResponse Optional callback for each response chunk.
     * @param onFinishCallback Optional callback when the response is fully received.
     * @param model The ID of the model to use.
     * @param maxTokens Optional maximum number of tokens.
     * @param conversationId Optional conversation ID.
     * @param temperature Optional temperature for response generation.
     * @returns Promise resolving to an object containing a cancel function.
     */
    public async chatCompletion(
        prompt: string,
        useServerSideHistory?: boolean,
        previousMessages?: { message: ChatMessageInterface }[],
        onResponse?: (data: any, prompt: string, currentCompletion: string) => void,
        onFinishCallback?: (data: any, prompt: string, currentCompletion: string) => void,
        model: string = `gpt-3.5-turbo`,
        maxTokens?: number,
        conversationId?: string,
        temperature?: number,
    ): Promise<{ cancel: Function }> {
        return new Promise<{ cancel: Function }>(async (resolve, reject) => {
            const abortSignalHandler = () => resolveWithCleanup();
            const resolveWithCleanup = (...args: any[]) => cleanup() && resolve(args as any);
            const rejectWithCleanup = (error: any) => cleanup() && reject(error);
            const cleanup = () => {
                if (this.currentAbortController) {
                    this.currentAbortController.signal.removeEventListener("abort", abortSignalHandler);
                    this.currentAbortController = null;
                }
                return true;
            };

            await this.checkSessionValidity();

            // cancel current running call if existing
            if (this.currentAbortController) {
                this.currentAbortController.abort();
            }

            // see https://github.com/Azure/fetch-event-source
            this.currentAbortController = new AbortController();
            this.currentAbortController.signal.addEventListener("abort", abortSignalHandler);

            let callCompleted = false;
            let currentCompletion = ``;

            try {
                await fetchEventSource(
                    `${AxiosUtil.baseURL}/v1/${this.getConfiguration().apiResourceName}/as-sse-stream/`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'text/event-stream',
                            'Connection': 'keep-alive',
                            'Cache-Control': 'no-cache',
                            'x-device': AuthUtil.getDeviceId(),
                            'x-conversation-id': conversationId,
                            'Authorization': `Bearer ${AuthUtil.getAccessToken()}`,
                            'accept': "text/event-stream",
                            'Transfer-Encoding': "chunked"
                        },
                        body: JSON.stringify({
                            model: model ?? this.getConfiguration().defaultModel,
                            maxTokens: maxTokens, // null / undefined for default behavior
                            prompt: prompt,
                            useServerSideHistory: useServerSideHistory,
                            previousMessages: previousMessages ?? [],
                            temperature: temperature ?? undefined,
                        }),
                        signal: this.currentAbortController.signal,

                        async onopen(response) {
                            if (!response.ok || response.headers.get('content-type') !== EventStreamContentType) {
                                rejectWithCleanup(new Error(`Unexpected status code: ${response.status}, ${response.statusText}`));
                            }
                        },
                        onmessage(msg) {
                            try {
                                if (msg.data?.toLocaleLowerCase() === '[done]') {
                                    // ignore done message
                                    return;
                                }

                                const data = JSON.parse(msg.data) as ChatCompletionResultInterface;
                                if (msg.event === 'chunk') {
                                    currentCompletion += data.choices[0].delta?.content ?? '';
                                    onResponse?.(data, prompt, currentCompletion);
                                } else if (msg.event === 'end') {
                                    const completion = data.choices[0].message.content;
                                    onFinishCallback?.(data, prompt, completion);
                                    resolveWithCleanup(completion);
                                    callCompleted = true;
                                } else if (msg.event === 'error') {
                                    rejectWithCleanup(new Error(`Unexpected data: ${msg.data}`));
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        },
                        onclose() {
                            if (!callCompleted) {
                                rejectWithCleanup(new Error('Connection closed unexpectedly'));
                            }
                        },
                        onerror(err) {
                            rejectWithCleanup(
                                new Error(
                                    `Unexpected state: ${JSON.stringify(err)}`
                                )
                            );
                        }
                    }
                );
            } catch (error) {
                rejectWithCleanup(error);
            }
        });
    }
}