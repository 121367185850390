// react component for instruction panel with props

import React from "react";
import { useTranslation } from "react-i18next";
import ButtonWithIcon from "../buttonWithIcon/ButtonWithIcon";
import { GearInFolderIcon } from "../icons/GearInFolderIcon";
import { SaveIcon } from "../icons/SaveIcon";
import BaseInput, { BaseInputType } from "../inputs/baseInput/BaseInput";
import "./InstructionPanel.scss";
import InstructionsSelectorDialog from "./instructionsSelectorDialog/InstructionsSelectorDialog";
import InstructionsInstructionsEntryEditDialog from "./instructionsEntryEditDialog/InstructionsEntryEditDialog";
import { InstructionInterface } from "@dashart/dashart-gpt-shared-library";

const ICON_SIZE = 15;
const MAX_LINES = 2;
const MAX_LINES_EXPANDED = 20;

interface InstructionPanelProps {
    instruction: string;

    onInstructionChange?: (instruction: string) => void;
}

export const InstructionPanel: React.FC<InstructionPanelProps> = (props: InstructionPanelProps) => {
    const { t } = useTranslation();

    const [instructionsSelectorIsOpen, setInstructionsSelectorIsOpen] = React.useState(false);
    const [instructionEntryEditDialogIsOpen, setInstructionEntryEditDialogIsOpen] = React.useState(false);
    const [instructionEntryToEditAndViewMode, setInstructionEntryToEditAndViewMode] = React.useState<{
        viewMode: 'create' | 'edit' | 'view',
        instruction: InstructionInterface,
    }>(null);

    const [textAreaAutoResize, setTextAreaAutoResize] = React.useState(false);

    /**
     * The number of lines to display in the input field,
     * depending on whether the input field is expanded or not
     * and whether the instruction is set or not
     */
    const inputLines = React.useMemo(() => {
        const instructionIsSet = props.instruction && props.instruction.trim().length > 0;
        return textAreaAutoResize && instructionIsSet ? MAX_LINES_EXPANDED : MAX_LINES
    }, [props.instruction, textAreaAutoResize]);

    const onSettingsIconClickHandler = () => {
        console.log('icon clicked');

        setInstructionsSelectorIsOpen(true);
    }

    const onSaveIconClickHandler = () => {
        console.log('save icon clicked');
        setInstructionEntryToEditAndViewMode({
            viewMode: 'create',
            instruction: {
                instruction: props.instruction,
            } as any
        });
        setInstructionEntryEditDialogIsOpen(true);
    }

    /**
     * Handler for mouse over
     */
    const onMouseOverHandler = () => {
        setTextAreaAutoResize(true);
    }

    /**
     * Handler for mouse out
     */
    const onMouseOutHandler = () => {
        setTextAreaAutoResize(false);
    }

    return (
        <div className="InstructionPanel" onMouseOver={onMouseOverHandler} onMouseOut={onMouseOutHandler}>

            {instructionsSelectorIsOpen ? <InstructionsSelectorDialog
                width="90vw"
                height="90vh"
                onSelect={(instruction: string) => {
                    props.onInstructionChange(instruction);
                    setInstructionsSelectorIsOpen(false);
                }}
                onDetailViewRequested={(instruction: InstructionInterface, mode: 'edit' | 'view') => {
                    setInstructionEntryToEditAndViewMode({
                        viewMode: mode,
                        instruction,
                    });
                    setInstructionEntryEditDialogIsOpen(true);
                }}
                onclose={() => {
                    setInstructionsSelectorIsOpen(false);
                }}
            /> : null}

            {instructionEntryEditDialogIsOpen ? <InstructionsInstructionsEntryEditDialog
                width="80vw"
                height="80vh"
                mode={instructionEntryToEditAndViewMode.viewMode}
                instructionToEdit={instructionEntryToEditAndViewMode.instruction}
                onSelect={(instruction: string) => {
                    props.onInstructionChange(instruction);
                    setInstructionEntryEditDialogIsOpen(false);
                }}
                onclose={() => {
                    setInstructionEntryEditDialogIsOpen(false);
                }}
                onRequestClose={() => {
                    setInstructionEntryEditDialogIsOpen(false);
                }}
            /> : null}

            <BaseInput
                classes={'instructionsInput m-0'}
                label={t("components.instruction_panel.input.label")}
                labelClasses="label"
                textAreaAutoResize={false}
                textAreaRows={inputLines}
                value={props.instruction}
                type={BaseInputType.TextArea}
                placeholder={t("components.instruction_panel.input.placeholder")}
                handlerChange={props.onInstructionChange}
            />

            <div className={"iconsContainer"}>
                <ButtonWithIcon
                    icon={
                        <SaveIcon className="saveIcon" width={ICON_SIZE} height={ICON_SIZE} />
                    }
                    onClick={onSaveIconClickHandler}
                />
                <ButtonWithIcon
                    icon={
                        <GearInFolderIcon className="gearIcon" width={ICON_SIZE} height={ICON_SIZE} />
                    }
                    onClick={onSettingsIconClickHandler}
                />
            </div>
        </div>
    );
};
