import {History, Location} from 'history';
import {matchPath} from "react-router";

export class RouterUtil {

    private static removeLoadingSlashAndTrim = (route: string): string => {
        return route.trim();
    }

    /**
     * Returns true in case given route is active
     * @param route
     * @param location
     */
    static isRouteActive = (route: string, location: Location): boolean => {
        const match = matchPath(
            {
                path: route,
                caseSensitive: false,
                end: true,
            },
            location.pathname,
        )

        return match !== null;
    }

    /**
     * Returns true in case one of the given routes is active
     * @param routes
     * @param location
     */
    static isOneOfTheRoutesActive = (routes: string[], location: Location): boolean => {
        const hits = routes.filter(route => RouterUtil.isRouteActive(RouterUtil.removeLoadingSlashAndTrim(route), location));
        return hits.length > 0;
    }

    /**
     * Returns true in case given route is active
     * @param route
     * @param location
     */
    static currentLocationStartsWith = (route: string, location: Location): boolean => {
        return RouterUtil.removeLoadingSlashAndTrim(location.pathname).indexOf(
            RouterUtil.removeLoadingSlashAndTrim(route)
        ) === 0;
    }

    /**
     * Redirect to given route
     * @param route
     * @param history
     */
    static redirect = (route: string, history: History) => {
        history.push(route);
    }

    /**
     * Returns true in case given route is active, otherwise redirect to given route will be triggered
     * @param route
     * @param location
     * @param history
     */
    static isRouteActiveOtherwiseRedirect = (route: string, location: Location, history: History): boolean => {
        if (!RouterUtil.isRouteActive(RouterUtil.removeLoadingSlashAndTrim(route), location)) {
            RouterUtil.redirect(route, history);
            return false;
        } else {
            return true;
        }
    }

    /**
     * Returns true in case one of the given routes is active, if not redirect will be triggered
     * @param routes
     * @param redirectRoute
     * @param location
     * @param history
     */
    static isOneOfTheRoutesActiveOtherwiseRedirect = (routes: string[], redirectRoute: string, location: Location, history: History): boolean => {
        const hits = routes.filter(route => RouterUtil.isRouteActive(RouterUtil.removeLoadingSlashAndTrim(route), location));
        if (hits.length <= 0) {
            RouterUtil.redirect(redirectRoute, history);
            return false;
        } else {
            return true;
        }
    }

}