import { CompanyInterface } from "@dashart/dashart-gpt-shared-library";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../app/models/RecoilStates";
import { CompanyService } from "../../../app/services/CompanyService";
import { UserService } from "../../../app/services/UserService";
import BaseInput, { BaseInputType } from "../inputs/baseInput/BaseInput";

export interface OverlayAssignUserToCompanyProps {
    company: CompanyInterface;

    onClose?: () => void;
    onHide?: () => void;
}

const I18N_BASE_KEY = 'company_management.users_list_screen.assign_user_overlay.';

/**
 * Convert users to option values
 * @param users 
 * @returns 
 */
const usersToOptionValues = (users: UserInterface[]) => {
    return users.map((user) => {
        return userToOptionValue(user)
    })
}

/**
 * Get option value from user
 * @param user 
 * @returns 
 */
const userToOptionValue = (user: UserInterface) => {
    if (!user) {
        return '';
    }
    return `${user.familyName}, ${user.firstName} (${user.email})`
}

/**
 * Get user from option value
 * @param usersList 
 * @param optionValue 
 * @returns 
 */
const userFromOptionValue = (usersList: UserInterface[], optionValue: string): UserInterface => {
    if (!optionValue) {
        return null;
    }
    const optionValueParts = optionValue.split(' (');
    const email = (optionValueParts[1]?.replace(')', '')) ?? '';
    const firstName = (optionValueParts[0]?.split(', ')[1]) ?? '';
    const familyName = (optionValueParts[0]?.split(', ')[0]) ?? '';

    return usersList.find((user) => {
        return user.email === email.trim()
            && user.firstName === firstName.trim()
            && user.familyName === familyName.trim();
    });
}

const OverlayAssignUserToCompany: React.FunctionComponent<OverlayAssignUserToCompanyProps> = (props: OverlayAssignUserToCompanyProps) => {

    const { t } = useTranslation();
    const [users, setUsers] = React.useState<UserInterface[]>([]);
    const [userOptions, setUserOptions] = React.useState<string[]>([]);
    const [selectedUser, setSelectedUser] = React.useState<UserInterface>(null);
    const [lastUserSearchTerm, setLastUserSearchTerm] = React.useState<string>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [fetchFailed, setFetchFailed] = React.useState<boolean>(false);
    const [showOnlyUnassignedUsers, setShowOnlyUnassignedUsers] = React.useState<boolean>(true);
    const [lastToastMessage, setLastToastMessage] = useRecoilState(RecoilStates.lastToastMessageState);

    /**
     * Fetch all users
     */
    React.useEffect(() => {
        setLoading(true);
        setFetchFailed(false);

        // TODO: fetch only users that are not already assigned to this company

        UserService.getAll()
            .then((result) => {
                setUsers(result ?? []);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setFetchFailed(true);
                setLoading(false);
            })
    }, [])

    React.useEffect(() => {
        setUserOptions(
            usersToOptionValues(users ?? [])
        )
    }, [users]);

    /**
     * Search users
     * @param searchTerm 
     */
    const searchUsers = async (searchTerm: string) => {
        setLastUserSearchTerm(searchTerm);
        try {
            const result = await UserService.search(
                searchTerm,
                showOnlyUnassignedUsers
            );
            setUsers(result ?? []);
        } catch (error) {
            console.log(error);
            setLastToastMessage({
                severity: 'error',
                summary: t(`${I18N_BASE_KEY}search_failed`),
                detail: JSON.stringify(error?.message),
            });
        }
    }

    /**
     * Handle selection change
     * @param selectedValue 
     */
    const onUserSelectionChange = (selectedValue) => {
        const selectedUser = userFromOptionValue(users, selectedValue);

        setSelectedUser(
            selectedUser
        );
    }

    /**
     * Handle search request
     * @param event 
     */
    const onSearchUsers = async (event) => {
        searchUsers(event);
    }

    /**
     * Handle show only unassigned users change
     * @param event 
     */
    const onShowOnlyUnassignedUsersChange = (event) => {
        setShowOnlyUnassignedUsers(event);
        searchUsers(lastUserSearchTerm);
    }

    /**
     * Handle cancel, close overlay
     */
    const onCancel = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    /**
     * Handle confirm, assign user to company
     */
    const onConfirm = () => {
        CompanyService.addUser(
            props.company.id,
            {
                userId: selectedUser.id,
            }
        ).then((result) => {
            if (result) {
                setLastToastMessage({
                    severity: 'success',
                    summary: t(`${I18N_BASE_KEY}assign_success`),
                });
                if (props.onHide) {
                    // TODO: implement
                    props.onHide();
                }
            } else {
                setLastToastMessage({
                    severity: 'error',
                    summary: t(`${I18N_BASE_KEY}assign_error`),
                    detail: t(JSON.stringify(result)),
                });
            }
        }).catch((error) => {
            console.log(error);
            setLastToastMessage({
                severity: 'error',
                summary: t(`${I18N_BASE_KEY}assign_error`),
                detail: JSON.stringify(error?.message),
            });
        });
    }

    const footer = <div>
        <Button
            label={t(`${I18N_BASE_KEY}btn_cancel`)}
            className='p-button-secondary'
            icon="pi pi-times"
            onClick={onCancel}
        />
        <Button
            label={t(`${I18N_BASE_KEY}btn_assign`)}
            className='p-button'
            icon="pi pi-check"
            onClick={onConfirm}
            disabled={!selectedUser}
        />
    </div>

    return <Dialog
        header={t(`${I18N_BASE_KEY}header`)}
        visible={true}
        footer={footer}
        onHide={props.onHide}
        style={{ width: '50vw', maxWidth: '50vw', whiteSpace: 'pre-wrap' }}
    >

        <BaseInput
            classes="mb-2 flex"
            labelClasses="col-10"
            inputClasses="col-2"
            type={BaseInputType.Checkbox}
            label={t(`${I18N_BASE_KEY}show_only_unassigned_users`)}
            value={showOnlyUnassignedUsers}
            handlerChange={onShowOnlyUnassignedUsersChange}
        />

        <BaseInput
            classes="mb-2"
            labelClasses="col-4"
            inputClasses="col-8"
            type={BaseInputType.AutoComplete}
            label={t(`${I18N_BASE_KEY}label_user`)}
            value={userToOptionValue(selectedUser)}
            options={userOptions}
            handlerChange={onUserSelectionChange}
            autoCompleteSearchRequest={onSearchUsers}
        />

    </Dialog>
}

export default OverlayAssignUserToCompany;