import React, { useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ChatModelInterface } from '../../../app/interfaces/ChatModelInterface';
import ButtonWithIcon from "../buttonWithIcon/ButtonWithIcon";
import { BrainIcon } from '../icons/BrainIcon';
import { LinesCountIcon } from "../icons/LinesCountIcon";
import { TemperatureIcon } from '../icons/TemperatureIcon';
import { TokensIcon } from "../icons/TokensIcon";
import SelectorDialogDialog from './SelectorDialog/SelectorDialog';


const maxTokensValues = [
    null, 200, 500, 1000, 2000, 4000, 8000, 16000
]
const maxTokensListValues = [
    ...maxTokensValues.map((option) => {
        return {
            label: `${option}`,
            value: option,
        }
    })
]
// values between 0.1 and 2.0
const temperatureValues = [
    ...Array.from(Array(20).keys()).map((index) => {
        return (index + 1) / 10 * 1;
    })
]
const temperatureListValues = [
    ...temperatureValues.map((option) => {
        return {
            label: `${option}`,
            value: option,
        }
    })
]

// values between 4 and 20
const inputLinesValues = [
    4, // default
    ...Array.from(Array(8).keys()).map((index) => {
        return (index + 1) * 2 + 4;
    })
]
const inputLinesListValues = [
    ...inputLinesValues.map((option) => {
        return {
            label: `${option}`,
            value: option,
        }
    })
]

const iconDefaultProps = {
    width: 24,
    height: 24,
}

export interface ChatOptionsProps {
    completionIsRunning: boolean;
    completionError: string | null;

    gptModel: ChatModelInterface;
    availableModels: ChatModelInterface[];
    temperature: number;
    maxTokens: number;
    inputTextLines: number;

    setGptModel: (model: ChatModelInterface) => void;
    setTemperature: (temperature: number) => void;
    setMaxTokens: (maxTokens: number) => void;
    setInputTextLines: (inputTextLines: number) => void;
}

export const ChatOptions: React.FunctionComponent<ChatOptionsProps> = (props: ChatOptionsProps) => {
    const {
        completionIsRunning,
        completionError,
        gptModel,
        temperature,
        maxTokens,
        inputTextLines,
        setTemperature,
        setMaxTokens,
        setInputTextLines,
    } = props;
    const { t } = useTranslation();
    const [display, setDisplay] = useState({
        model: false,
        temp: false,
        lines: false,
        tokens: false,
    });

    /**
     * Current state of the completion or the error message
     */
    const completionStateText = useMemo(() => {
        if(completionIsRunning) {
            return t('components.chat_options.status.loading');
        }

        if(completionError) {
            return completionError;
        }
        return t('components.chat_options.status.idle');
    }, [completionIsRunning, completionError])

    const showDialog = (name) => {
        setDisplay(prev => ({ ...prev, [name]: true }));
    };

    const hideDialog = (name) => {
        setDisplay(prev => ({ ...prev, [name]: false }));
    };

    React.useEffect(() => {
        // set default values
        setTemperature(temperatureValues[9]); // default is 1.0
        setMaxTokens(maxTokensValues[0]);
    }, []);

    return (
        <div key="1" className={'flex width-full pl-2 pr-2 pt-1 pb-1'}>

            {/* overlays */}
            <>
                {/* model selection */}
                {display.model ? <SelectorDialogDialog
                    header={t('components.chat_options.selected_model.header')}
                    description={t('components.chat_options.selected_model.description')}
                    width="50vw"
                    labelKey="id"
                    availableValues={props.availableModels}
                    selectedValues={props.gptModel}
                    onSelect={(model) => {
                        // take care user does not deselect a model,
                        // we need always a selected model
                        if(model) {
                            props.setGptModel(model);
                        }
                        hideDialog('model');
                    }}
                    onclose={() => hideDialog('model')}
                /> : null}

                {/* temperature selection */}
                {display.temp ? <SelectorDialogDialog
                    header={t('components.chat_options.temperature.header')}
                    description={t('components.chat_options.temperature.description')}
                    width="50vw"
                    labelKey="label"
                    availableValues={temperatureListValues}
                    selectedValues={temperature}
                    onSelect={(value) => {
                        setTemperature(value);
                        hideDialog('temp');
                    }}
                    onclose={() => hideDialog('temp')}
                /> : null}

                {/* lines selection */}
                {display.lines ? <SelectorDialogDialog
                    header={t('components.chat_options.input_lines_count.header')}
                    description={t('components.chat_options.input_lines_count.description')}
                    width="50vw"
                    labelKey="label"
                    availableValues={inputLinesListValues}
                    selectedValues={inputTextLines}
                    onSelect={(value) => {
                        setInputTextLines(value);
                        hideDialog('lines');
                    }}
                    onclose={() => hideDialog('lines')}
                /> : null}

                {/* maximum tokens selection */}
                {display.tokens ? <SelectorDialogDialog
                    header={t('components.chat_options.max_tokens.header')}
                    description={t('components.chat_options.max_tokens.description')}
                    width="50vw"
                    labelKey="label"
                    availableValues={maxTokensListValues}
                    selectedValues={maxTokens}
                    onSelect={(value) => {
                        setMaxTokens(value);
                        hideDialog('tokens');
                    }}
                    onclose={() => hideDialog('tokens')}
                /> : null}
            </>

            <div className={'flex-grow-1 flex align-items-center'}>
                {t('components.chat_options.status.label')}:&nbsp;{completionStateText}
            </div>
            <div className={'flex align-items-center flex-end gap-3'}>
                <ButtonWithIcon
                    icon={
                        <BrainIcon {...iconDefaultProps} />
                    }
                    onClick={() => showDialog('model')}
                    label={gptModel?.id ?? 'null'}
                />

                <ButtonWithIcon
                    icon={
                        <TemperatureIcon {...iconDefaultProps} />
                    }
                    onClick={() => showDialog('temp')}
                    label={temperature?.toString() ?? 'null'}
                />

                <ButtonWithIcon
                    icon={
                        <TokensIcon {...iconDefaultProps} />
                    } additionalClassNames="p-button-outlined"
                    onClick={() => showDialog('tokens')}
                    label={maxTokens?.toString() ?? 'null'}
                />

                <ButtonWithIcon
                    icon={
                        <LinesCountIcon {...iconDefaultProps} />
                    } additionalClassNames="p-button-outlined"
                    onClick={() => showDialog('lines')}
                    label={inputTextLines?.toString() ?? 'null'}
                />
            </div>
        </div>
    )
}

export default ChatOptions;
