import * as React from "react";
import { CSSProperties } from "react";

export const SaveIcon: React.FC<{ className?: any, color?: string, width: number, height: number }> = (props) => {

    const style: CSSProperties = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: props.color ? props.color : '#000000',
    }

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                style={style}
                d="M32 32H0V64 448v32H32 416h32V448 160 146.7l-9.4-9.4-96-96L333.3 32H320 32zM64 96H320V224H64V96zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
            />
        </g>
    </svg>
}