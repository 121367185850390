export class ServiceUtils {
    /**
     * Removes common blocking data from data object like id, createdAt, updatedAt
     * @param dataObject
     */
    static cleanUpdateCallFromDefaultData = (dataObject: any): any => {
        return ServiceUtils.removePropertiesFromDataObject(
            [
                'id',
                'createdAt',
                'updatedAt',
            ],
            dataObject
        )
    }

    /**
     * Removes given keys from data object if existing
     * @param propertyKeys
     * @param dataObject
     */
    static removePropertiesFromDataObject = (propertyKeys: string[], dataObject: any): any => {
        if(!dataObject) {
            return dataObject;
        }

        propertyKeys.map(key => {
            if (dataObject.hasOwnProperty(key)) {
                delete dataObject[key];
            }
        })

        return dataObject;
    }
}