import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { CompanyService } from "@app/services/CompanyService";
import { CompanyACLUtil, CompanyInterface } from "@dashart/dashart-gpt-shared-library/dist";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menubar } from "primereact/menubar";
import { Panel } from "primereact/panel";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { ToastMessageInterface } from "../../../app/interfaces/ToastMessageInterface";
import OverlayAssignUserToCompany from "../../components/overlayAssignUserToCompany/OverlayAssignUserToCompany";
import RoleValidatorComponent from "../../components/roleValidatorComponent/RoleValidatorComponent";

const MAX_ENTRIES_PER_PAGE = 50;

export interface CompanyUsersListScreenProps {
    currentUser: UserInterface;
    showToast: (toast: ToastMessageInterface) => void
}

const CompanyUsersListScreen: React.FunctionComponent<CompanyUsersListScreenProps> = (props: CompanyUsersListScreenProps) => {
    const { t } = useTranslation();
    const { currentUser } = props;
    const { companyId } = useParams();
    const [data, setData] = React.useState<{
        entries: UserInterface[],
        currentPage: number,
        hasNextPage: boolean,
    }>({ entries: [], currentPage: 1, hasNextPage: false });
    const [entriesFetchFailed, setEntriesFetchFailed] = React.useState<boolean>(false);
    const [assignUserOverlayOpen, setAssignUserOverlayOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const loadEntries = async (page: number = 1) => {
        setEntriesFetchFailed(false);
        try {
            const result = await CompanyService.getUsers(companyId, page, MAX_ENTRIES_PER_PAGE);
            setData({
                entries: result?.data ?? [],
                currentPage: page,
                hasNextPage: result?.meta?.next_page_url ? true : false,
            });
            setEntriesFetchFailed(false);
        } catch (error) {
            console.log(error);
            setEntriesFetchFailed(true);
        }
    }

    useEffect(() => {
        loadEntries(1);
    }, [])

    /**
     * Assign existing user to company
     */
    const assignExistingUser = () => {
        setAssignUserOverlayOpen(true);
    }

    /**
     * Close assign user overlay
     * and reload current page
     */
    const closeAssignUserOverlay = () => {
        setAssignUserOverlayOpen(false);

        // reload current page
        loadEntries(data.currentPage);
    }

    /**
     * Cell parser for user name
     * @param rowData 
     * @returns 
     */
    const cellParserName = (rowData: UserInterface) => {
        return (
            <>
                {`${rowData.familyName}, ${rowData.firstName}`}
            </>
        );
    }

    /**
     * Cell parser for user role
     * @param rowData 
     * @returns 
     */
    const cellParserRole = (rowData: UserInterface) => {
        return (
            <>
                {t('globals.roles.' + rowData.role)}
            </>
        );
    }

    /**
     * Cell parser for company user row actions
     * @param rowData 
     * @returns 
     */
    const cellParserActions = (rowData: UserInterface) => {
        const buttons = [];

        if (CompanyACLUtil.canUserAddOrRemoveUserFromCompany(props.currentUser, { id: parseInt(companyId) })) {
            buttons.push(
                <ButtonWithLink
                    key={`${rowData.id}-btn-edit`}
                    className='mr-2'
                    label={t('company_management.users_list_screen.action_edit_user')}
                    icon={'pi pi-fw pi-pencil'}
                    href={RoutesEnum.userEdit.replace(RoutesParametersEnum.userId, `${rowData.id}`)}
                />
            );
        }

        return (
            <>
                {buttons}
            </>
        );
    }

    const actionMenu = (key: string = '') => {
        const buttons = [];

        if (CompanyACLUtil.canUserAddOrRemoveUserFromCompany(props.currentUser, { id: parseInt(companyId) })) {
            buttons.push(
                <ButtonWithLink
                    key={key + "-btn-add"}
                    className='mr-2'
                    label={t('company_management.users_list_screen.action_add_new_user')}
                    icon={'pi pi-fw pi-plus'}
                    href={RoutesEnum.userCreate}
                />
            )
            buttons.push(
                <Button
                    key={key + "-btn-assign"}
                    className='mr-2'
                    label={t('company_management.users_list_screen.action_assign_existing_user')}
                    icon={'pi pi-fw pi-plus'}
                    onClick={assignExistingUser}
                />
            )
        }

        // back button
        buttons.push(
            <Button
                key={key + "-btn-back"}
                className='mr-2'
                label={t('globals.actions.backToOverview')}
                icon={'pi pi-fw pi-arrow-left'}
                onClick={() => navigate(-1)}
            />
        )

        return <Menubar
            className={'mt-2 mb-2'}
            end={<>
                {buttons}
            </>}
        />
    }

    return <div className='CompanyUsersListScreen d-list-screen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'companyScope'}
            companyOfInterest={{ id: currentUser.companyId } as CompanyInterface}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />

        {assignUserOverlayOpen ? <OverlayAssignUserToCompany
            company={{ id: parseInt(companyId) } as CompanyInterface}
            onClose={closeAssignUserOverlay}
            onHide={closeAssignUserOverlay}
        /> : null}


        <Panel header={t("company_management.users_list_screen.header")} className={"p-4"}>

            {/* TODO: paginator */}

            {actionMenu('top')}
            <div className={'table-container'}>
                <DataTable value={data?.entries ?? []}
                    rowHover={true}
                    scrollable scrollHeight={'flex'}
                    sortField="name" sortOrder={1}
                    paginator rows={MAX_ENTRIES_PER_PAGE}
                >
                    <Column field="id" header="ID" style={{ width: '5em' }} sortable={true} />
                    <Column header={t('company_management.users_list_screen.column_user_name')}
                        body={cellParserName}
                        sortable={true}
                        sortField={'familyName'}
                    />
                    <Column header={t('company_management.users_list_screen.column_user_role')}
                        body={cellParserRole}
                        sortable={true}
                        sortField={'role'}
                    />
                    <Column
                        header={t('user_management.actions')}
                        body={cellParserActions}
                        style={{ width: '15rem' }}
                    />
                </DataTable>
            </div>
            {actionMenu('bottom')}

        </Panel>
    </div>
}

export default CompanyUsersListScreen;
