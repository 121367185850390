import { CreateInstructionDto, InstructionInterface } from '@dashart/dashart-gpt-shared-library';
import { transformAndValidateSync } from 'class-transformer-validator';
import { ValidationError } from 'class-validator';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import RecoilStates from '../../../../app/models/RecoilStates';
import { InstructionsService } from '../../../../app/services/InstructionsService';
import FormValidationErrorFieldset from '../../formValidationErrorFieldset/FormValidationErrorFieldset';
import BaseInput, { BaseInputType } from '../../inputs/baseInput/BaseInput';
import ProgressSpinnerOverlay from '../../progressSpinnerOverlay/ProgressSpinnerOverlay';
import './InstructionsEntryEditDialog.scss';

interface FormData extends InstructionInterface {
    passwordConfirm?: string,
}

export interface InstructionsEntryEditDialogProps {
    width: string;
    height: string;

    instructionToEdit?: InstructionInterface;

    mode: 'create' | 'edit' | 'view';

    onRequestClose: () => void;
    onSelect: (instruction: string) => void;
    onclose: () => void;
}

export const InstructionsInstructionsEntryEditDialog: React.FunctionComponent<InstructionsEntryEditDialogProps> = (props: InstructionsEntryEditDialogProps) => {
    const { mode } = props;
    const { t } = useTranslation();

    const [currentUser, setCurrentUser] = useRecoilState(RecoilStates.currentUserState);
    const [userIsOwner, setUserIsOwner] = useState<boolean>(false);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [serverActionPending, setServerActionPending] = useState(false);

    const [formData, setFormData] = useState<FormData>(null);
    const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

    const setLastUpdatedInstructionIdAndTs = useSetRecoilState(RecoilStates.lastUpdatedInstructionIdAndTsState);

    const showToast = useSetRecoilState(RecoilStates.lastToastMessageState);

    useEffect(() => {
        const isCreateMode = mode === 'create';
        const isEditMode = mode === 'create' || mode === 'edit';
        setIsEditMode(isEditMode);
        setUserIsOwner(true);
        setFormData({
            ...props.instructionToEdit,
        });
    }, [currentUser, props.mode, props.instructionToEdit])

    /**
     * Load entry from backend
     * @param instructionId
     */
    const loadEntry = async (instructionId: number) => {
        setServerActionPending(true);
        try {
            setFormData(await InstructionsService.getInstance().getInstruction(instructionId));
        } catch (error) {
            showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            props.onRequestClose();
        }
        setServerActionPending(false);
    };

    /**
     * Update user in backend
     * @param instructionId
     */
    const updateEntry = async (instructionId: number) => {
        setServerActionPending(true);
        try {
            const update = await InstructionsService.getInstance().updateInstruction(instructionId, formData);
            props.onRequestClose();
            props.onSelect(update.instruction);
            showToast({
                severity: "success",
                summary: t('globals.updateSuccessNotice'),
            });

            setLastUpdatedInstructionIdAndTs(`${update.id} ${new Date().getTime()}`);
        } catch (error) {
            showToast({
                severity: "error",
                summary: t('globals.updateFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    /**
     * Create user in backend
     */
    const createEntry = async () => {
        setServerActionPending(true);
        try {
            const entry = await InstructionsService.getInstance().createInstruction(formData);
            showToast({
                severity: "success",
                summary: t('globals.createSuccessNotice'),
            });
            props.onRequestClose();
            props.onSelect(entry.instruction);
            setLastUpdatedInstructionIdAndTs(`${entry.id} ${new Date().getTime()}`);
        } catch (error) {
            showToast({
                severity: "error",
                summary: t('globals.createFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    /**
     * Load entry from backend if instructionToEdit is set
     */
    useEffect(() => {
        if (props.instructionToEdit && props.instructionToEdit.id) {
            loadEntry(props.instructionToEdit.id);
        }
    }, [props.instructionToEdit]);

    /**
     * Sets the form data and validates it
     * @param formData 
     */
    const setAndValidateInputs = (formData: FormData) => {
        if (formData) {
            formData = {
                ...formData,
                userId: formData.userId ? formData.userId : currentUser.id,
                user: formData.user ? formData.user : currentUser,
                companyId: formData.companyId ? formData.companyId : currentUser.companyId,
                company: formData.company ? formData.company : currentUser.company,
            };
            validateInputs(formData);
        }

        setFormData(formData);
        return formData;
    }

    /**
     * Validates the form data
     * @param formData 
     * @returns 
     */
    const validateInputs = (formData: FormData): ValidationError[] => {
        try {
            transformAndValidateSync(CreateInstructionDto, formData);
            setFormErrors([]);
            return [];
        } catch (err) {
            setFormErrors(err);
            return err;
        }
    }

    /**
     * Validates the logic and triggers form data save
     */
    const onSaveClickHandler = async () => {
        const errors = validateInputs(
            // set and validate inputs to get default data prefilled
            setAndValidateInputs(formData)
        );

        if (errors?.length > 0) {
            showToast({
                severity: "error",
                summary: t('globals.validationFailedNotice')
            });
        } else {
            if (mode === 'edit') {
                await updateEntry(props.instructionToEdit.id);
            } else if (mode === 'create') {
                await createEntry();
            }
        }
    }

    /**
     * Action buttons
     * @returns 
     */
    const actionButtons = React.useMemo(() => {
        if (mode === 'create') {
            return <div className="flex justify-content-end flex-wrap">
                <Button
                    severity="secondary"
                    label={t('globals.actions.cancel')}
                    icon="pi pi-times"
                    onClick={props.onRequestClose}
                />
                <Button
                    className="ml-2"
                    label={t('globals.actions.create')}
                    icon="pi pi-check"
                    onClick={onSaveClickHandler}
                />
            </div>
        } else if (mode === 'edit') {
            return <div className="flex justify-content-end flex-wrap">
                <Button
                    severity="secondary"
                    label={t('globals.actions.dismiss')}
                    icon="pi pi-times"
                    onClick={props.onRequestClose}
                />
                <Button
                    className="ml-2"
                    label={t('globals.actions.save')}
                    icon="pi pi-check"
                    onClick={onSaveClickHandler}
                />
            </div>
        }

        return <div className="flex justify-content-end flex-wrap">
            <Button
                className="button-secondary"
                label={t('globals.actions.backToOverview')}
                icon="pi pi-chevron-left"
                onClick={props.onRequestClose}
            />
        </div>;
    }, [mode, formData, formErrors]);

    return (
        <Dialog
            className="InstructionsInstructionsEntryEditDialog"
            header={props.mode == "create" ? t('components.instruction_panel.instructionsInstructionsEntryEditDialog.headerCreate') : t('components.instruction_panel.instructionsInstructionsEntryEditDialog.headerUpdate')}
            visible={true}
            style={{ width: props.width, height: props.height }}
            onHide={props.onclose}
            closeOnEscape={true}
        >

            <ProgressSpinnerOverlay visible={serverActionPending} />

            <div className="p-fluid formgrid grid">
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t("components.instruction_panel.instructionsInstructionsEntryEditDialog.label_shortDescription")}
                    value={formData?.shortDescription}
                    valueName={'shortDescription'}
                    formErrors={formErrors}
                    readOnly={!isEditMode}
                    handlerChange={
                        (data: any) => {
                            setAndValidateInputs({ ...formData, shortDescription: data });
                        }
                    }
                />

                <BaseInput
                    type={BaseInputType.TextArea}
                    classes={'col-12'}
                    label={t("components.instruction_panel.instructionsInstructionsEntryEditDialog.label_instruction")}
                    value={formData?.instruction}
                    valueName={'instruction'}
                    formErrors={formErrors}
                    readOnly={!isEditMode}
                    textAreaAutoResize={true}
                    textAreaRows={10}
                    handlerChange={
                        (data: any) => {
                            setAndValidateInputs({ ...formData, instruction: data });
                        }
                    }
                />

                <BaseInput
                    type={BaseInputType.Checkbox}
                    classes={'col-4 flex align-items-center flex-row-reverse flex-wrap'}
                    inputClasses='flex-initial mr-2 ml-2'
                    labelClasses='flex-1'
                    label={t("components.instruction_panel.instructionsInstructionsEntryEditDialog.label_sharedWithTeam")}
                    value={formData?.sharedWithTeam}
                    valueName={'sharedWithTeam'}
                    formErrors={formErrors}
                    readOnly={!isEditMode || !userIsOwner}
                    handlerChange={
                        (data: any) => {
                            setAndValidateInputs({ ...formData, sharedWithTeam: data });
                        }
                    }
                />

                <BaseInput
                    type={BaseInputType.Checkbox}
                    classes={'col-4 flex align-items-center flex-row-reverse flex-wrap'}
                    inputClasses='flex-initial mr-2 ml-2'
                    labelClasses='flex-1'
                    label={t("components.instruction_panel.instructionsInstructionsEntryEditDialog.label_teamCanModify")}
                    value={formData?.teamCanModify}
                    valueName={'teamCanModify'}
                    formErrors={formErrors}
                    readOnly={!isEditMode || !userIsOwner}
                    handlerChange={
                        (data: any) => {
                            setAndValidateInputs({ ...formData, teamCanModify: data });
                        }
                    }
                />

                <BaseInput
                    type={BaseInputType.Checkbox}
                    classes={'col-4 flex align-items-center flex-row-reverse flex-wrap'}
                    inputClasses='flex-initial mr-2 ml-2'
                    labelClasses='flex-1'
                    label={t("components.instruction_panel.instructionsInstructionsEntryEditDialog.label_teamCanDelete")}
                    value={formData?.teamCanDelete}
                    valueName={'teamCanDelete'}
                    formErrors={formErrors}
                    readOnly={!isEditMode || !userIsOwner}
                    handlerChange={
                        (data: any) => {
                            setAndValidateInputs({ ...formData, teamCanDelete: data });
                        }
                    }
                />

            </div>

            <div className={'messages-area'}>

                {formErrors?.length > 0 ? <FormValidationErrorFieldset
                    headline={t("components.instruction_panel.instructionsInstructionsEntryEditDialog.label_validation_errors")}
                    translationKeyBase={"components.instruction_panel.instructionsInstructionsEntryEditDialog.label_"}
                    validationErrors={formErrors}
                /> : null}

            </div>

            <div className="col-12 d-flex justify-end">
                {actionButtons}
            </div>

        </Dialog>
    );
}

export default InstructionsInstructionsEntryEditDialog;
