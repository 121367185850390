import * as React from "react";
import {useEffect, useRef} from "react";
import "./ToastComponent.scss"
import {Toast} from "primereact/toast";
import {ToastMessageInterface} from "@app/interfaces/ToastMessageInterface";

export interface ToastComponentProps {
    lastToast: ToastMessageInterface;
}

const ToastComponent: React.FunctionComponent<ToastComponentProps> = (props: ToastComponentProps) => {
    const toast = useRef(null);

    useEffect(() => {
        const {lastToast} = props;
        if (lastToast) {
            const truncatedDetail = (lastToast.detail?.length > 300 ) ? `${lastToast.detail.substring(0,300)} ...`  : lastToast.detail;

            toast.current.show({
                severity: lastToast.severity,
                summary: lastToast.summary,
                detail: truncatedDetail,
                life: lastToast.lifetime ?? 5000
            })
        }
    }, [props.lastToast])

    return <div
        className={'ToastComponent'}
    >
        <Toast ref={toast}/>
    </div>;
}

export default ToastComponent;