import { ButtonProps } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import * as React from "react";
import { useMemo } from "react";
import styles from "./ButtonWithIcon.module.scss";

export interface ButtonWithLinkProps extends ButtonProps {
    icon: React.ReactNode;
    label?: string;
    tooltip?: string;
    tooltipPosition?: "left" | "right" | "top" | "bottom" | undefined;
    additionalClassNames?: string;
    additionalLabelClassNames?: string;
    onClick?: () => void;
}

const ButtonWithIcon: React.FunctionComponent<ButtonWithLinkProps> = (props: ButtonWithLinkProps) => {

    const uniqueDivId = useMemo(() => {
        return "unqId-" + Math.random().toString(36).substr(2, 9);
    }, []);

    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    // @ts-ignore
    return <div
        id={uniqueDivId}
        className={`${styles.ButtonWithIcon} ${props.additionalClassNames ? props.additionalClassNames : ""}`}
        onClick={onClickHandler}
    >

        <Tooltip
            target={`#${uniqueDivId}`}
            content={props.tooltip}
            position={props.tooltipPosition}
        />

        <div className={styles.icon}>
            {props.icon}
        </div>

        {props.label ?
            <div className={`${styles.label} ${props.additionalLabelClassNames ?? ''}`}>
                {props.label}
            </div>
            :
            null
        }
    </div>
}

export default ButtonWithIcon;