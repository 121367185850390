// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonWithIcon{display:flex;cursor:pointer;align-items:center;flex-direction:row;gap:.2rem;cursor:pointer}.ButtonWithIcon:hover{opacity:.75}.ButtonWithIcon .label{margin-top:-4px}`, "",{"version":3,"sources":["webpack://./src/view/components/buttonWithIcon/ButtonWithIcon.module.scss"],"names":[],"mappings":"AAAA,gBACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,SAAA,CAEA,cAAA,CAEA,sBACE,WAAA,CAGF,uBACE,eAAA","sourcesContent":[".ButtonWithIcon {\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  flex-direction: row;\n  gap: 0.2rem;\n\n  cursor: pointer;\n\n  &:hover {\n    opacity: .75;\n  }\n\n  .label {\n    margin-top: -4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonWithIcon": `ButtonWithIcon`,
	"label": `label`
};
export default ___CSS_LOADER_EXPORT___;
