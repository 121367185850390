import { SentryHelper } from "@app/helpers/SentryHelper";
import { AxiosUtil } from "@app/utils/AxiosUtil";
import { ServiceUtils } from "@app/utils/ServiceUtils";
import { CreateUserDto } from "@dashart/dashart-gpt-shared-library/dist/dtos/user/create-user.dto";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import { AxiosInstance, AxiosResponse } from "axios";

export class UserService {

    private static axiosInstance: AxiosInstance;

    private static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = AxiosUtil.createPreconfiguredAxiosInstance();
        }

        return this.axiosInstance;
    }

    /**
     * Get all users
     */
    static async getAll(offset: number = 0, limit: number = 50): Promise<UserInterface[]> {
        return this.getAxiosInstance().get(
            '/v1/users',
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<UserInterface[]>response.data ?? [])
            },
            (error) => {
                console.log('Get users failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Get one user
     */
    static async getOne(userId: string): Promise<UserInterface> {
        return this.getAxiosInstance().get(
            '/v1/users/' + userId,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<UserInterface>response.data ?? null)
            },
            (error) => {
                console.log('Get user failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Create one user
     */
    static async createOne(user: CreateUserDto): Promise<UserInterface> {
        return this.getAxiosInstance().post(
            '/v1/users/',
            user
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<UserInterface>response.data ?? null)
            },
            (error) => {
                console.log('Create user failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Update one user
     */
    static async updateOne(userId: string, user: CreateUserDto): Promise<UserInterface> {
        user = ServiceUtils.cleanUpdateCallFromDefaultData(user);

        user = ServiceUtils.removePropertiesFromDataObject([
            'rememberToken'
        ], user);

        return this.getAxiosInstance().patch(
            '/v1/users/' + userId,
            user
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<UserInterface>response.data ?? null)
            },
            (error) => {
                console.log('Create user failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Delete one user
     */
    static async deleteOne(userId: string): Promise<UserInterface> {
        return this.getAxiosInstance().delete(
            '/v1/users/' + userId,
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<UserInterface>response.data ?? null)
            },
            (error) => {
                console.log('Delete user failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Search users by first name, family name, email
     */
    static async search(searchTerm: string, onlyUsersWithoutAssignedCompany: boolean = false, offset: number = 0, limit: number = 50): Promise<UserInterface[]> {
        return this.getAxiosInstance().get(
            '/v1/users/search',
            {
                params: {
                    searchTerm: searchTerm,
                    onlyUsersWithoutAssignedCompany,
                    offset,
                    limit
                }
            }
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<UserInterface[]>response.data ?? [])
            },
            (error) => {
                console.log('Search users failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }
}
