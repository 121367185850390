import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { UserService } from "@app/services/UserService";
import { UserACLUtil } from "@dashart/dashart-gpt-shared-library/dist";
import { UserRolesEnum } from "@dashart/dashart-gpt-shared-library/dist/";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menubar } from "primereact/menubar";
import { Panel } from "primereact/panel";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RoleValidatorComponent from "../../components/roleValidatorComponent/RoleValidatorComponent";

export interface UserListScreenProps {
    currentUser: UserInterface;
}

const UserListScreen: React.FunctionComponent<UserListScreenProps> = (props: UserListScreenProps) => {
    const { currentUser } = props;
    const { t } = useTranslation();
    const [users, setUsers] = React.useState<UserInterface[]>([]);
    const [usersFetchFailed, setUsersFetchFailed] = React.useState<boolean>(false);

    const loadUsers = async () => {
        setUsersFetchFailed(false);
        try {
            setUsers(await UserService.getAll(0, 1000));
            setUsersFetchFailed(false);
        } catch (error) {
            console.log(error);
            setUsersFetchFailed(true);
        }
    }

    useEffect(() => {
        loadUsers();
    }, [])

    const cellParserName = (rowData: UserInterface) => {
        return (
            <>
                {`${rowData.familyName}, ${rowData.firstName}`}
            </>
        );
    }

    const cellParserRole = (rowData: UserInterface) => {
        return (
            <>
                {t('globals.roles.' + rowData.role)}
            </>
        );
    }

    const cellParserActions = (rowData: UserInterface) => {
        const buttons = [];

        if (UserACLUtil.canUserUpdateUser(props.currentUser, rowData)) {
            buttons.push(
                <ButtonWithLink
                    key={`btn-view-${rowData.id}`}
                    href={RoutesEnum.userDetail.replace(RoutesParametersEnum.userId, `${rowData.id}`)}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-success p-button-text mr-1"
                />
            )
            buttons.push(
                <ButtonWithLink
                    key={`btn-edit-${rowData.id}`}
                    href={RoutesEnum.userEdit.replace(RoutesParametersEnum.userId, `${rowData.id}`)}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text mr-1"
                />
            )
        }

        if (UserACLUtil.canUserDeleteUser(props.currentUser, rowData)) {
            buttons.push(
                <ButtonWithLink
                    key={`btn-delete-${rowData.id}`}
                    data-test-id={"btn-delete-user"}
                    href={RoutesEnum.userDelete.replace(RoutesParametersEnum.userId, `${rowData.id}`)}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning p-button-text mr-1"
                />
            )
        }

        if (UserACLUtil.canUserSeeUserTokenUsage(props.currentUser, rowData)) {
            buttons.push(
                <ButtonWithLink
                    key={`btn-stats-${rowData.id}`}
                    data-test-id={"btn-stats-user"}
                    href={RoutesEnum.userTokenUsage.replace(RoutesParametersEnum.userId, `${rowData.id}`)}
                    icon="pi pi-chart-bar"
                    className="p-button-rounded p-button-text mr-1"
                />
            )
        }

        return (
            <>
                {buttons}
            </>
        );
    }

    const actionMenu = (key: string = '') => {
        const buttons = [];

        if (UserACLUtil.canUserCreateUser(props.currentUser, { role: UserRolesEnum.UNKNOWN })) {
            buttons.push(
                <ButtonWithLink
                    key={key + "-btn-add"}
                    className='mr-2'
                    label={t('user_management.createUser')}
                    icon={'pi pi-fw pi-plus'}
                    href={RoutesEnum.userCreate}
                />
            )
        }

        return <Menubar
            className={'mt-2 mb-2'}
            end={<>
                {buttons}
            </>}
        />
    }

    return <div className='UserListScreen d-list-screen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'userScope'}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />

        <Panel header={t("user_management.list_screen.header")} className={"p-4"}>

            {actionMenu('top')}
            <div className={'table-container'}>
                <DataTable value={users}
                    rowHover={true}
                    scrollable scrollHeight={'flex'}
                    sortField="lastName" sortOrder={1}
                    paginator rows={50} rowsPerPageOptions={[50, 100, 500]}
                >
                    <Column field="id" header="ID" style={{ width: '5em' }} sortable={true} />
                    <Column header={t('user_management.name')} body={cellParserName} sortable={true}
                        sortField={'lastName'} />
                    <Column field="role" header={t('user_management.role')} body={cellParserRole} sortable={true} />
                    <Column
                        header={t('user_management.actions')}
                        body={cellParserActions}
                        style={{ width: '15rem' }}
                    />
                </DataTable>
            </div>
            {actionMenu('bottom')}

        </Panel>
    </div>
}

export default UserListScreen;
