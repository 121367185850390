import * as React from "react";
import {useMemo} from "react";
import "./ProgressSpinnerOverlay.scss"
import {ProgressSpinner} from "primereact/progressspinner";

export interface ProgressSpinnerOverlayProps {
    visible: boolean;
}

const ProgressSpinnerOverlay: React.FunctionComponent<ProgressSpinnerOverlayProps> = (props: ProgressSpinnerOverlayProps) => {

    const loaderView = useMemo(() => <div
        className='ProgressSpinnerOverlayProps'>
        <ProgressSpinner/>
    </div>, []);

    return <>
        {props.visible ? loaderView : null}
    </>
}

export default ProgressSpinnerOverlay;