import { InstructionACLUtil, InstructionInterface } from '@dashart/dashart-gpt-shared-library';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { PaginatedServerResponse } from '../../../../../app/interfaces/PaginatedServerResponse';
import RecoilStates from '../../../../../app/models/RecoilStates';
import { InstructionsService } from '../../../../../app/services/InstructionsService';
import ConfirmDialog from '../../../confirmDialog/ConfirmDialog';
import ProgressSpinnerOverlay from '../../../progressSpinnerOverlay/ProgressSpinnerOverlay';
import './InstructionsSelectorDialogSavedEntries.scss';

export interface InstructionsSelectorDialogSavedEntriesProps {
    mode: 'by-user' | 'by-company';

    onSelect: (instruction: string) => void;
    onDetailViewRequested?: (instruction: InstructionInterface, mode: 'edit' | 'view') => void;
}

const ROWS_PER_PAGE = 20;
const MAX_INSTRUCTIONS_LENGTH = 250;

export const InstructionsInstructionsSelectorDialogSavedEntries: React.FunctionComponent<InstructionsSelectorDialogSavedEntriesProps> = (props: InstructionsSelectorDialogSavedEntriesProps) => {
    const { t } = useTranslation();

    const [serverActionPending, setServerActionPending] = useState(false);
    const [currentUser, setCurrentUser] = useRecoilState(RecoilStates.currentUserState);
    const [paginatedResult, setPaginatedResult] = useState<PaginatedServerResponse<InstructionInterface>>(null)
    const lastUpdatedInstructionIdAndTs = useRecoilState(RecoilStates.lastUpdatedInstructionIdAndTsState)[0];

    const [confirmDeletionOfEntry, setConfirmDeletionOfEntry] = useState<InstructionInterface>(null);
    const setLastToast = useSetRecoilState(RecoilStates.lastToastMessageState);

    const loadInstructions = async (page: number) => {
        const service = InstructionsService.getInstance();
        let result: PaginatedServerResponse<InstructionInterface>;
        switch (props.mode) {
            case 'by-user':
                result = await service.getInstructionsOfUserSavedListPaginated(page, ROWS_PER_PAGE);
                break;
            case 'by-company':
                result = await service.getInstructionsOfCompanySavedListPaginated(page, ROWS_PER_PAGE);
                break;
        }

        setPaginatedResult(result);
    }

    useEffect(() => {
        loadInstructions(1);
    }, []);

    /**
     * Reload list on entry update
     */
    useEffect(() => {
        if (lastUpdatedInstructionIdAndTs && paginatedResult?.meta) {
            loadInstructions(paginatedResult.meta.current_page);
        }
    }, [lastUpdatedInstructionIdAndTs]);

    /**
     * Delete entry
     * @param id 
     */
    const deleteEntry = async (id: number) => {
        setServerActionPending(true);
        try {
            const entry = await InstructionsService.getInstance().deleteInstruction(id);
            setLastToast({
                severity: "success",
                summary: t('globals.createSuccessNotice'),
            });
            // reload list
            loadInstructions(paginatedResult.meta.current_page);
        } catch (error) {
            setLastToast({
                severity: "error",
                summary: t('globals.createFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    }

    /**
     * Cell parser with mouse handler
     * @param rowData 
     * @returns 
     */
    const cellParser = (rowData: InstructionInterface) => {
        // notice css will restrict to max 3 lines
        const shortInstruction = rowData.instruction.length > MAX_INSTRUCTIONS_LENGTH ? rowData.instruction.substring(0, MAX_INSTRUCTIONS_LENGTH) + '...' : rowData.instruction;
        return (
            <div className="InstructionsInstructionsSelectorDialogSavedEntriesCell cursor-pointer" onClick={() => props.onSelect(rowData.instruction)}>
                {rowData.shortDescription}
                <div className='entry-instruction-detail'>
                    <small>{shortInstruction}</small>
                </div>
            </div>
        );
    }

    const cellParserActions = (rowData: InstructionInterface) => {
        const buttons = [];

        if(InstructionACLUtil.canUserListInstruction(currentUser, rowData as any)) {
            buttons.push(
                <Button
                    key={`btn-view-${rowData.id}`}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-success p-button-text"
                    onClick={() => {
                        if (props.onDetailViewRequested) {
                            props.onDetailViewRequested(rowData, 'view');
                        }
                    }}
                />
            )
        }

        if (InstructionACLUtil.canUserUpdateInstruction(currentUser, rowData, rowData)) {
            buttons.push(
                <Button
                    key={`btn-edit-${rowData.id}`}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                        if (props.onDetailViewRequested) {
                            props.onDetailViewRequested(rowData, 'edit');
                        }
                    }}
                />
            )
        }

        if (InstructionACLUtil.canUserDeleteInstruction(currentUser, rowData)) {
            buttons.push(
                <Button
                    key={`btn-delete-${rowData.id}`}
                    data-test-id={"btn-delete-user"}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning p-button-text"
                    onClick={() => {
                        setConfirmDeletionOfEntry(rowData);
                    }}
                />
            )
        }

        return (
            <>
                {buttons}
            </>
        );
    }

    // TODO: delete entry
    // TODO: search

    const onDeleteConfirmYesHandler = () => {
        deleteEntry(confirmDeletionOfEntry.id);
        setConfirmDeletionOfEntry(null);
    }

    const onDeleteConfirmNoHandler = () => {
        setConfirmDeletionOfEntry(null);
    }

    /**
     * Render delete confirmation dialog if needed
     */
    const deleteConfirmation = React.useMemo(() => {
        if (!confirmDeletionOfEntry) {
            return null;
        }

        return <ConfirmDialog
            headline={t("components.instruction_panel.instructionsEntryDeleteConfirmation.headline")}
            body={t(
                "components.instruction_panel.instructionsEntryDeleteConfirmation.body",
                confirmDeletionOfEntry
            )}
            confirmLabel={t('globals.actions.yes')}
            cancelLabel={t('globals.actions.no')}
            onConfirm={onDeleteConfirmYesHandler}
            onCancel={onDeleteConfirmNoHandler}
            onHide={onDeleteConfirmNoHandler}
        />;
    }, [confirmDeletionOfEntry]);

    return (
        <div className="InstructionsSelectorDialogSavedEntries h-full">

            {deleteConfirmation}

            <ProgressSpinnerOverlay visible={serverActionPending} />

            <div className='contentContainer'>
                <div className='tableContainer'>
                    <DataTable
                        value={paginatedResult?.data ?? []}
                        rowHover={true}
                        scrollable scrollHeight='flex'
                        sortField="name" sortOrder={1}
                        emptyMessage={t('components.instruction_panel.instructionsSelectorDialog.savedEntries.emptyMessage')}
                        showHeaders={false}
                    >
                        <Column
                            body={cellParser}
                        />
                        <Column
                            body={cellParserActions}
                            style={{ width: '10rem' }}
                        />
                    </DataTable>
                </div>

                <Paginator
                    first={paginatedResult?.meta?.current_page}
                    rows={ROWS_PER_PAGE}
                    totalRecords={paginatedResult?.meta?.total}
                    onPageChange={(e) => {
                        loadInstructions(e.page + 1);
                    }}
                />
            </div>
        </div>
    );
}

export default InstructionsInstructionsSelectorDialogSavedEntries;
