// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginScreen .loginMenubar{border:none;padding:.5rem 1.5rem .5rem 0 !important}.LoginBox>*{height:fit-content}`, "",{"version":3,"sources":["webpack://./src/view/screens/loginScreen/LoginScreen.scss"],"names":[],"mappings":"AAGE,2BACE,WAAA,CACA,uCAAA,CAKF,YACE,kBAAA","sourcesContent":["@import \"src/styles/helpers\";\n\n.LoginScreen {\n  .loginMenubar {\n    border: none;\n    padding: .5rem 1.5rem .5rem 0 !important;\n  }\n}\n\n.LoginBox {\n  & > * {\n    height: fit-content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoginScreen": `LoginScreen`,
	"loginMenubar": `loginMenubar`,
	"LoginBox": `LoginBox`
};
export default ___CSS_LOADER_EXPORT___;
