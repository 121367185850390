// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InstructionsEntryEditDialog .p-tabview{display:flex;flex-direction:column}.InstructionsEntryEditDialog .p-tabview-panels{flex:1 1 auto}.InstructionsEntryEditDialog .p-tabview-panel{flex:1 1 auto;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/view/components/instructionPanel/instructionsEntryEditDialog/InstructionsEntryEditDialog.scss"],"names":[],"mappings":"AACI,wCACI,YAAA,CACA,qBAAA,CAEJ,+CACI,aAAA,CAEJ,8CACI,aAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".InstructionsEntryEditDialog {\n    .p-tabview {\n        display: flex;\n        flex-direction: column;\n    }\n    .p-tabview-panels {\n        flex: 1 1 auto;\n    }\n    .p-tabview-panel {\n        flex: 1 1 auto;\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InstructionsEntryEditDialog": `InstructionsEntryEditDialog`,
	"p-tabview": `p-tabview`,
	"p-tabview-panels": `p-tabview-panels`,
	"p-tabview-panel": `p-tabview-panel`
};
export default ___CSS_LOADER_EXPORT___;
