// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressSpinnerOverlayProps{position:absolute;left:0;right:0;top:0;bottom:0;background:rgba(255,255,255,.5);z-index:100;display:flex;justify-content:center;align-items:center}`, "",{"version":3,"sources":["webpack://./src/view/components/progressSpinnerOverlay/ProgressSpinnerOverlay.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CAEA,+BAAA,CAEA,WAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".ProgressSpinnerOverlayProps {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n\n  background: rgba(255,255,255, .5);\n\n  z-index: 100;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProgressSpinnerOverlayProps": `ProgressSpinnerOverlayProps`
};
export default ___CSS_LOADER_EXPORT___;
