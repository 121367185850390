// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserDetailScreen{position:relative;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/view/screens/userDetailScreen/UserDetailScreen.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CAEA,UAAA,CACA,WAAA","sourcesContent":[".UserDetailScreen {\n  position: relative;\n\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserDetailScreen": `UserDetailScreen`
};
export default ___CSS_LOADER_EXPORT___;
