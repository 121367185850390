import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import RecoilStates from "@app/models/RecoilStates";
import { RoutesEnum } from "@app/models/RoutesEnum";
import { AuthService } from "@app/services/AuthService";
import MainMenu from "@view/components/mainMenu/MainMenu";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import ToastComponent from "@view/components/toastComponent/ToastComponent";
import DashBoardScreen from "@view/screens/dashBoardScreen/DashBoardScreen";
import LoginScreen from "@view/screens/loginScreen/LoginScreen";
import UserDetailScreen, { UserDetailScreenMode } from "@view/screens/userDetailScreen/UserDetailScreen";
import UserListScreen from "@view/screens/userListScreen/UserListScreen";
import UserTokenUsageScreen from "@view/screens/userTokenUsageScreen/UserTokenUsageScreen";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath } from "react-router";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { OpenAiService } from "../app/services/OpenAiService";
import { ClaudeService } from "../app/services/ClaudeService";
import { MistralService } from "../app/services/MistralService";
import "./EnhancedChatGptApp.scss";
import ChatScreenHoc from "./screens/chatScreen/ChatScreenHoc";
import CompanyDetailScreen, { CompanyDetailScreenMode } from "./screens/companyDetailScreen/CompanyDetailScreen";
import CompanyListScreen from "./screens/companyListScreen/CompanyListScreen";
import CompanyUsersListScreen from "./screens/companyUsersListScreen/CompanyUsersListScreen";

const AUTH_SESSION_CHECK_INTERVAL = 1000 * 60 * .5; // 30 seconds
const LOCAL_STORAGE_KEY__AFTER_LOGIN_REDIRECT = "afterLoginRedirect";

export interface EnhancedChatGptAppProps {
}

const EnhancedChatGptApp: React.FunctionComponent<EnhancedChatGptAppProps> = (props: EnhancedChatGptAppProps) => {
    const location = useLocation();
    const [initialDelayActive, setInitialDelayActive] = useState(true);
    const [lastToastMessage, setLastToastMessage] = useRecoilState(RecoilStates.lastToastMessageState);
    const { t } = useTranslation();

    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const [lastLoginTryFailed, setLastLoginTryFailed] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useRecoilState(RecoilStates.currentUserState)

    const [availableChatServices, setAvailableChatServices] = useState<{
        chatGpt: boolean,
        claude: boolean,
        mistral: boolean,
    }>({
        chatGpt: false,
        claude: false,
        mistral: false,
    });

    /**
     * Try to validate session on app start
     */
    useEffect(() => {
        AuthService.checkIfSessionValidAndGetNewRefreshToken().then((response) => {
            setCurrentUser(response);
        }).catch((error) => {
            setCurrentUser(null);
        });
    }, []);

    /**
     * Check for available chat services on user change
     */
    useEffect(() => {
        if (currentUser) {
            checkForAvailableChatServices();
        }
    }, [currentUser]);

    /**
     * Check for available chat services
     */
    const checkForAvailableChatServices = () => {
        OpenAiService.getInstance().getServiceAvailableForUser().then((response) => {
            setAvailableChatServices((prevState) => {
                return {
                    ...prevState,
                    chatGpt: response
                }
            });
        });
        ClaudeService.getInstance().getServiceAvailableForUser().then((response) => {
            setAvailableChatServices((prevState) => {
                return {
                    ...prevState,
                    claude: response
                }
            });
        });
        MistralService.getInstance().getServiceAvailableForUser().then((response) => {
            setAvailableChatServices((prevState) => {
                return {
                    ...prevState,
                    mistral: response
                }
            });
        });
    }

    // TODO check if session is valid and if not redirect to login screen

    /**
     * Try to login user
     * @param userName
     * @param password
     */
    const login = (userName: string, password: string): void => {
        setLoginLoading(true);
        setLastLoginTryFailed(false);

        AuthService.login(userName, password).then((response) => {
            setCurrentUser(response);
        }).catch(
            (error) => {
                showToast({
                    severity: "error",
                    summary: t("loginScreen.loginFailed"),
                    detail: error.message
                });
                setLastLoginTryFailed(true);
            }
        ).then(() => {
            setLoginLoading(false);
        });
    }

    /**
     * Try to logout user
     */
    const logout = (): void => {
        AuthService.logout().then((response) => {

        }).catch(
            (error) => {
                showToast({
                    severity: "error",
                    summary: t("loginScreen.logoutFailed"),
                    detail: error.message
                });
                setLastLoginTryFailed(true);
            }).then(() => {
                setCurrentUser(null);
            });
    }

    /**
     * Stores last location to local storage
     * @param path
     */
    const setRedirectPathOnAuthentication = (path: string) => {
        localStorage.setItem(LOCAL_STORAGE_KEY__AFTER_LOGIN_REDIRECT, path);
    }

    /**
     * Loads last location from local storage
     * @param andFlush - if true last location will be deleted
     */
    const getRedirectPathOnAuthentication = (andFlush: boolean = false): string => {
        return localStorage.getItem(LOCAL_STORAGE_KEY__AFTER_LOGIN_REDIRECT);
    }

    const showToast = (toast: ToastMessageInterface): void => {
        setLastToastMessage(toast);
    }

    // delay initial view by 1000s to get store ready and load auth data
    useEffect(() => {
        const timer = setTimeout(() => {
            setInitialDelayActive(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    // Custom function to determine if a route is active
    const isActiveRoute = (path: string): boolean => {
        const match = matchPath(
            {
                path,
                caseSensitive: false, // Optional, `true` == static parts of `path` should match case
                end: true, // Optional, `true` == match to end of location.pathname
            },
            location.pathname
        );
        return match !== null;
    };

    return <div className='EnhancedChatGptApp'>

        {!currentUser ?
            <LoginScreen
                loading={loginLoading}
                loginFailed={lastLoginTryFailed}
                overlay={true}
                login={login}
            />
            :
            null
        }

        <MainMenu
            user={currentUser}
            claudeAvailable={availableChatServices.claude}
            openAiAvailable={availableChatServices.chatGpt}
            mistralAvailable={availableChatServices.mistral}
            tryLogout={logout}
        />

        {initialDelayActive ?
            <ProgressSpinnerOverlay visible={true} /> :
            <>
                <div className={'screen-holder-outer'}>
                    <div className={'screen-holder'}>
                        <ToastComponent lastToast={lastToastMessage} />

                        <Routes>

                            {/*reset password screen*/}
                            <Route path={RoutesEnum.resetPassword}>
                                {/*<ResetPasswordScreenContainer/>*/}
                            </Route>


                            <Route path="/" element={
                                currentUser ? <Navigate to={RoutesEnum.chatWithChatGpt} /> : null
                            } />

                            <Route path={RoutesEnum.dashboard} element={
                                currentUser ? <DashBoardScreen /> : null
                            } />

                            {/*chat screens*/}

                            <Route path={RoutesEnum.chatWithChatGpt} element={
                                currentUser ? <ChatScreenHoc
                                    chatService={OpenAiService.getInstance()}
                                /> : null
                            } />

                            <Route path={RoutesEnum.chatWithClaude} element={
                                currentUser ? <ChatScreenHoc
                                    chatService={ClaudeService.getInstance()}
                                /> : null
                            } />

                            <Route path={RoutesEnum.chatWithMistral} element={
                                currentUser ? <ChatScreenHoc
                                    chatService={MistralService.getInstance()}
                                /> : null
                            } />

                            {/*user administration screens*/}

                            <Route path={RoutesEnum.userList} element={
                                currentUser ? <UserListScreen
                                    currentUser={currentUser}
                                /> : null
                            } />
                            <Route path={RoutesEnum.userDetail} element={
                                currentUser ? <UserDetailScreen
                                    currentUser={currentUser}
                                    mode={UserDetailScreenMode.VIEW}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.userEdit} element={
                                currentUser ? <UserDetailScreen
                                    currentUser={currentUser}
                                    mode={UserDetailScreenMode.UPDATE}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.userCreate} element={
                                currentUser ? <UserDetailScreen
                                    currentUser={currentUser}
                                    mode={UserDetailScreenMode.CREATE}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.userDelete} element={
                                currentUser ? <UserDetailScreen
                                    currentUser={currentUser}
                                    mode={UserDetailScreenMode.DELETE}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.userTokenUsage} element={
                                currentUser ? <UserTokenUsageScreen
                                    currentUser={currentUser}
                                    showToast={showToast}
                                /> : null
                            } />

                            {/* company administration screens */}
                            <Route path={RoutesEnum.companyList} element={
                                currentUser ? <CompanyListScreen
                                    currentUser={currentUser}
                                /> : null
                            } />
                            <Route path={RoutesEnum.companyDetail} element={
                                currentUser ? <CompanyDetailScreen
                                    currentUser={currentUser}
                                    mode={CompanyDetailScreenMode.VIEW}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.companyEdit} element={
                                currentUser ? <CompanyDetailScreen
                                    currentUser={currentUser}
                                    mode={CompanyDetailScreenMode.UPDATE}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.companyCreate} element={
                                currentUser ? <CompanyDetailScreen
                                    currentUser={currentUser}
                                    mode={CompanyDetailScreenMode.CREATE}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.companyDelete} element={
                                currentUser ? <CompanyDetailScreen
                                    currentUser={currentUser}
                                    mode={CompanyDetailScreenMode.DELETE}
                                    showToast={showToast}
                                /> : null
                            } />
                            <Route path={RoutesEnum.companyListUsers} element={
                                currentUser ? <CompanyUsersListScreen
                                    currentUser={currentUser}
                                    showToast={showToast}
                                /> : null
                            } />


                            {/*fall back to default screens*/}

                            <Route path={RoutesEnum.dashboard} element={
                                currentUser ? <DashBoardScreen /> : null
                            } />

                            <Route path={RoutesEnum.catchAll} element={
                                <Navigate to={RoutesEnum.chatWithChatGpt} />
                            } />
                        </Routes>
                    </div>
                </div>

            </>
        }
    </div>
}

export default EnhancedChatGptApp;