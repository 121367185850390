import * as React from 'react';
import './styles/styles.scss'

import * as i18n from './i18n';
import { HashRouter } from "react-router-dom";
import { AxiosUtil } from "@app/utils/AxiosUtil";
import { HtmlAttributesUtil } from "@dashart/d_utilz_typescript";
import { createRoot } from "react-dom/client";
import EnhancedChatGptApp from "@view/EnhancedChatGptApp";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";

const domElement: HTMLElement = document.getElementById('EnhancedChatGpt') as HTMLElement;
const locale = HtmlAttributesUtil.getAttributeAsText(domElement, 'data-locale', 'de');
const apiURI = HtmlAttributesUtil.getAttributeAsText(domElement, 'data-api-uri', 'http://localhost:3000/');
const sentryDsn = HtmlAttributesUtil.getAttributeAsText(domElement, 'data-sentry-dsn', '');

if (sentryDsn.trim() !== '') {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [],
    });
}

i18n.default(locale);
AxiosUtil.configureAxios(apiURI, locale);

const app = <RecoilRoot>
    <HashRouter>
        <EnhancedChatGptApp />
    </HashRouter>
</RecoilRoot>;

const root = createRoot(domElement); // createRoot(container!) if you use TypeScript
root.render(app);