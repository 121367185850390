// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToastComponent{position:relative;word-break:break-word}.ToastComponent .p-toast-top-right{top:75px}`, "",{"version":3,"sources":["webpack://./src/view/components/toastComponent/ToastComponent.scss"],"names":[],"mappings":"AAEA,gBACE,iBAAA,CAEA,qBAAA,CAEA,mCACE,QAAA","sourcesContent":["@import \"../../../styles/helpers\";\n\n.ToastComponent {\n  position: relative;\n\n  word-break: break-word;\n\n  .p-toast-top-right {\n    top: 75px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToastComponent": `ToastComponent`,
	"p-toast-top-right": `p-toast-top-right`
};
export default ___CSS_LOADER_EXPORT___;
