import { ChatModelInterface } from "@app/interfaces/ChatGptModelInterface";
import { ChatMessageInterface } from "@app/interfaces/ChatMessageInterface";
import ButtonWithIcon from "@view/components/buttonWithIcon/ButtonWithIcon";
import { CancelIcon } from "@view/components/icons/CancelIcon";
import { ContinueIcon } from "@view/components/icons/ContinueIcon";
import { FreshIcon } from "@view/components/icons/FreshIcon";
import { SendIcon } from "@view/components/icons/SendIcon";
import { InputTextarea } from "primereact/inputtextarea";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../app/models/RecoilStates";
import ChatHistory from "../../components/chatHistory/ChatHistory";
import ChatMessagesList from "../../components/chatMessagesList/ChatMessagesList";
import ChatOptions from "../../components/chatOptions/ChatOptions";
import { InstructionPanel } from "../../components/instructionPanel/InstructionPanel";
import "./ChatScreen.scss";

export interface ChatScreenHocCompletionStateInterface {
    running: boolean;
    currentQuestion: string;
    currentCompletion: string;
    error: string;
    continueOutputAvailable: boolean;
    conversationId: string;
}

export interface ChatScreenProps {
    instruction: string;
    setInstruction: (instruction: string) => void;

    prompt: string;
    setPrompt: (prompt: string) => void;

    messages: ChatMessageInterface[];
    lastUsedConversationId: string;
    availableModels: ChatModelInterface[];

    maxTokens: number;
    setMaxTokens: (maxTokens: number) => void;

    temperature: number;
    setTemperature: (temperature: number) => void;

    gptModel: ChatModelInterface;
    setGptModel: (model: ChatModelInterface) => void;

    completionState: ChatScreenHocCompletionStateInterface;

    requestNewConversation: () => void;
    requestForkOfChatStartingAtGivenMessage: (messageIndex: number) => void;
    requestContinueOfOutput: () => void;
    requestSubmitOfCurrentPrompt: (command?: string) => void;
    requestCancelOfCurrentRequest: () => void;
    requestLoadConversationFromHistory: (conversationId: string, model: string) => void;
}

const ChatScreen: React.FunctionComponent<ChatScreenProps> = (props: ChatScreenProps) => {
    const { t } = useTranslation();

    const [sidebarOpen, setSidebarOpen] = useRecoilState(RecoilStates.chatHistoryOpenState);
    const [textAreaLines, setTextAreaLines] = useState(4);

    /**
     * Actions menu
     */
    const dialogMenu = useMemo(() => {
        const iconSize = 24;

        return <div className={'absolute right-0 bottom-0 m-2 mr-3 flex flex-column'}>
            {(props.messages.length > 0 || props.completionState.running) ?
                <ButtonWithIcon
                    icon={<FreshIcon width={iconSize} height={iconSize} />}
                    additionalClassNames={'mb-2'}
                    tooltip={t('chat_screen.menu.start_new_chat')}
                    tooltipPosition={'left'}
                    onClick={props.requestNewConversation}
                />
                : null}

            {props.completionState.continueOutputAvailable ?
                <ButtonWithIcon
                    icon={<ContinueIcon width={iconSize} height={iconSize} />}
                    tooltip={t('chat_screen.menu.continue_chat')}
                    tooltipPosition={'left'}
                    onClick={props.requestContinueOfOutput}
                />
                : null}
        </div>
    }, [props.completionState, props.messages]);

    const renderInput = () => {
        const actionButtonsSize = 24;

        return <div className="lower-section flex-grow-0 p-grid p-2 m-0 border-top-1">
            <div className="grid" onKeyDownCapture={(event) => {
                if (event.key === 'Enter' && event.metaKey) {
                    props.requestSubmitOfCurrentPrompt()
                }
            }}>
                <div className="col-12 p-1 pb-0 mb-1 relative">
                    <InputTextarea
                        className={'col-12 p-2 m-0'}
                        placeholder={t('chat_screen.your_prompt')}
                        rows={textAreaLines}
                        value={props.prompt}
                        onChange={(e) => props.setPrompt(e.currentTarget.value)}
                    />

                    <div className={'inputActionButtons absolute right-0 bottom-0 m-2'}>
                        {!props.completionState.running ?
                            <ButtonWithIcon
                                icon={<SendIcon
                                    width={actionButtonsSize}
                                    height={actionButtonsSize}
                                />}
                                onClick={props.requestSubmitOfCurrentPrompt}
                            /> :
                            <ButtonWithIcon
                                icon={<CancelIcon
                                    width={actionButtonsSize}
                                    height={actionButtonsSize}
                                />}
                                onClick={props.requestCancelOfCurrentRequest}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    return <div className='ChatScreen flex flex-row h-full w-full'>
        <div className={`h-full ${sidebarOpen ? 'historyOpen' : 'historyClosed'}`}>
            <ChatHistory
                expanded={sidebarOpen}
                toggleExpanded={() => setSidebarOpen(!sidebarOpen)}
                onEntrySelected={props.requestLoadConversationFromHistory}
            />
        </div>

        <div className={'h-full flex-grow-1 flex flex-column overflow-hidden'}>
            <InstructionPanel
                instruction={props.instruction}
                onInstructionChange={props.setInstruction}
            />
            <ChatMessagesList
                className="upperDiv table-container flex-grow-1"
                onForkRequestHandler={props.requestForkOfChatStartingAtGivenMessage}
                messages={props.messages}
                completionIsRunning={props.completionState.running}
                currentQuestion={props.completionState.currentQuestion}
                currentCompletion={props.completionState.currentCompletion}
                labelIfNoMessages={t('chat_screen.no_messages')}
            />

            <div className={'actionMenu'}>
                {dialogMenu}
            </div>

            <div className="border-top-1">
                <ChatOptions
                    completionError={props.completionState.error}
                    completionIsRunning={props.completionState.running}
                    availableModels={props.availableModels}
                    gptModel={props.gptModel}
                    maxTokens={props.maxTokens}
                    temperature={props.temperature}
                    inputTextLines={textAreaLines}
                    setGptModel={props.setGptModel}
                    setMaxTokens={props.setMaxTokens}
                    setTemperature={props.setTemperature}
                    setInputTextLines={setTextAreaLines}
                />

                {renderInput()}
            </div>
        </div>
    </div>
}

export default ChatScreen;