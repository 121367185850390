import * as React from "react";
import { CSSProperties } from "react";

export const ThreeDotsIcon: React.FC<{ className?: any, color?: string, width: number, height: number }> = (props) => {

    const style: CSSProperties = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stroke: props.color ? props.color : '#000000',
        strokeWidth: '4px',
    }

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                style={style}
                d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
            />
        </g>
    </svg>
}