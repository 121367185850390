import { ChatHistoryEntryInterface } from "@dashart/dashart-gpt-shared-library";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../app/models/RecoilStates";
import { ChatHistoryService } from "../../../app/services/ChatHistoryService";
import { ChatGptIcon } from "../icons/ChatGptIcon";
import { ClaudeIcon } from "../icons/ClaudeIcon";
import { ContractIcon } from "../icons/ContractIcon";
import { MistralIcon } from "../icons/MistralIcon";
import { ExpandIcon } from "../icons/ExpandIcon";
import styles from "./ChatHistory.module.scss";
import { ChatModelInterface } from "../../../app/interfaces/ChatModelInterface";

export interface ChatHistoryProps {
    expanded?: boolean;

    toggleExpanded?: () => void;
    onEntrySelected?: (conversationId: string, model: string) => void;
}

const LIMIT = 25;
const SEARCH_MINIMUM_LENGTH = 3;
const SEARCH_DEBOUNCE_IN_MS = 200;

const ChatHistory: React.FunctionComponent<ChatHistoryProps> = (props: ChatHistoryProps) => {
    const { t } = useTranslation();
    const { expanded } = props;

    // used to rerender list on models list change,
    // otherwise the list entries will call props.onEntrySelected with the wrong model
    const [availableModels, setAvailableModels] = useRecoilState<ChatModelInterface[]>(RecoilStates.availableModels);

    const [historyEntries, setHistoryEntries] = React.useState<{
        currentPageNumber: number,
        moreItemsAvailable: boolean,
        keyToIndexMap: { [key: string]: number },
        entries: ChatHistoryEntryInterface[]
    }>({
        currentPageNumber: 1,
        moreItemsAvailable: true,
        keyToIndexMap: {},
        entries: []
    });
    const [searchHistoryEntries, setSearchHistoryEntries] = React.useState<{
        currentPageNumber: number,
        moreItemsAvailable: boolean,
        keyToIndexMap: { [key: string]: number },
        entries: ChatHistoryEntryInterface[]
    }>({
        currentPageNumber: 1,
        moreItemsAvailable: true,
        keyToIndexMap: {},
        entries: []
    });
    const [lastUsedConversationId] = useRecoilState(RecoilStates.lastUsedConversationId);
    const [currentSearchValue, setCurrentSearchValue] = React.useState<string>("");
    const [mode, setMode] = React.useState<"history" | "search">("history");

    /**
     * Loads the history on first load nor on conversation id change
     */
    React.useEffect(() => {
        loadHistoryEntries(1, LIMIT, false);
    }, [lastUsedConversationId]);


    /**
     * Loads the search result when the search value changes, but debounced by 200ms
     */
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (currentSearchValue?.length >= SEARCH_MINIMUM_LENGTH) {
                loadSearchResultEntries(currentSearchValue, 1, LIMIT, true);
            }
        }, SEARCH_DEBOUNCE_IN_MS);

        return () => {
            clearTimeout(timeout);
        }
    }, [currentSearchValue]);

    /**
     * tracks if more items are available
     */
    const moreItemsAvailable = React.useMemo(() => {
        return mode === 'history' ? historyEntries.moreItemsAvailable : searchHistoryEntries.moreItemsAvailable;
    }, [mode, historyEntries, searchHistoryEntries]);

    /**
     * Toggles the expanded state
     */
    const toggleExpanded = () => {
        props.toggleExpanded?.();
    }

    /**
     * Called when the search field changes
     * @param e 
     */
    const searchFieldOnChange = (e) => {
        const searchValue = (e.target.value ?? '').trim();
        const searchActive = searchValue.length >= SEARCH_MINIMUM_LENGTH;

        setCurrentSearchValue(searchActive ? searchValue : null);
        setMode(searchActive ? "search" : "history");
    }

    /**
     * Called when the load more button is clicked
     */
    const onLoadMoreClickHandler = () => {
        if (mode === 'history') {
            loadHistoryEntries(historyEntries.currentPageNumber + 1, LIMIT, false);
        } else {
            loadSearchResultEntries(currentSearchValue, searchHistoryEntries.currentPageNumber + 1, LIMIT, false);
        }
    }

    /**
     * Adds new entries to the list, but ignores entries that are already in the list.
     * If resetEntries is true, the list is cleared before adding the new entries.
     * @param newEntries 
     * @param pageNumber 
     * @param moreItemsAvailable 
     * @param resetEntries
     */
    const addNewEntriesToList = (newEntries: ChatHistoryEntryInterface[], pageNumber, moreItemsAvailable, resetEntries = false) => {
        const source = mode === 'history' ? historyEntries : searchHistoryEntries;

        if (resetEntries) {
            source.entries = [];
            source.keyToIndexMap = {};
        }

        const updatedEntries = [
            ...source.entries,
            // only add entries that are not already in the list
            ...newEntries.filter((entry) => {
                return !source.keyToIndexMap[entry.conversationId];
            })
        ].filter((entry, index, self) => {
            // remove duplicates
            return self.findIndex((e) => e.conversationId === entry.conversationId) === index;
        });

        // sort the entries by updatedAt
        updatedEntries.sort((a, b) => {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        });

        // refresh the key to index map
        const keyToIndexMap = {};
        updatedEntries.forEach((entry, index) => {
            keyToIndexMap[entry.conversationId] = index;
        });


        if (mode === 'history') {
            setHistoryEntries({
                currentPageNumber: pageNumber,
                moreItemsAvailable: moreItemsAvailable,
                keyToIndexMap,
                entries: updatedEntries
            });
        } else {
            setSearchHistoryEntries({
                currentPageNumber: pageNumber,
                moreItemsAvailable: moreItemsAvailable,
                keyToIndexMap,
                entries: updatedEntries
            });
        }
    }

    /**
     * Loads the history
     * @param page 
     * @param limit 
     * @param resetList 
     */
    const loadHistoryEntries = async (page: number, limit: number, resetList: boolean) => {
        const historyResult = await ChatHistoryService.getInstance().getConversationHistoryListPaginated(page, limit);
        addNewEntriesToList(historyResult.data, page, historyResult.meta.next_page_url, resetList);
    }

    /**
     * Loads the search history
     * @param searchValue 
     * @param page 
     * @param limit 
     * @param resetList 
     */
    const loadSearchResultEntries = async (searchValue: string, page: number, limit: number, resetList: boolean) => {
        const searchResult = await ChatHistoryService.getInstance().searchConversationHistoryList(searchValue, page, limit);
        addNewEntriesToList(searchResult.data, page, searchResult.meta.next_page_url, resetList);
    }

    /**
     * Renders the load more button
     */
    const renderedLoadMoreButton = React.useMemo(() => {
        return <div
            className={styles.LoadMoreButton}
            onClick={onLoadMoreClickHandler}
        >
            <Button
                label={t('chat_screen.chat_history.load_more')}
                className={"p-button-raised p-button-secondary p-button-rounded w-full"}
            />
        </div>
    }, [mode, historyEntries, searchHistoryEntries]);

    /**
     * Renders the expand toggle buttons
     */
    const renderedExpandToggleButtons = React.useMemo(() => {
        return <div
            className={styles.ExpandToggleButtons}
            onClick={toggleExpanded}
        >
            {expanded ?
                <ContractIcon
                    className={styles.ContractIcon}
                    width={24}
                    height={24}
                /> : <ExpandIcon
                    className={styles.ExpandIcon}
                    width={24}
                    height={24}
                />
            }
        </div>
    }, [expanded]);

    /**
     * Renders a single list entry
     * @param entry 
     * @param index 
     * @returns 
     */
    const renderListEntry = (entry: ChatHistoryEntryInterface, index) => {
        const size = {
            className: styles.icon,
            width: 15,
            height: 15
        };

        let icon;
        if (entry.model.startsWith('claude')) {
            icon = <ClaudeIcon
                {...size}
            />
        } else if (entry.model.startsWith('gpt') || entry.model.startsWith('o')) {
            icon = <ChatGptIcon
                {...size}
            />
        } else if (entry.model.startsWith('mistral')) {
            icon = <MistralIcon
                {...size}
            />
            
        }

        return <div
            className={styles.listEntry}
            key={entry.conversationId}
            onClick={() => {
                props.onEntrySelected(
                    entry.conversationId,
                    entry.model
                )
            }}
        >
            <>
                {icon}
            </>
            <>
                {entry.excerpt}
            </>
        </div>
    }

    /**
     * Renders the list with all entries and
     * day labels before the first entry of each day
     */
    const renderedList = React.useMemo(() => {
        const entries = mode === 'history' ? historyEntries.entries : searchHistoryEntries.entries;
        const renderedListEntries = [];

        let lastDate = null;
        entries?.forEach((entry, index) => {
            const date = new Date(entry.updatedAt);

            if (!lastDate || lastDate.getDate() !== date.getDate()) {
                const dateString = date.toLocaleDateString();
                lastDate = date;

                renderedListEntries.push(<div
                    className={styles.listEntryDayLabel}
                    key={dateString}
                >
                    {dateString}
                </div>);
            }

            renderedListEntries.push(renderListEntry(entry, index));
        });

        // render list entries, but add a day label before the first entry of each day
        return <div className={styles.listSection}>
            {renderedListEntries}
        </div>
    }, [lastUsedConversationId, historyEntries, searchHistoryEntries, mode, availableModels]);

    /**
     * Renders the search field
     */
    const renderedSearchField = React.useMemo(() => {
        return <div
            className={styles.searchField}
        >
            <span className="p-input-icon-right w-full">
                <i className="pi pi-search" />
                <InputText
                    placeholder={t('chat_screen.chat_history.search_prompt')}
                    className="w-full p-1"
                    onChange={searchFieldOnChange}
                />
            </span>
        </div>
    }, []);


    return <div
        className={styles.ChatHistory}
    >
        {renderedExpandToggleButtons}

        {expanded ? <div className={styles.listContainer}>
            {renderedSearchField}

            <div className={styles.listTitle}>
                {mode == 'history' ?
                    t('chat_screen.chat_history.title_history')
                    :
                    t('chat_screen.chat_history.title_search_result')}
            </div>
            {renderedList}

            {moreItemsAvailable ? renderedLoadMoreButton : null}
        </div> : null}
    </div>
}

export default ChatHistory;