import {AxiosInstance, AxiosResponse} from "axios";
import {AxiosUtil} from "@app/utils/AxiosUtil";
import {SentryHelper} from "@app/helpers/SentryHelper";
import {GetTokenUsageOfUserByTimeRangeDto, TokenUsageInterface} from "@dashart/dashart-gpt-shared-library";

export class TokenUsageService {

    private static axiosInstance: AxiosInstance;

    private static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = AxiosUtil.createPreconfiguredAxiosInstance();
        }

        return this.axiosInstance;
    }

    /**
     * Get token usage by time range
     * @param dto
     */
    static async getUserTokenUsageByTimeRange(dto: GetTokenUsageOfUserByTimeRangeDto): Promise<TokenUsageInterface[]> {
        return this.getAxiosInstance().post(
            '/v1/token-usage/',
            dto
        ).then(
            (response: AxiosResponse) => {
                return Promise.resolve(<TokenUsageInterface[]>response.data ?? [])
            },
            (error) => {
                console.log('Get token usage failed', error);
                SentryHelper.apiCatch(error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };
}
