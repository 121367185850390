import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { CompanyService } from "@app/services/CompanyService";
import { CompanyACLUtil, CompanyInterface } from "@dashart/dashart-gpt-shared-library/dist";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menubar } from "primereact/menubar";
import { Panel } from "primereact/panel";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RoleValidatorComponent from "../../components/roleValidatorComponent/RoleValidatorComponent";

const MAX_ENTRIES_PER_PAGE = 50;

export interface CompanyListScreenProps {
    currentUser: UserInterface;
}

const CompanyListScreen: React.FunctionComponent<CompanyListScreenProps> = (props: CompanyListScreenProps) => {
    const { t } = useTranslation();
    const { currentUser } = props;
    const [data, setData] = React.useState<{
        entries: CompanyInterface[],
        currentPage: number,
        hasNextPage: boolean,
    }>({ entries: [], currentPage: 1, hasNextPage: false });
    const [entriesFetchFailed, setEntriesFetchFailed] = React.useState<boolean>(false);

    const loadEntries = async (page: number = 1) => {
        setEntriesFetchFailed(false);
        try {
            const result = await CompanyService.getAll(page, MAX_ENTRIES_PER_PAGE);
            setData({
                entries: result?.data ?? [],
                currentPage: page,
                hasNextPage: result?.meta?.next_page_url ? true : false,
            });
            setEntriesFetchFailed(false);
        } catch (error) {
            console.log(error);
            setEntriesFetchFailed(true);
        }
    }

    useEffect(() => {
        loadEntries(1);
    }, [])

    /**
     * Cell parser for company name
     * @param rowData 
     * @returns 
     */
    const cellParserName = (rowData: CompanyInterface) => {
        return (
            <>
                {`${rowData.name}`}
            </>
        );
    }

    /**
     * Cell parser for company row actions
     * @param rowData 
     * @returns 
     */
    const cellParserActions = (rowData: UserInterface) => {
        const buttons = [];

        if (CompanyACLUtil.canUserCreateCompany(props.currentUser)) {
            buttons.push(
                <ButtonWithLink
                    key={`btn-view-${rowData.id}`}
                    href={RoutesEnum.companyDetail.replace(RoutesParametersEnum.companyId, `${rowData.id}`)}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-success p-button-text mr-1"
                />
            )
            buttons.push(
                <ButtonWithLink
                    key={`btn-edit-${rowData.id}`}
                    href={RoutesEnum.companyEdit.replace(RoutesParametersEnum.companyId, `${rowData.id}`)}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text mr-1"
                />
            )
            buttons.push(
                <ButtonWithLink
                    key={`btn-users-${rowData.id}`}
                    href={RoutesEnum.companyListUsers.replace(RoutesParametersEnum.companyId, `${rowData.id}`)}
                    icon="pi pi-users"
                    className="p-button-rounded p-button-text mr-1"
                />
            )
        }

        if (CompanyACLUtil.canUserDeleteCompany(props.currentUser, rowData)) {
            buttons.push(
                <ButtonWithLink
                    key={`btn-delete-${rowData.id}`}
                    data-test-id={"btn-delete-user"}
                    href={RoutesEnum.companyDelete.replace(RoutesParametersEnum.companyId, `${rowData.id}`)}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning p-button-text mr-1"
                />
            )
        }

        return (
            <>
                {buttons}
            </>
        );
    }

    const actionMenu = (key: string = '') => {
        const buttons = [];

        if (CompanyACLUtil.canUserCreateCompany(props.currentUser)) {
            buttons.push(
                <ButtonWithLink
                    key={key + "-btn-add"}
                    className='mr-2'
                    label={t('company_management.createCompany')}
                    icon={'pi pi-fw pi-plus'}
                    href={RoutesEnum.companyCreate}
                />
            )
        }

        return <Menubar
            className={'mt-2 mb-2'}
            end={<>
                {buttons}
            </>}
        />
    }

    return <div className='CompanyListScreen d-list-screen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'companyScope'}
            companyOfInterest={{ id: -99 } as CompanyInterface}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />


        <Panel header={t("company_management.list_screen.header")} className={"p-4"}>

            {/* TODO: paginator */}

            {actionMenu('top')}
            <div className={'table-container'}>
                <DataTable value={data?.entries ?? []}
                    rowHover={true}
                    scrollable scrollHeight={'flex'}
                    sortField="name" sortOrder={1}
                    paginator rows={MAX_ENTRIES_PER_PAGE}
                >
                    <Column field="id" header="ID" style={{ width: '5em' }} sortable={true} />
                    <Column header={t('company_management.name')} body={cellParserName} sortable={true}
                        sortField={'name'} />
                    <Column
                        header={t('user_management.actions')}
                        body={cellParserActions}
                        style={{ width: '15rem' }}
                    />
                </DataTable>
            </div>
            {actionMenu('bottom')}

        </Panel>
    </div>
}

export default CompanyListScreen;
