// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InstructionsSelectorDialog .p-tabview{display:flex;flex-direction:column}.InstructionsSelectorDialog .p-tabview-panels{flex:1 1 auto;padding:0}.InstructionsSelectorDialog .p-tabview-panel{flex:1 1 auto;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/view/components/instructionPanel/instructionsSelectorDialog/InstructionsSelectorDialog.scss"],"names":[],"mappings":"AACI,uCACI,YAAA,CACA,qBAAA,CAEJ,8CACI,aAAA,CACA,SAAA,CAEJ,6CACI,aAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".InstructionsSelectorDialog {\n    .p-tabview {\n        display: flex;\n        flex-direction: column;\n    }\n    .p-tabview-panels {\n        flex: 1 1 auto;\n        padding: 0;\n    }\n    .p-tabview-panel {\n        flex: 1 1 auto;\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InstructionsSelectorDialog": `InstructionsSelectorDialog`,
	"p-tabview": `p-tabview`,
	"p-tabview-panels": `p-tabview-panels`,
	"p-tabview-panel": `p-tabview-panel`
};
export default ___CSS_LOADER_EXPORT___;
