import * as React from "react";
import {CSSProperties} from "react";

export const OkIcon: React.FC<{ className?: any, color?: string, width: number, height: number }> = (props) => {

    const style: CSSProperties = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stroke: props.color ? props.color : '#000000',
        strokeWidth: '4px',
    }

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                style={style}
                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zm49 281L177 409c-9.4 9.4-24.6 9.4-33.9 0L79 345c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 247c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
        </g>
    </svg>
}