import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AuthService } from "@app/services/AuthService";
import { AuthUtil } from "@app/utils/AuthUtil";

export class AxiosUtil {

    public static baseURL: string = '';
    public static locale: string = 'en';

    static createPreconfiguredAxiosInstance(): AxiosInstance {
        const newInstance = axios.create();
        this.registerGlobalInterceptors(newInstance);

        return newInstance;
    }

    static createNonConfiguredAxiosInstance(): AxiosInstance {
        const newInstance = axios.create();

        newInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            if (config.headers) {
                config.headers['x-custom-lang'] = this.locale;
            }
            return config;
        }, (error) => {
            // Do something with request error
            return Promise.reject(error);
        });

        return newInstance;
    }

    static configureAxios(baseURL: string, locale: string) {
        this.baseURL = baseURL;
        this.locale = locale;

        axios.defaults.baseURL = this.baseURL;
    }

    /**
     * For implementing global interceptors
     * @private
     */
    private static registerGlobalInterceptors(instance: AxiosInstance) {
        // request interceptors
        instance.interceptors.request.use(async (config) => {
            const accessToken = AuthUtil.getAccessToken();

            if (config.headers) {
                if (accessToken) {
                    // Add the access token to the 'Authorization' header
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                config.headers["x-custom-lang"] = this.locale;
                config.headers['x-device'] = AuthUtil.getDeviceId();
            }
            return Promise.resolve(config);
        }, (error) => {
            // Do something with request error
            return Promise.reject(error);
        });

        // error validation on response
        instance.interceptors.response.use(
            (response: AxiosResponse) => {
                return interceptorOnResponseSuccessHandler(response, instance);
            },
            async (error: AxiosError) => {
                return await interceptorOnResponseErrorHandler(error, instance);
            }
        )
    }
}

const interceptorOnResponseSuccessHandler = (response: AxiosResponse, instance: AxiosInstance) => {
    return response;
}

const interceptorOnResponseErrorHandler = async (error: AxiosError, instance: AxiosInstance) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && originalRequest.headers.get('IsRetry') !== 'true') {
        // If the request returns 401 Unauthorized and it hasn't been retried already
        originalRequest.headers.set('IsRetry', 'true');

        // Get the refresh token from localStorage or any other storage mechanism
        const refreshToken = AuthUtil.getRefreshToken();

        if (AuthUtil.getRefreshTokenExpired()) {
            await AuthService.logout();
            return Promise.reject('Refresh token expired');
        }

        if (refreshToken) {
            try {
                try {
                    // Get the new access token from the response
                    const newAccessToken = await AuthService.requestNewAccessToken(refreshToken);
                    // Update the 'Authorization' header with the new access token
                    instance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                } catch (error) {
                    await AuthService.logout();
                    return Promise.reject(error);
                }

                // Retry the original request with the new access token
                return instance(originalRequest);
            } catch (refreshError) {
                // Failed to refresh the access token, perform logout or other error handling
                return Promise.reject(refreshError);
            }
        }
    }

    return Promise.reject(error);
}