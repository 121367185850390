import * as jwt from 'jsonwebtoken';

export class AuthUtil {

    private static readonly LS_DEVICE_ID = 'device_id';
    private static readonly LS_JWT_ACCESS_TOKEN = 'jwt_access_token';
    private static readonly LS_JWT_ACCESS_TOKEN_VALID_UNTIL = 'jwt_access_token_valid_until';
    private static readonly LS_JWT_REFRESH_TOKEN = 'jwt_refresh_token';
    private static readonly LS_JWT_REFRESH_TOKEN_VALID_UNTIL = 'jwt_refresh_token_valid_until';

    /**
     * Get the device id from local storage
     */
    static getDeviceId(): string {
        return localStorage.getItem(AuthUtil.LS_DEVICE_ID) ?? null;
    }

    /**
     * Set the device id in local storage
     * @param deviceId
     */
    static setDeviceId(deviceId: string): void {
        localStorage.setItem(AuthUtil.LS_DEVICE_ID, deviceId);
    }

    /**
     * Get the payload from the token
     */
    static getTokenPayload(accessToken:string): any {
        return jwt.decode(accessToken);
    }

    /**
     * Clear all values from local storage
     */
    static clearTokens(): void {
        localStorage.removeItem(AuthUtil.LS_JWT_ACCESS_TOKEN);
        localStorage.removeItem(AuthUtil.LS_JWT_REFRESH_TOKEN);
        localStorage.removeItem(AuthUtil.LS_JWT_ACCESS_TOKEN_VALID_UNTIL);
    }

    /**
     * Get the refresh token from local storage
     */
    static getRefreshToken(): string {
        return localStorage.getItem(AuthUtil.LS_JWT_REFRESH_TOKEN) ?? null;
    }

    /**
     * Set the refresh token in local storage
     * @param token
     */
    static setRefreshToken(token: string): string {
        localStorage.setItem(AuthUtil.LS_JWT_REFRESH_TOKEN, token)

        const decodedData = this.getTokenPayload(token);
        localStorage.setItem(AuthUtil.LS_JWT_REFRESH_TOKEN_VALID_UNTIL, `${decodedData.exp}`);

        return token;
    }

    /**
     * Get refresh token valid until timestamp in seconds
     */
    static getRefreshTokenValidUntil(): number {
        return +(localStorage.getItem(AuthUtil.LS_JWT_REFRESH_TOKEN_VALID_UNTIL) ?? null);
    }

    /**
     * Check if the refresh token is expired
     */
    static getRefreshTokenExpired(): boolean {
        return this.getRefreshTokenValidUntil() * 1000 < Date.now();
    }

    /**
     * Get access token valid until timestamp in seconds
     */
    static getAccessTokenValidUntil(): number {
        return +(localStorage.getItem(AuthUtil.LS_JWT_ACCESS_TOKEN_VALID_UNTIL) ?? null);
    }

    /**
     * Check if the access token is expired
     */
    static getAccessTokenExpired(): boolean {
        return this.getAccessTokenValidUntil() * 1000 < Date.now();
    }

    /**
     * Get the access token from local storage
     */
    static getAccessToken(): string {
        return localStorage.getItem(AuthUtil.LS_JWT_ACCESS_TOKEN) ?? null;
    }

    /**
     * Set the access token in local storage
     * @param token
     */
    static setAccessToken(token: string): string {
        localStorage.setItem(AuthUtil.LS_JWT_ACCESS_TOKEN, token)

        const decodedData = this.getTokenPayload(token);
        localStorage.setItem(AuthUtil.LS_JWT_ACCESS_TOKEN_VALID_UNTIL, `${decodedData.exp}`);

        return token;
    }
}