import * as React from "react";
import { useEffect } from "react";
import "./DeviceApiTokensPanel.scss"
import { Fieldset } from "primereact/fieldset";
import { useTranslation } from "react-i18next";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DeviceApiTokenACLUtil, DeviceApiTokenInterface } from "@dashart/dashart-gpt-shared-library/dist/";
import { DeviceApiTokensService } from "@app/services/DeviceApiTokensService";
import { useSetRecoilState } from "recoil";
import RecoilStates from "@app/models/RecoilStates";
import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import BaseInput, { BaseInputType } from "@view/components/inputs/baseInput/BaseInput";
import { DateTime } from "luxon";

export interface DeviceApiTokensPanelProps {
    currentUser: UserInterface;
    user: UserInterface;
    editable: boolean;
}

const DeviceApiTokensPanel: React.FunctionComponent<DeviceApiTokensPanelProps> = (props: DeviceApiTokensPanelProps) => {
    const { currentUser, user, editable } = props;
    const { t } = useTranslation();
    const [deviceApiTokens, setDeviceApiTokens] = React.useState<DeviceApiTokenInterface[]>([]);
    const setLastToastMessage = useSetRecoilState(RecoilStates.lastToastMessageState);

    const [showCreateTokenDialog, setShowCreateTokenDialog] = React.useState(false);
    const [showCreateTokenSuccessDialog, setShowCreateTokenSuccessDialog] = React.useState(false);
    const [showRemoveTokenDialog, setShowRemoveTokenDialog] = React.useState(false);

    const [nameOfNewToken, setNameOfNewToken] = React.useState('');
    const [lastCreatedToken, setLastCreatedToken] = React.useState<DeviceApiTokenInterface>(null);
    const [tokenToBeRemoved, setTokenToBeRemoved] = React.useState<DeviceApiTokenInterface>(null);

    // load device api tokens
    useEffect(() => {
        if (user) {
            loadAllTokensOfUser()
        }
    }, [user]);

    /**
     * load all tokens of the user
     */
    const loadAllTokensOfUser = () => {
        DeviceApiTokensService.getAllByUser(user.id)
            .then((deviceApiTokens) => {
                setDeviceApiTokens(deviceApiTokens);
            }).catch((error) => {
                setLastToastMessage({
                    severity: "error",
                    summary: t("components.device_api_tokens_panel.load_error"),
                    detail: error.message
                } as ToastMessageInterface);
            })
    }

    /**
     * create a new token
     */
    const createNewToken = () => {
        setShowCreateTokenDialog(false);
        DeviceApiTokensService.createOne(
            {
                user: user,
                name: nameOfNewToken,
                type: 'device_api_token',
            }
        ).then((deviceApiToken) => {
            setLastToastMessage({
                severity: "success",
                summary: t("components.device_api_tokens_panel.addTokenDialog.create_success"),
            });

            setLastCreatedToken(deviceApiToken);
            setShowCreateTokenSuccessDialog(true);
            // reload the tokens
            loadAllTokensOfUser();
        }).catch((error) => {
            setLastToastMessage({
                severity: "error",
                summary: t("components.device_api_tokens_panel.addTokenDialog.create_error", { error: JSON.stringify(error) }),
            });
        });
    }

    /**
     * remove a token
     */
    const removeToken = () => {
        setShowRemoveTokenDialog(false);
        DeviceApiTokensService.deleteOne(
            tokenToBeRemoved.id
        ).then((deviceApiToken) => {
            setLastToastMessage({
                severity: "success",
                summary: t("components.device_api_tokens_panel.removeTokenDialog.remove_success"),
            });

            setTokenToBeRemoved(null);
            // reload the tokens
            loadAllTokensOfUser();
        }).catch((error) => {
            setLastToastMessage({
                severity: "error",
                summary: t("components.device_api_tokens_panel.removeTokenDialog.remove_error", { error: JSON.stringify(error) }),
            });
        });
    }

    /**
     * render the row action menu
     * @param rowData
     */
    const cellParserActions = (rowData: DeviceApiTokenInterface) => {
        const buttons = [];

        if (DeviceApiTokenACLUtil.canUserDeleteTokensOfUser(props.currentUser, rowData)) {
            buttons.push(
                <Button
                    key={`btn-delete-${rowData.id}`}
                    data-test-id={"btn-delete-user"}
                    onClick={() => {
                        setTokenToBeRemoved(rowData);
                        setShowRemoveTokenDialog(true);
                    }}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text mr-1"
                />
            )
        }

        return (
            <>
                {buttons}
            </>
        );
    }

    /**
     * render the top action menu
     * @param key
     */
    const actionMenu = (key: string = '') => {
        const buttons = [];

        if (DeviceApiTokenACLUtil.canUserCreateDeviceApiToken(props.currentUser, { name: '', type: '', user: user })) {
            buttons.push(
                <Button
                    key={key + "-btn-add"}
                    className='ml-2 p-button-sm'
                    label={t('components.device_api_tokens_panel.label_addToken')}
                    icon={'pi pi-fw pi-plus'}
                    onClick={() => {
                        setShowCreateTokenDialog(true);
                    }}
                />
            )
        }

        return <div
            className={'flex flex-row justify-content-end w-full'}
        >
            {editable ? buttons : null}
        </div>
    }

    /**
     * copy the last created token to clipboard
     */
    const copyTokenToClipboard = () => {
        const token = lastCreatedToken?.token ?? '';
        navigator.clipboard.writeText(token).then(() => {
            setLastToastMessage({
                severity: "success",
                summary: t("components.device_api_tokens_panel.addTokenSuccessDialog.copy_success"),
            });
        }).catch((error) => {
            setLastToastMessage({
                severity: "error",
                summary: t(
                    "components.device_api_tokens_panel.addTokenSuccessDialog.copy_failed",
                    { error: JSON.stringify(error) }
                ),
            });
        });
    }

    //
    // create new token dialogs

    /**
     * render the create new token dialog
     */
    const createNewTokenDialog = () => {
        return <Dialog
            header={t('components.device_api_tokens_panel.addTokenDialog.header')}
            visible={showCreateTokenDialog}
            style={{ width: '50vw' }}
            onHide={() => setShowCreateTokenDialog(false)}
            onShow={() => setNameOfNewToken('')}
            footer={<div>
                <Button
                    label={t('globals.actions.cancel')}
                    icon="pi pi-times"
                    onClick={() => setShowCreateTokenDialog(false)}
                    className="p-button-text" />
                <Button
                    label={t('components.device_api_tokens_panel.addTokenDialog.btn_create')}
                    icon="pi pi-check"
                    onClick={createNewToken}
                    autoFocus
                />
            </div>}
        >
            <div className="p-fluid formgrid grid">
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t('components.device_api_tokens_panel.label_name')}
                    value={nameOfNewToken}
                    valueName={'name'}
                    handlerChange={
                        (data: any) => {
                            setNameOfNewToken(data);
                        }
                    }
                />
            </div>
        </Dialog>
    }

    /**
     * render the create new token success dialog
     */
    const createNewTokenSuccessDialog = () => {
        return <Dialog
            header={t('components.device_api_tokens_panel.addTokenSuccessDialog.header')}
            visible={showCreateTokenSuccessDialog}
            style={{ width: '50vw' }}
            onHide={() => setShowCreateTokenSuccessDialog(false)}
            footer={<div>
                <Button
                    label={t('components.device_api_tokens_panel.addTokenSuccessDialog.btn_copy')}
                    icon="pi pi-copy"
                    onClick={copyTokenToClipboard}
                />
                <Button
                    label={t('globals.actions.okay')}
                    icon="pi pi-check"
                    onClick={() => setShowCreateTokenSuccessDialog(false)}
                    autoFocus
                />
            </div>}
        >
            <div className="p-fluid formgrid grid">
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t('components.device_api_tokens_panel.addTokenSuccessDialog.label_name')}
                    value={lastCreatedToken.name}
                    readOnly={true}
                />
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t('components.device_api_tokens_panel.addTokenSuccessDialog.label_token')}
                    value={lastCreatedToken.token}
                    readOnly={true}
                />
                <div className={'col-12 w-full'}>
                    <p>{t('components.device_api_tokens_panel.addTokenSuccessDialog.note')}</p>
                </div>
            </div>

        </Dialog>
    }

    //
    // delete a token dialogs

    const createRemoveTokenDialog = () => {
        return <Dialog
            header={t('components.device_api_tokens_panel.removeTokenDialog.header')}
            visible={showRemoveTokenDialog}
            style={{ width: '50vw' }}
            onHide={() => setShowRemoveTokenDialog(false)}
            footer={<div>
                <Button
                    label={t('globals.actions.no')}
                    icon="pi pi-times"
                    onClick={() => setShowRemoveTokenDialog(false)}
                    className="p-button-text" />
                <Button
                    label={t('globals.actions.yes')}
                    icon="pi pi-check"
                    onClick={removeToken}
                    autoFocus
                />
            </div>}
        >
            <div className="p-fluid formgrid grid">
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t('components.device_api_tokens_panel.removeTokenDialog.label_name')}
                    value={tokenToBeRemoved.name}
                    valueName={'name'}
                    readOnly={true}
                />
                <BaseInput
                    type={BaseInputType.TextInput}
                    classes={'col-12'}
                    label={t('components.device_api_tokens_panel.removeTokenDialog.label_token')}
                    value={tokenToBeRemoved.strippedToken}
                    valueName={'token'}
                    readOnly={true}
                />
            </div>
        </Dialog>
    }

    return <Fieldset className={'DeviceApiTokensPanel'} legend={t("components.device_api_tokens_panel.header")}>

        {showCreateTokenDialog ? createNewTokenDialog() : null}
        {showCreateTokenSuccessDialog ? createNewTokenSuccessDialog() : null}
        {showRemoveTokenDialog ? createRemoveTokenDialog() : null}

        <div className={'col-12 w-full'}>
            <p>{t('components.device_api_tokens_panel.note')}</p>
        </div>

        <DataTable
            value={deviceApiTokens}
            rowHover={true}
            scrollable scrollHeight={'flex'}
            paginator rows={50} rowsPerPageOptions={[50, 100, 500]}
            footer={actionMenu('bottom')}
        >
            <Column
                field={'name'}
                header={t('components.device_api_tokens_panel.label_token')}
                sortable={true}
                sortField={'name'} />
            <Column
                field={'strippedToken'}
                header={t('components.device_api_tokens_panel.label_name')}
                sortable={true}
                sortField={'strippedToken'} />
            <Column
                field={'createdAt'}
                header={t('components.device_api_tokens_panel.label_createdAt')}
                sortable={true}
                body={(rowData: DeviceApiTokenInterface) => DateTime.fromISO(rowData.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
                sortField={'createdAt'} />
            {editable ? <Column
                header={t('user_management.actions')}
                body={cellParserActions}
                style={{ width: '5rem' }}
            /> : null}
        </DataTable>
    </Fieldset>
}

export default DeviceApiTokensPanel;