import { CopyIcon } from "@view/components/icons/CopyIcon";
import { OkIcon } from "@view/components/icons/OkIcon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import RecoilStates from "../../../app/models/RecoilStates";
import styles from './CodeCopyBtn.module.scss';

const CodeCopyBtn = ({ children }) => {
    const [copySuccess, setCopySuccess] = React.useState(false);
    const { t } = useTranslation();
    const setToast = useSetRecoilState(RecoilStates.lastToastMessageState);
    const iconSize = 12;
    const handleClick = (e) => {
        try {
            // try to copy the code rendered by the markdown editor
            navigator.clipboard.writeText(
                children[0].props.children[0]
            );
            setToast({
                severity: 'success',
                summary: t('globals.clipboard.copied'),
                lifetime: 1000
            });
        } catch (e) {
            // if it fails, copy the raw code
            navigator.clipboard.writeText(
                children
            );
            setToast({
                severity: 'warn',
                summary: t('globals.clipboard.failed'),
                lifetime: 1000
            });
        }

        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 500);
    }
    return (
        <div className={styles.CodeCopyBtn} onClick={handleClick}>
            <div className={styles.inner}>
                {copySuccess ?
                    <OkIcon width={iconSize} height={iconSize} /> : <CopyIcon width={iconSize} height={iconSize} />
                }
            </div>
        </div>
    )
}

export default CodeCopyBtn;