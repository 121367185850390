import { UserRolesEnum } from "@dashart/dashart-gpt-shared-library/dist/";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";

export interface RolesValue {
    role: number,
    label: string,
}

export class RolesUtil {
    /**
     * Validates if given user has minimum role or higher
     * @param user
     * @param minimumRole
     */
    static userHasMinimumRole = (user: UserInterface, minimumRole: UserRolesEnum) => {
        const role = +user?.role;
        if (user?.role === null || role < UserRolesEnum.ADMIN || isNaN(role)) {
            return false;
        }
        return user && role <= minimumRole;
    }
    /**
     * Validates if given user has role or lower
     * @param user
     * @param maximumRole
     */
    static userHasMaximumRole = (user: UserInterface, maximumRole: UserRolesEnum) => {
        const role = +user?.role;
        if (user?.role === null || role < UserRolesEnum.ADMIN || isNaN(role)) {
            return false;
        }
        return user && role >= maximumRole;
    }

    /**
     * Returns available roles by user
     * @param user
     * @param translateFunction
     */
    static getAvailableRoles(user: UserInterface, translateFunction: (key: string) => string): RolesValue[] {
        switch (+user?.role) {
            case UserRolesEnum.ADMIN:
                return [
                    { role: UserRolesEnum.ADMIN, label: translateFunction('globals.roles.0') },
                    { role: UserRolesEnum.COMPANY_ADMIN, label: translateFunction('globals.roles.10') },
                    { role: UserRolesEnum.MANAGER, label: translateFunction('globals.roles.20') },
                    { role: UserRolesEnum.USER, label: translateFunction('globals.roles.30') },
                ];
            case UserRolesEnum.COMPANY_ADMIN:
                return [
                    { role: UserRolesEnum.COMPANY_ADMIN, label: translateFunction('globals.roles.10') },
                    { role: UserRolesEnum.MANAGER, label: translateFunction('globals.roles.20') },
                    { role: UserRolesEnum.USER, label: translateFunction('globals.roles.30') },
                ];
            case UserRolesEnum.MANAGER:
                return [
                    { role: UserRolesEnum.MANAGER, label: translateFunction('globals.roles.20') },
                    { role: UserRolesEnum.USER, label: translateFunction('globals.roles.30') },
                ];
            case UserRolesEnum.USER:
                return [
                    { role: UserRolesEnum.USER, label: translateFunction('globals.roles.30') },
                ];
            default:
                return [];
        }
    }
}