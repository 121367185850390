import * as React from "react";
import { ThreeDotsIcon } from "../icons/ThreeDotsIcon";
import styles from './ChatMessageOptionsButton.module.scss';

export interface ChatMessageOptionsButtonProps {
    onClickHandler?: () => void;
}

const ChatMessageOptionsButton: React.FunctionComponent<ChatMessageOptionsButtonProps> = (props: ChatMessageOptionsButtonProps) => {
    const { onClickHandler } = props;
    const iconSize = 12;
    
    const handleClick = (e) => {
        if (onClickHandler) {
            onClickHandler();
        }
    }
    return (
        <div className={styles.ChatMessageOptionsButton} onClick={handleClick}>
            <div className={styles.inner}>
                <ThreeDotsIcon width={iconSize} height={iconSize} />
            </div>
        </div>
    )
}

export default ChatMessageOptionsButton;