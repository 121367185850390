import * as React from "react";
import {CSSProperties} from "react";

export const TokensIcon: React.FC<{ className?: any, color?:string,width: number, height: number }> = (props) => {

    const style: CSSProperties = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: props.color ? props.color : '#000000',
    }

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                style={style}
                d="M47 377l96 96c9.4 9.4 24.6 9.4 33.9 0l96-96c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-55 55V56c0-13.3-10.7-24-24-24s-24 10.7-24 24V398.1L81 343c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9zm409-65c0-7.7-3.7-15-10-19.5s-14.3-5.7-21.6-3.3l-48 16c-12.6 4.2-19.4 17.8-15.2 30.4s17.8 19.4 30.4 15.2l16.4-5.5V432H384c-13.3 0-24 10.7-24 24s10.7 24 24 24h48 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H456V312zM424 160a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm88-40c0-48.6-39.4-88-88-88s-88 39.4-88 88c0 42.1 29.6 77.3 69.1 86l-15.6 18.7c-8.5 10.2-7.1 25.3 3.1 33.8s25.3 7.1 33.8-3.1l64.6-77.6c13-15.6 20.3-35.1 20.9-55.3c0-.8 0-1.6 0-2.5z"
            />
        </g>
    </svg>
}