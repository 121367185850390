import * as React from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";

export interface ConfirmDialogProps {
    headline: string;
    body: string;

    confirmLabel: string;
    cancelLabel?: string;

    onConfirm: () => void;
    onCancel?: () => void;
    onHide?: () => void;
}

const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = (props: ConfirmDialogProps) => {

    const {t} = useTranslation();

    const footer = <div>
        {props.cancelLabel ? <Button label={props.cancelLabel} className='p-button-secondary' icon="pi pi-times"
                                     onClick={props.onCancel}/> : null}
        <Button label={props.confirmLabel} className='p-button' icon="pi pi-check" onClick={props.onConfirm}/>
    </div>

    return <Dialog
        header={props.headline}
        visible={true}
        footer={footer}
        onHide={props.onHide}
        style={{maxWidth: '50vw', whiteSpace: 'pre-wrap'}}
    >
        <p>{props.body}</p>
    </Dialog>
}

export default ConfirmDialog;