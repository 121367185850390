import * as Sentry from "@sentry/browser";

export class SentryHelper {
    static apiCatch = (error) => {
        const response = {
            ...error?.response
        }

        if(response?.config?.headers?.Authorization) {
            delete response.config.headers.Authorization;
        }

        const stringifiedErrorResponse = JSON
            .stringify(response)

        Sentry.configureScope(
            scope => scope
                .setLevel('error')
                // TODO: Add user data
                //.setUser({email: state?.userDataState?.user?.email, id: `${state?.userDataState?.user?.id}`})
                //.setExtra("store", stringifiedState)
                .setExtra("errorResponse", stringifiedErrorResponse)
        );
        // Sentry.showReportDialog(); - If you want get users feedback on error
        return Sentry.captureException(error);
    };
}