import * as React from "react";
import {CSSProperties} from "react";

export const SendIcon: React.FC<{ className?: any, color?:string,width: number, height: number }> = (props) => {

    const style: CSSProperties = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: props.color ? props.color : '#000000',
    }

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                style={style}
                d="M49.9 27.8C15.1 12.7-19.2 50.1-1.2 83.5L68.1 212.2c4.4 8.3 12.6 13.8 21.9 15c0 0 0 0 0 0l176 22c3.4 .4 6 3.3 6 6.7s-2.6 6.3-6 6.7l-176 22s0 0 0 0c-9.3 1.2-17.5 6.8-21.9 15L-1.2 428.5c-18 33.4 16.3 70.8 51.1 55.7L491.8 292.7c32.1-13.9 32.1-59.5 0-73.4L49.9 27.8z"
            />
        </g>
    </svg>
}