import * as React from "react";
import {Button, ButtonProps} from "primereact/button";
import {useNavigate} from "react-router-dom";

export interface ButtonWithLinkProps extends ButtonProps {
    href: string;
}

const ButtonWithLink: React.FunctionComponent<ButtonWithLinkProps> = (props: ButtonWithLinkProps) => {
    const navigate = useNavigate();

    const onClickHandler = () => {
        if (props.href) {
            navigate(props.href)
        }
    }

    // @ts-ignore
    return <Button
        {...props}
        onClick={onClickHandler}
    />
}

export default ButtonWithLink;