// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InstructionPanel{width:100%;position:relative;z-index:1}.InstructionPanel .iconsContainer{position:absolute;top:-0.25rem;right:.25rem;padding:.5rem;display:flex;justify-content:space-between;align-items:center;gap:.25rem}.InstructionPanel .instructionsInput{position:relative}.InstructionPanel .instructionsInput .label{position:absolute;left:1rem;top:-0.15rem}.InstructionPanel .instructionsInput textarea{padding:.75rem .5rem .5rem .5rem;resize:none}`, "",{"version":3,"sources":["webpack://./src/view/components/instructionPanel/InstructionPanel.scss"],"names":[],"mappings":"AAAA,kBACI,UAAA,CACA,iBAAA,CACA,SAAA,CAEA,kCACI,iBAAA,CACA,YAAA,CACA,YAAA,CACA,aAAA,CAEA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CAGJ,qCACI,iBAAA,CAEA,4CACI,iBAAA,CACA,SAAA,CACA,YAAA,CAGJ,8CACI,gCAAA,CAEA,WAAA","sourcesContent":[".InstructionPanel {\n    width: 100%;\n    position: relative;\n    z-index: 1;\n\n    .iconsContainer {\n        position: absolute;\n        top: -.25rem;\n        right: .25rem;\n        padding: .5rem;\n\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        gap: .25rem;\n    }\n\n    .instructionsInput {\n        position: relative;\n\n        .label {\n            position: absolute;\n            left: 1rem;\n            top: -.15rem;\n        }\n\n        textarea {\n            padding: .75rem .5rem .5rem .5rem;\n            // disallow resizing\n            resize: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InstructionPanel": `InstructionPanel`,
	"iconsContainer": `iconsContainer`,
	"instructionsInput": `instructionsInput`,
	"label": `label`
};
export default ___CSS_LOADER_EXPORT___;
