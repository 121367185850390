import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { UserService } from "@app/services/UserService";
import { RolesUtil } from "@app/utils/RolesUtil";
import { CompanyInterface } from "@dashart/dashart-gpt-shared-library";
import { CreateUserDto } from "@dashart/dashart-gpt-shared-library/dist/dtos/user/create-user.dto";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import ConfirmDialog from "@view/components/confirmDialog/ConfirmDialog";
import DeviceApiTokensPanel from "@view/components/deviceApiTokensPanel/DeviceApiTokensPanel";
import FormValidationErrorFieldset from "@view/components/formValidationErrorFieldset/FormValidationErrorFieldset";
import BaseInput, { BaseInputType } from "@view/components/inputs/baseInput/BaseInput";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import RoleValidatorComponent from "@view/components/roleValidatorComponent/RoleValidatorComponent";
import { transformAndValidateSync } from "class-transformer-validator";
import { ValidationError } from "class-validator";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyService } from "../../../app/services/CompanyService";
import "./UserDetailScreen.scss";

export enum UserDetailScreenMode {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    VIEW = "VIEW",
    UNKNOWN = "UNKNOWN",
}

export interface UserDetailScreenProps {
    currentUser: UserInterface;
    mode: UserDetailScreenMode;
    showToast: (toast: ToastMessageInterface) => void
}

interface RoleDropdownValue {
    role: number,
    label: string,
}

interface CompanyDropdownValue {
    id: number,
    label: string,
}

interface FormData extends UserInterface {
    passwordConfirm?: string,
}

const UserDetailScreen: React.FunctionComponent<UserDetailScreenProps> = (props: UserDetailScreenProps) => {
    const { currentUser, mode } = props;
    const { t } = useTranslation();
    const { userId } = useParams();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [serverActionPending, setServerActionPending] = useState(false);

    const [formData, setFormData] = useState<FormData>(null);
    const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

    const [selectableCompanies, setSelectableCompanies] = useState<CompanyInterface[]>([]);

    const [rolesDropDownValues, setRolesDropDownValues] = useState<RoleDropdownValue[]>([])
    const [selectableCompaniesDropDownValues, setSelectableCompaniesDownValues] = useState<CompanyDropdownValue[]>([])

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const loadSelectableCompanies = async () => {
            try {
                const companies = await fetchAllSelectableCompanies();
                setSelectableCompanies(companies);
            } catch (error) {
                props.showToast({
                    severity: "error",
                    summary: t('globals.loadFailedNotice'),
                    detail: JSON.stringify(error ?? '')
                });
            }
        };

        loadSelectableCompanies();
    }, []);

    /**
     * Fetch all selectable companies
     * @param start 
     * @param limit 
     * @returns 
     */
    const fetchAllSelectableCompanies = async (start = 1, limit = 100): Promise<CompanyInterface[]> => {
        let companies = [];
        const result = await CompanyService.getAll(start, limit);

        companies = [...selectableCompanies, ...result.data];

        if (result.meta.next_page_url) {
            companies = [...companies, ...await fetchAllSelectableCompanies(start + 1, limit)]
        }

        return companies;
    }

    /**
     * Load user from backend
     * @param userId
     */
    const loadUser = async (userId: string) => {
        setServerActionPending(true);
        try {
            setFormData(await UserService.getOne(userId));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            navigate(RoutesEnum.userList);
        }
        setServerActionPending(false);
    };

    /**
     * Delete user from backend
     * @param userId
     */
    const deleteUser = async (userId: string) => {
        setServerActionPending(true);
        try {
            await UserService.deleteOne(userId);
            props.showToast({
                severity: "success",
                summary: t('globals.deleteSuccessNotice'),
            });
            navigate(RoutesEnum.userList);
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.deleteFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    /**
     * Update user in backend
     * @param userId
     */
    const updateUser = async (userId: string) => {
        setServerActionPending(true);
        try {
            const update = await UserService.updateOne(userId, formData);
            props.showToast({
                severity: "success",
                summary: t('globals.updateSuccessNotice'),
            });
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.updateFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    /**
     * Create user in backend
     */
    const createUser = async () => {
        setServerActionPending(true);
        try {
            const user = await UserService.createOne(formData);
            props.showToast({
                severity: "success",
                summary: t('globals.createSuccessNotice'),
            });
            navigate(RoutesEnum.userDetail.replace(RoutesParametersEnum.userId, `${user.id}`));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.createFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    useEffect(() => {
        if (userId) {
            loadUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        setRolesDropDownValues(RolesUtil.getAvailableRoles(currentUser, t));
        setIsEditMode(mode === UserDetailScreenMode.CREATE || mode === UserDetailScreenMode.UPDATE);
    }, [formData, currentUser, props.mode])

    /**
     * Update selectable companies dropdown values
     */
    useEffect(() => {
        setSelectableCompaniesDownValues(
            selectableCompanies.map(company => {
                return {
                    id: company.id,
                    label: company.name,
                }
            })
        );
        setIsEditMode(mode === UserDetailScreenMode.CREATE || mode === UserDetailScreenMode.UPDATE);
    }, [formData, selectableCompanies, props.mode])

    const setAndValidateUserData = (formData: FormData) => {
        if (formData) {
            formData = { ...formData };
            validateInputs(formData);
        }

        setFormData(formData);
    }

    const userRoleDropDownValue = React.useMemo(() => {
        return rolesDropDownValues.find(value => value.role === formData?.role);
    }, [formData?.role, rolesDropDownValues]);

    const companyDropDownValue = React.useMemo(() => {
        return selectableCompaniesDropDownValues.find(value => value.id === formData?.companyId);
    }, [formData?.companyId, selectableCompaniesDropDownValues]);

    const validateInputs = (formData: FormData): ValidationError[] => {
        // workaround to allow empty password inputs
        formData = {
            ...formData,
            password: formData?.password !== '' ? formData?.password : null,
            passwordConfirm: formData?.passwordConfirm !== '' ? formData?.passwordConfirm : null,
        }

        try {
            transformAndValidateSync(CreateUserDto, formData);
            setFormErrors([]);
            return [];
        } catch (err) {
            setFormErrors(err);
            return err;
        }
    }

    /**
     * Render device api tokens panel if needed
     */
    const deviceApiTokensPanel = React.useMemo(() => {
        if ((mode === UserDetailScreenMode.UPDATE || mode === UserDetailScreenMode.VIEW) && formData?.id !== undefined) {
            return <DeviceApiTokensPanel
                currentUser={currentUser}
                user={formData}
                editable={isEditMode}
            />
        }

        return null;
    }, [formData?.id, currentUser, mode, isEditMode]);

    /**
     * Validates the logic and triggers form data save
     */
    const onSaveClickHandler = async () => {
        const errors = validateInputs(formData);

        if (errors?.length > 0) {
            props.showToast({
                severity: "error",
                summary: t('globals.validationFailedNotice')
            });
        } else {
            if (mode === UserDetailScreenMode.UPDATE) {
                await updateUser(userId);
            } else if (mode === UserDetailScreenMode.CREATE) {
                await createUser();
            }
        }
    }

    /**
     * Delete user after confirmation modal
     */
    const onDeleteClickHandler = async () => {
        setShowDeleteConfirmation(true);
    }

    const onDeleteConfirmYesHandler = () => {
        setShowDeleteConfirmation(false);
        setServerActionPending(true);

        deleteUser(userId);
    }

    const onDeleteConfirmNoHandler = () => {
        setShowDeleteConfirmation(false);
    }

    const actionButtons = () => {
        if (mode === UserDetailScreenMode.CREATE) {
            return <>
                <Button
                    className="button-secondary"
                    label={t('globals.actions.cancel')}
                    icon="pi pi-times"
                    onClick={() => navigate(-1)}
                />
                <Button
                    className="ml-2"
                    label={t('globals.actions.create')}
                    icon="pi pi-check"
                    onClick={onSaveClickHandler}
                />
            </>
        } else if (mode === UserDetailScreenMode.UPDATE) {
            return <>
                <Button
                    className="button-secondary"
                    label={t('globals.actions.dismiss')}
                    icon="pi pi-times"
                    onClick={() => navigate(-1)}
                />
                <Button
                    className="ml-2"
                    label={t('globals.actions.save')}
                    icon="pi pi-check"
                    onClick={onSaveClickHandler}
                />
            </>
        } else if (mode === UserDetailScreenMode.DELETE) {
            return <>
                <Button
                    className="button-secondary"
                    label={t('globals.actions.backToOverview')}
                    icon="pi pi-chevron-left"
                    onClick={() => navigate(-1)}
                />
                <Button
                    className="ml-2 button-danger"
                    label={t('globals.actions.delete')}
                    icon="pi pi-trash"
                    onClick={onDeleteClickHandler}
                />
            </>
        }

        return <>
            <ButtonWithLink
                className="button-secondary"
                label={t('globals.actions.backToOverview')}
                icon="pi pi-chevron-left"
                href={RoutesEnum.userList}
            />
        </>;
    };

    /**
     * Render delete confirmation dialog if needed
     */
    const deleteConfirmation = () => {
        if (!showDeleteConfirmation) {
            return null;
        }

        return <ConfirmDialog
            headline={t("user_management.deleteConfirmation.headline")}
            body={t(
                "user_management.deleteConfirmation.body",
                formData
            )}
            confirmLabel={t('globals.actions.yes')}
            cancelLabel={t('globals.actions.no')}
            onConfirm={onDeleteConfirmYesHandler}
            onCancel={onDeleteConfirmNoHandler}
            onHide={onDeleteConfirmNoHandler}
        />;
    }

    return <div className='UserDetailScreen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'userScope'}
            userOfInterest={formData}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />

        {deleteConfirmation()}

        <Panel header={t("user_management.detail_screen.header")} className={"p-4"}>

            <Fieldset legend={t("user_management.detail_screen.contactHeader")} className={'mb-4'}>

                <ProgressSpinnerOverlay visible={serverActionPending} />

                <div className="p-fluid formgrid grid">
                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("user_management.detail_screen.label_firstName")}
                        value={formData?.firstName}
                        valueName={'firstName'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, firstName: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("user_management.detail_screen.label_familyName")}
                        value={formData?.familyName}
                        valueName={'familyName'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, familyName: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("user_management.detail_screen.label_email")}
                        value={formData?.email}
                        valueName={'email'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, email: data });
                            }
                        }
                    />

                </div>
            </Fieldset>

            <Fieldset legend={t("user_management.detail_screen.permissionHeader")} className={'mb-4'}>
                <div className="p-fluid formgrid grid">

                    <BaseInput
                        type={BaseInputType.DropDown}
                        classes={'col-6'}
                        label={t("user_management.detail_screen.label_role")}
                        value={userRoleDropDownValue}
                        options={rolesDropDownValues}
                        optionLabel={'label'}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, role: (data as RoleDropdownValue)?.role });
                            }
                        }
                    />
                </div>

            </Fieldset>

            <Fieldset legend={t("user_management.detail_screen.companyHeader")} className={'mb-4'}>
                <div className="p-fluid formgrid grid">

                    <BaseInput
                        type={BaseInputType.DropDown}
                        classes={'col-6'}
                        label={t("user_management.detail_screen.label_companyId")}
                        value={companyDropDownValue}
                        options={selectableCompaniesDropDownValues}
                        optionLabel={'label'}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, companyId: (data as CompanyDropdownValue)?.id });
                            }
                        }
                    />
                </div>

            </Fieldset>

            {(isEditMode) ?
                <Fieldset legend={t("user_management.detail_screen.passwordHeader")} className={'mb-4'}>

                    <div className="p-fluid formgrid grid">
                        <BaseInput
                            type={BaseInputType.Password}
                            classes={'col-6'}
                            label={t("user_management.detail_screen.label_password")}
                            value={formData?.password}
                            valueName={'password'}
                            formErrors={formErrors}
                            handlerChange={
                                (data: any) => {
                                    setAndValidateUserData({ ...formData, password: data });
                                }
                            }
                        />

                        <BaseInput
                            type={BaseInputType.Password}
                            classes={'col-6'}
                            label={t("user_management.detail_screen.label_passwordConfirm")}
                            value={formData?.passwordConfirm}
                            valueName={'passwordConfirm'}
                            formErrors={formErrors}
                            handlerChange={
                                (data: any) => {
                                    setAndValidateUserData({ ...formData, passwordConfirm: data });
                                }
                            }
                        />
                    </div>

                </Fieldset>
                : null
            }

            <Fieldset legend={t("user_management.detail_screen.apiTokensHeader")} className={'mb-4'}>
                <div className="p-fluid formgrid grid">

                    <BaseInput
                        type={BaseInputType.TextInput} classes={'col-6'}
                        label={t("user_management.detail_screen.label_customOpenAiApiToken")}
                        value={formData?.customOpenAiApiToken}
                        valueName={'customOpenAiApiToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, customOpenAiApiToken: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput} classes={'col-6'}
                        label={t("user_management.detail_screen.label_customClaudeApiToken")}
                        value={formData?.customClaudeApiToken}
                        valueName={'customClaudeApiToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, customClaudeApiToken: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput} classes={'col-6'}
                        label={t("user_management.detail_screen.label_customMistralApiToken")}
                        value={formData?.customMistralApiToken}
                        valueName={'customMistralApiToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateUserData({ ...formData, customMistralApiToken: data });
                            }
                        }
                    />
                </div>

            </Fieldset>

            {deviceApiTokensPanel}

            <div className={'messages-area'}>

                {formErrors?.length > 0 ? <FormValidationErrorFieldset
                    headline={t("user_management.detail_screen.label_validation_errors")}
                    translationKeyBase={"user_management.detail_screen.label_"}
                    validationErrors={formErrors}
                /> : null}

            </div>

            <div className="col-12 d-flex justify-end">
                {actionButtons()}
            </div>

        </Panel>

    </div>
}

export default UserDetailScreen;
