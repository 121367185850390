// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InstructionsSelectorDialogHistoryTab{position:relative}.InstructionsSelectorDialogHistoryTab .contentContainer{position:absolute;top:0;left:0;right:0;bottom:0;display:flex;flex-direction:column}.InstructionsSelectorDialogHistoryTab .contentContainer>*{flex:0 0 auto}.InstructionsSelectorDialogHistoryTab .contentContainer .tableContainer{flex:1 1 auto;position:relative}.InstructionsSelectorDialogHistoryTab .contentContainer .tableContainer>*{position:absolute;width:100%}`, "",{"version":3,"sources":["webpack://./src/view/components/instructionPanel/instructionsSelectorDialog/instructionsSelectorDialogHistoryTab/InstructionsSelectorDialogHistoryTab.scss"],"names":[],"mappings":"AAAA,sCACI,iBAAA,CAEA,wDACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CAEA,YAAA,CACA,qBAAA,CAEA,0DACI,aAAA,CAGJ,wEACI,aAAA,CACA,iBAAA,CAEA,0EACI,iBAAA,CACA,UAAA","sourcesContent":[".InstructionsSelectorDialogHistoryTab {\n    position: relative;\n\n    .contentContainer{\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n\n        display: flex;\n        flex-direction: column;\n\n        & > * {\n            flex: 0 0 auto;\n        }\n\n        .tableContainer {\n            flex: 1 1 auto;\n            position: relative;\n\n            & > * {\n                position: absolute;\n                width: 100%;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InstructionsSelectorDialogHistoryTab": `InstructionsSelectorDialogHistoryTab`,
	"contentContainer": `contentContainer`,
	"tableContainer": `tableContainer`
};
export default ___CSS_LOADER_EXPORT___;
