// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormValidationErrorFieldset{display:flex;word-break:break-word}.FormValidationErrorFieldset li{list-style:none}.FormValidationErrorFieldset li b{text-transform:capitalize}.FormValidationErrorFieldset li li{list-style:disc}`, "",{"version":3,"sources":["webpack://./src/view/components/formValidationErrorFieldset/FormValidationErrorFieldset.scss"],"names":[],"mappings":"AAEA,6BACE,YAAA,CACA,qBAAA,CAEA,gCACE,eAAA,CAEA,kCACE,yBAAA,CAGF,mCACE,eAAA","sourcesContent":["@import \"src/styles/helpers\";\n\n.FormValidationErrorFieldset {\n  display: flex;\n  word-break: break-word;\n\n  li {\n    list-style: none;\n\n    b {\n      text-transform: capitalize;\n    }\n\n    li {\n      list-style: disc;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormValidationErrorFieldset": `FormValidationErrorFieldset`
};
export default ___CSS_LOADER_EXPORT___;
