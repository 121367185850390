import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { CompanyACLUtil, UserACLUtil } from "@dashart/dashart-gpt-shared-library/dist";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import "./MainMenu.scss";

export interface MainMenuProps {
    user: UserInterface | null,
    claudeAvailable: boolean,
    openAiAvailable: boolean,
    mistralAvailable: boolean,

    tryLogout: () => void,
}

const MainMenu: React.FunctionComponent<MainMenuProps> = (props: MainMenuProps) => {
    const { t } = useTranslation();
    const location = useLocation();

    const logout = async () => {
        props.tryLogout();
    };

    // Custom function to determine if a route is active
    const isActiveRoute = (path: string): boolean => {
        const match = matchPath(
            {
                path,
                caseSensitive: false, // Optional, `true` == static parts of `path` should match case
                end: true, // Optional, `true` == match to end of location.pathname
            },
            location.pathname
        );
        return match !== null;
    };

    const addActiveClass = (routesToMatch: string[]): string => {
        const matchingRoutes = routesToMatch.filter(route => {
            return isActiveRoute(route);
        });


        return matchingRoutes.length > 0 ? "p-menuitem-active" : ''
    }

    const menuItemsV2 = React.useMemo(() => {
        const menuItems = [
            {
                label: t("menu.dashboard"),
                url: '#' + RoutesEnum.dashboard,
                className: addActiveClass([RoutesEnum.dashboard])
            },
        ];
    
        if(props.openAiAvailable) {
            menuItems.push(
                {
                    label: t("menu.chatWithChatGpt"),
                    url: '#' + RoutesEnum.chatWithChatGpt,
                    className: addActiveClass([
                        RoutesEnum.chatWithChatGpt,
                    ])
                }
            )
        }
    
        if(props.claudeAvailable) {
            menuItems.push(
                {
                    label: t("menu.chatWithClaude"),
                    url: '#' + RoutesEnum.chatWithClaude,
                    className: addActiveClass([
                        RoutesEnum.chatWithClaude,
                    ])
                }
            )
        }
    
        if(props.mistralAvailable) {
            menuItems.push(
                {
                    label: t("menu.chatWithMistral"),
                    url: '#' + RoutesEnum.chatWithMistral,
                    className: addActiveClass([
                        RoutesEnum.chatWithMistral,
                    ])
                }
            )
        }
    
        if (props.user && UserACLUtil.canUserListAllUsers(props.user)) {
            menuItems.push(
                {
                    label: t("menu.users"),
                    url: '#' + RoutesEnum.userList,
                    className: addActiveClass([
                        RoutesEnum.userList,
                        RoutesEnum.userCreate,
                        RoutesEnum.userDetail,
                        RoutesEnum.userEdit,
                        RoutesEnum.userDelete,
                    ])
                }
            )
        }
    
        if (props.user && CompanyACLUtil.canUserListAllCompanies(props.user)) {
            menuItems.push(
                {
                    label: t("menu.companies"),
                    url: '#' + RoutesEnum.companyList,
                    className: addActiveClass([
                        RoutesEnum.companyList,
                        RoutesEnum.companyCreate,
                        RoutesEnum.companyDetail,
                        RoutesEnum.companyEdit,
                        RoutesEnum.companyDelete,
                        RoutesEnum.companyListUsers,
                    ])
                }
            )
        }
    
        if (props.user?.companyId && CompanyACLUtil.canUserListUsersOfHisCompany(props.user, { id: props.user?.companyId })) {
            menuItems.push(
                {
                    label: t("menu.companiesUsers"),
                    url: '#' + RoutesEnum.companyListUsers.replace(RoutesParametersEnum.companyId, "" + props.user?.companyId),
                    className: addActiveClass([
                        RoutesEnum.companyList,
                        RoutesEnum.companyCreate,
                        RoutesEnum.companyDetail,
                        RoutesEnum.companyEdit,
                        RoutesEnum.companyDelete,
                        RoutesEnum.companyListUsers,
                    ])
                }
            )
        }

        return menuItems;
    }, [props.user, props.openAiAvailable, props.claudeAvailable, props.mistralAvailable, location]);

    return <div className={'MainMenu col p-col-nogutter'}>
        <Menubar
            model={menuItemsV2}
            end={<>
                <ButtonWithLink
                    label={props.user?.firstName + " " + props.user?.familyName}
                    className={"p-button-secondary"}
                    icon="pi pi-user"
                    style={{ marginLeft: 4 }}
                    href={RoutesEnum.userEdit.replace(RoutesParametersEnum.userId, "" + props.user?.id)}
                />
                <Button label={t("menu.logout")} icon="pi pi-power-off" style={{ marginLeft: 4 }} onClick={logout} />
            </>}
        >
        </Menubar>
    </div>
}

export default MainMenu;