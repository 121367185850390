const i18n = require('i18next');
import {initReactI18next} from 'react-i18next';

import * as translations_de from './i18n/de/translation.json'
import * as validators_de from '@dashart/dashart-gpt-shared-library/dist/i18n/de/validators.json'
import * as translations_en from './i18n/en/translation.json'
import * as validators_en from '@dashart/dashart-gpt-shared-library/dist/i18n/en/validators.json'

const initializeI18n = (fallbackLocale:string = 'de') => {
    i18n
        //.use(LanguageDetector) // not wanted
        .use(initReactI18next)
        .init({
            resources: {
                de: {
                    translation: {
                        ...translations_de,
                        validators: validators_de
                    }
                },
                en: {
                    translation: {
                        ...translations_en,
                        validators: validators_en
                    }
                },
            },
            fallbackLng: fallbackLocale,
            debug: false,
            keySeparator: ".",
            interpolation: {
                escapeValue: false,
                prefix: "{",
                suffix: "}",
            }
        });
}



export default initializeI18n;