const i18n = require('i18next');

export class I18nUtil {
    /**
     * Parse validator translation
     * @param message
     * @param returnType
     */
    static parseValidatorError(message: string, returnType: "key" | "args" | "both" = "key"): string | any | { key: string, values: any } {
        try {
            const object: { key: string, values: any } = JSON.parse(message);

            switch (returnType) {
                case "args":
                    return object?.values ?? {};
                case "both":
                    return object ?? message;
                default:
                    return object?.key ?? message;
            }
        } catch (e) {
            return message
        }
    }

    /**
     * Parse and translate validator error
     * @param message
     */
    static translateValidatorError(message: string): string {
        const translateObject = this.parseValidatorError(message, "both");

        if (i18n?.t) {
            const translation = (i18n.t(translateObject.key, translateObject.values) ?? '').toString();
            return translation != '' ? translation : message;
        } else {
            return message;
        }
    }
}