import * as React from "react";
import { useEffect, useState } from "react";
import "./UserTokenUsageScreen.scss"
import { useTranslation } from "react-i18next";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import { UserService } from "@app/services/UserService";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesEnum } from "@app/models/RoutesEnum";
import RoleValidatorComponent from "@view/components/roleValidatorComponent/RoleValidatorComponent";
import { TokenUsageService } from "@app/services/TokenUsageService";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { useDebounce } from "primereact/hooks";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import { TokenUsageInterface } from "@dashart/dashart-gpt-shared-library/dist/index";

export interface UserTokenUsageScreenProps {
    currentUser: UserInterface;
    showToast: (toast: ToastMessageInterface) => void
}

const UserTokenUsageScreen: React.FunctionComponent<UserTokenUsageScreenProps> = (props: UserTokenUsageScreenProps) => {
    const { currentUser } = props;
    const { t } = useTranslation();
    const { userId } = useParams();

    const [userData, setUserData] = useState<UserInterface>(null);
    const [tokenUsageData, setTokenUsageData] = useState<TokenUsageInterface[]>([]);

    const [dateRange, dateRangeDebounced, setDateRange] = useDebounce([
        DateTime.now().minus({ days: 30 }).toJSDate(),
        DateTime.now().toJSDate(),
    ], 1000);
    const [serverActionPending, setServerActionPending] = useState(false);

    const navigate = useNavigate();

    /**
     * Load user from backend
     * @param userId
     */
    const loadUser = async (userId: string) => {
        setServerActionPending(true);
        try {
            setUserData(await UserService.getOne(userId));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            navigate(RoutesEnum.userList);
        }
        setServerActionPending(false);
    };

    /**
     * Load user from backend
     * @param userId
     */
    const loadUserTokenUsage = async (userId: string) => {
        setServerActionPending(true);

        const startTimeStamp = dateRange[0];
        const endTimeStamp = dateRange[1] ?? new Date();

        // start date should be start of day
        startTimeStamp.setHours(0, 0, 0, 0);
        // end date should be end of day
        endTimeStamp.setHours(23, 59, 59, 0);


        try {
            setTokenUsageData(await TokenUsageService.getUserTokenUsageByTimeRange(
                {
                    userId: +userId,
                    startTimeStamp: startTimeStamp.getTime(),
                    endTimeStamp: endTimeStamp.getTime(),
                }
            ));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            navigate(RoutesEnum.userList);
        }
        setServerActionPending(false);
    };

    useEffect(() => {
        if (userId) {
            loadUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (userData) {
            loadUserTokenUsage(
                userId
            );
        }
    }, [userData]);

    useEffect(() => {
        if (userData) {
            loadUserTokenUsage(
                userId
            );
        }
    }, [dateRangeDebounced]);

    const onDateRangeChange = (e: CalendarChangeEvent) => {
        setDateRange(e.value as [Date, Date])
    }

    const calculateTotalTokenCount = (data: TokenUsageInterface[]): {
        total: number,
        input: number,
        output: number
    } => {
        let total = 0;
        let inputTotal = 0;
        let outputTotal = 0;
        data.forEach((entry) => {
            total += entry.tokenCount;
            if (entry.tokenType === 'input') {
                inputTotal += entry.tokenCount;
            } else {
                outputTotal += entry.tokenCount;
            }
        });
        return {
            total: total,
            input: inputTotal,
            output: outputTotal
        };
    };

    const tableHeader = (
        <div className={'flex grid justify-content-end align-items-center'}>
            <div className={'mr-2 flex-grow-1'}>
                {t("user_management.usage_screen.header", userData)}
            </div>
            <div className={'mr-2'}>
                {t("user_management.usage_screen.date_range")}:
            </div>
            <Calendar
                value={dateRange}
                selectionMode="range" readOnlyInput
                showButtonBar={true}
                onChange={onDateRangeChange}
            />
        </div>
    );

    const tableSummaryFooter = (
        <ColumnGroup>
            <Row>
                <Column footer={`Total (${tokenUsageData?.length ?? 0})`} colSpan={3}/>
                <Column footer={
                    t(
                        "user_management.usage_screen.table.summary_tokens",
                        calculateTotalTokenCount(tokenUsageData)
                    )
                    }/>
                <Column footer=""/>
            </Row>
            <Row>
                <Column footer={
                    <div className={'flex grid justify-content-end align-items-center pt-1'}>
                        <ButtonWithLink
                            key={`btn-back`}
                            data-test-id={"btn-back"}
                            className="button-secondary"
                            label={t('globals.actions.backToOverview')}
                            icon="pi pi-chevron-left"
                            href={RoutesEnum.userList}
                        />
                    </div>
                } colSpan={5}/>
            </Row>
        </ColumnGroup>
    );

    return <div className='UserTokenUsageScreen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'userScope'}
            userOfInterest={userData}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />

        <ProgressSpinnerOverlay visible={serverActionPending} />

        <DataTable
            value={tokenUsageData}
            className={'m-2'}
            scrollable scrollHeight={'flex'}
            rowHover={true}
            header={tableHeader}
            footerColumnGroup={tableSummaryFooter as any}
        >
            <Column
                field="id"
                header={t("user_management.usage_screen.table.header_id")}
                sortable={true}
            />
            <Column
                field="modelId"
                header={t("user_management.usage_screen.table.header_model")}
                sortable={true} />
            <Column
                field="tokenType"
                header={t("user_management.usage_screen.table.header_tokenType")}
                sortable={true} />
            <Column
                field="tokenCount"
                header={t("user_management.usage_screen.table.header_tokenCount")}
                sortable={true}
            />

            <Column field="createdAt"
                header={t("user_management.usage_screen.table.header_createdAt")}
                body={(data) => {
                    return DateTime.fromISO(data.createdAt).toFormat('dd.MM.yy HH:mm:ss');
                }}
                sortable={true}
            />
        </DataTable>

    </div>
}

export default UserTokenUsageScreen;
