import * as React from "react";

export const MistralIcon: React.FC<{ className?: any, color?: string, width: number, height: number }> = (props) => {

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <g transform="matrix(1,0,0,1,-2,0)">
            <g transform="matrix(0.141157,0,0,0.141157,0.642078,1.63541)">
                <path id="path63" d="M129.966,190.014L69.823,190.014L69.851,129.901L129.996,129.901L129.966,190.014Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path65" d="M190.08,250.127L129.937,250.127L129.965,190.014L190.11,190.014L190.08,250.127Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path67" d="M190.11,190.014L129.966,190.014L129.995,129.901L190.14,129.901L190.11,190.014Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use69" d="M250.254,190.014L190.11,190.014L190.138,129.901L250.284,129.901L250.254,190.014Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path71" d="M129.996,129.899L69.852,129.899L69.88,69.785L130.026,69.785L129.996,129.899Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use73" d="M250.283,129.899L190.139,129.899L190.168,69.785L250.313,69.785L250.283,129.899Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path75" d="M69.823,190.014L9.679,190.014L9.707,129.901L69.852,129.901L69.823,190.014Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path77" d="M69.852,129.899L9.708,129.899L9.737,69.785L69.882,69.785L69.852,129.899Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path79" d="M69.882,69.785L9.738,69.785L9.766,9.672L69.912,9.672L69.882,69.785Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use81" d="M310.457,69.785L250.313,69.785L250.341,9.672L310.486,9.672L310.457,69.785Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path83" d="M69.793,250.127L9.649,250.127L9.677,190.014L69.823,190.014L69.793,250.127Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path85" d="M69.764,310.242L9.62,310.242L9.648,250.129L69.793,250.129L69.764,310.242Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use87" d="M310.397,190.014L250.254,190.014L250.282,129.901L310.427,129.901L310.397,190.014Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use89" d="M310.427,129.899L250.283,129.899L250.311,69.785L310.457,69.785L310.427,129.899Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use91" d="M310.368,250.127L250.224,250.127L250.252,190.014L310.398,190.014L310.368,250.127Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="use93" d="M310.338,310.242L250.195,310.242L250.223,250.129L310.368,250.129L310.338,310.242Z" style={{ fill: "rgb(28,28,27)", fillRule: "nonzero" }} />
                <path id="path97" d="M154.977,190.009L94.835,190.009L94.864,129.896L155.009,129.896L154.977,190.009Z" style={{ fill: "rgb(255,112,0)", fillRule: "nonzero" }} />
                <path id="use99" d="M215.093,250.123L154.949,250.123L154.977,190.009L215.123,190.009L215.093,250.123Z" style={{ fill: "rgb(255,73,0)", fillRule: "nonzero" }} />
                <path id="use101" d="M215.123,190.009L154.979,190.009L155.007,129.896L215.153,129.896L215.123,190.009Z" style={{ fill: "rgb(255,112,0)", fillRule: "nonzero" }} />
                <path id="use103" d="M275.266,190.009L215.123,190.009L215.151,129.896L275.296,129.896L275.266,190.009Z" style={{ fill: "rgb(255,112,0)", fillRule: "nonzero" }} />
                <path id="path107" d="M155.007,129.894L94.865,129.894L94.893,69.781L155.038,69.781L155.007,129.894Z" style={{ fill: "rgb(255,163,0)", fillRule: "nonzero" }} />
                <path id="use109" d="M275.296,129.894L215.152,129.894L215.18,69.781L275.326,69.781L275.296,129.894Z" style={{ fill: "rgb(255,163,0)", fillRule: "nonzero" }} />
                <path id="path113" d="M94.834,190.009L34.692,190.009L34.72,129.896L94.865,129.896L94.834,190.009Z" style={{ fill: "rgb(255,112,0)", fillRule: "nonzero" }} />
                <path id="path115" d="M94.863,129.894L34.721,129.894L34.749,69.781L94.894,69.781L94.863,129.894Z" style={{ fill: "rgb(255,163,0)", fillRule: "nonzero" }} />
                <path id="path117" d="M94.893,69.781L34.751,69.781L34.779,9.667L94.924,9.667L94.893,69.781Z" style={{ fill: "rgb(255,206,0)", fillRule: "nonzero" }} />
                <path id="use119" d="M335.469,69.781L275.326,69.781L275.354,9.667L335.499,9.667L335.469,69.781Z" style={{ fill: "rgb(255,206,0)", fillRule: "nonzero" }} />
                <path id="path123" d="M94.804,250.123L34.662,250.123L34.69,190.009L94.835,190.009L94.804,250.123Z" style={{ fill: "rgb(255,73,0)", fillRule: "nonzero" }} />
                <path id="path125" d="M94.775,310.238L34.633,310.238L34.661,250.124L94.806,250.124L94.775,310.238Z" style={{ fill: "rgb(255,1,7)", fillRule: "nonzero" }} />
                <path id="use127" d="M335.41,190.009L275.266,190.009L275.295,129.896L335.44,129.896L335.41,190.009Z" style={{ fill: "rgb(255,112,0)", fillRule: "nonzero" }} />
                <path id="path129" d="M335.439,129.894L275.296,129.894L275.324,69.781L335.469,69.781L335.439,129.894Z" style={{ fill: "rgb(255,163,0)", fillRule: "nonzero" }} />
                <path id="use131" d="M335.38,250.123L275.237,250.123L275.265,190.009L335.41,190.009L335.38,250.123Z" style={{ fill: "rgb(255,73,0)", fillRule: "nonzero" }} />
                <path id="use133" d="M335.351,310.238L275.207,310.238L275.236,250.124L335.381,250.124L335.351,310.238Z" style={{ fill: "rgb(255,1,7)", fillRule: "nonzero" }} />
            </g>
        </g>
    </svg>
}